import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {IReviewSubmit} from "../../interfaces/orders/IReviewSubmit.ts";
import {notificationSystem} from "../../utils/notificationSystem.ts";
import {IOrdersListResponse} from "../../interfaces/orders/IOrdersList.ts";
import {Data, ISingleOrder} from "../../interfaces/orders/ISingleOrder.ts";
import {IUpdateOderStatus} from "../../interfaces/orders/IUpdateOderStatus.ts";
import {setOrderStatus} from "./ordersSlice.ts";
import {ISingleBusinessOrder} from "../../interfaces/orders/ISingleBusinessOrder.ts";
import {IDriverTipSubmit} from "../../interfaces/buyer/product/IDriverTipSubmit.ts";

export const getAllOrdersAction = createAsyncThunk("orders/geAllOrders", async (page: number) => {
    try {
        const res = await axiosInstance.get<IOrdersListResponse>(`buyer/buyer-order-list?page=${page}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getAllBusinessOrdersAction = createAsyncThunk("orders/geAllBusinessOrders", async (page: number) => {
    try {
        const res = await axiosInstance.get<IOrdersListResponse>(`business/business-orders?page=${page}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getSingleOrderAction = createAsyncThunk("orders/getSingleOrder", async (id: string) => {
    try {
        const res = await axiosInstance.get<ISingleOrder>(`buyer/order?orderId=${id}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getSingleBusinessOrderAction = createAsyncThunk("orders/getSingleBusinessOrder", async (id: string) => {
    try {
        const res = await axiosInstance.get<ISingleBusinessOrder>(`business/order/${id}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const submitReviewAction = createAsyncThunk("orders/submitReview", async (data: IReviewSubmit) => {
    try {
        const res = await axiosInstance.post("productReview", data)
        if (res.data.success) {
            notificationSystem("Review submitted successfully", "", false)
            return res.data
        } else {
            notificationSystem("Something went wrong", "", true)
            return res.data
        }
    } catch (e) {
        console.log(e)
        notificationSystem("Something went wrong", "", true)
    }
})

export const updateOrderStatusAction = createAsyncThunk("orders/updateOrderStatus", async (date: IUpdateOderStatus, {dispatch}) => {
    try {
        const res = await axiosInstance.put<{
            success: boolean,
            order: Data,
            message?: string
        }>("driver/order-status-details-update", date)
        if (res.data.success) {
            dispatch(setOrderStatus(res.data.order.orderStatus))
            notificationSystem("Order status updated successfully", "", false)
            return res.data
        } else {
            notificationSystem("Something went wrong", res.data.message || "", true)
            return res.data
        }
    } catch (e) {
        notificationSystem("Something went wrong", "", true)
    }
})

export const updateTipReviewAction = createAsyncThunk("orders/updateTipReview", async (form: IDriverTipSubmit) => {
    try {
        const res = await axiosInstance.post("buyer/driver-tip-and-review", form)
        return res.data
    } catch (e) {
        console.log(e)
    }
})