import {BackgroundImage, Button, Card, em, Flex, Image, PasswordInput, Text, Title} from "@mantine/core";
import {useLocation, useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import {IUserCreate} from "../../interfaces/account/IAccount.ts";
import {createAccount} from "../../store/account/accountActions.ts";
import {useAppDispatch} from "../../store/store.ts";
import {useMediaQuery} from "@mantine/hooks";
import logo from "../../assets/logo.png";

const CreatePassword = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery(`(max-width: ${em(576)})`);
    const form = useForm({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validate: {
            confirmPassword: (value, values) =>
                value !== values.password ? 'Passwords did not match' : null,
        },
    });

    return (
        <BackgroundImage
            h="100vh"
            src="https://images.pexels.com/photos/264636/pexels-photo-264636.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        >
            <Image src={logo} pos={"absolute"} top={10} left={30} w={190} onClick={() => navigate("/")}
                   style={{cursor: "pointer"}}/>
            <Flex w="100%" justify="end" align="center" h="100%" p="sm">
                <Card w={isMobile ? '100%' : '520px'} mr={isMobile ? 0 : 80} bg={'#FFFFFFED'}
                      radius={isMobile ? '20px' : '40px'}>
                    <Flex
                        my={20}
                        direction='column'
                        mb={isMobile ? 'lg' : ''}
                        justify='center'
                        align={isMobile ? 'start' : 'center'}
                    >
                        <Title fw={500} order={isMobile ? 3 : 1}>Create a new Password</Title>
                        <Text ta={"center"}>
                            Your new password must be different from previous used passwords.
                        </Text>
                    </Flex>
                    <form onSubmit={form.onSubmit((values) => {
                        const data: IUserCreate = {
                            firstName: location?.state?.firstName,
                            lastName: location?.state?.lastName,
                            password: values.confirmPassword
                        }
                        dispatch(createAccount(data)).then((data) => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            if (data.payload.success) {
                                navigate("/buyer")
                            }
                        })
                    })}>
                        <PasswordInput size={isMobile ? "md" : "lg"} radius={isMobile ? "md" : "lg"} withAsterisk
                                       label="Password" w="100%" placeholder="******"
                                       type="password" {...form.getInputProps('password')}/>
                        <PasswordInput size={isMobile ? "md" : "lg"} radius={isMobile ? "md" : "lg"} withAsterisk
                                       label="Confirm Password" w="100%"
                                       placeholder="*******"
                                       type="password"  {...form.getInputProps('confirmPassword')}/>
                        <Button fw={200} radius="xl" type="submit" size="lg" color="#62BE12" w="100%"
                                mt="lg">Login</Button>
                    </form>
                </Card>
            </Flex>
        </BackgroundImage>
    );
};

export default CreatePassword;