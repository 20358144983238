import {Anchor, Button, Card, em, Flex, Group, Image, Loader, Rating, rem, Text, Title} from "@mantine/core";
import {IconArrowLeft, IconArrowRight, IconMapPin} from "@tabler/icons-react";
import {useMediaQuery, useWindowScroll} from "@mantine/hooks";
import FoodItemCard from "../../components/FoodItemCard.tsx";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Fragment, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import {getOneRestaurantAction} from "../../store/restaurants/restaurantsActions.ts";
import {calculateRatings} from "../../utils/ratingCalculator.ts";
import {Carousel} from "@mantine/carousel";
import classes from "../buyer/home/carousel.module.css";
import {getRecentSearchesAction} from "../../store/product/productActions.ts";
import fallbackImage from "../../assets/Placholder-01.png";

const SellerFoodShop = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const location = useLocation()
    const {id} = useParams()
    const dispatch = useAppDispatch()
    const {singleRestaurant, loaders} = useAppSelector(state => state.restaurants)
    const navigate = useNavigate()
    const foodTags = ['Starter', 'Main Course', 'Desert', 'Beverage', 'Soup And Salad', 'Fast Food', 'Snacks', 'Other']
    const [scroll, scrollTo] = useWindowScroll()

    useEffect(() => {
        if (!id) return
        dispatch(getOneRestaurantAction({id: id}))
        dispatch(getRecentSearchesAction("food"));
        scrollTo({y: 0})
        console.log(scroll)
    }, []);


    return (
        <>
            {loaders.gettingFoodsOfRestaurant ?
                <Flex h="100vh" w="100vw" justify="center" align="center">
                    <Loader/>
                </Flex> :
                <Flex direction="column" mb="lg" justify="center" align="center">
                    <Card m={isMobile ? "" : "sm"} radius="lg" maw={isMobile ? "100%" : "70%"}>
                        <Flex justify="space-between" align={isMobile ? "center" : ""}
                              direction={isMobile ? "column" : "row"}
                              w="100%">
                            <Flex direction={isMobile ? "column" : "row"} w="100%"
                                  align={isMobile ? "center" : ""} gap={isMobile ? "" : "xl"}>
                                <Card h={isMobile ? 200 : 360} w={isMobile ? "100%" : "70%"} p={0}>
                                    <Image radius="lg"
                                           src={singleRestaurant?.restaurant?.restaurantDetails?.image}
                                           h={isMobile ? 250 : "100%"}
                                           w={isMobile ? "100%" : "100%"}
                                           fallbackSrc={fallbackImage}
                                    />
                                </Card>
                                <Flex direction="column" mx="lg">
                                    <Title order={3}
                                           my="lg">{singleRestaurant?.restaurant?.restaurantDetails?.title}</Title>
                                    <Flex>
                                        <IconMapPin style={{marginRight: 10, marginTop: 3, color: "#767C8C"}}
                                                    size={20}/>
                                        <Text
                                            style={{color: "#767C8C"}}>{singleRestaurant?.restaurant?.restaurantDetails?.address?.formattedAddress}</Text>
                                    </Flex>
                                    <Flex my="lg" gap="sm" align="center" w="100%">
                                        <Rating
                                            value={calculateRatings(singleRestaurant?.restaurant?.restaurantDetails?.rating?.totalRating, singleRestaurant?.restaurant?.restaurantDetails?.rating?.numRating)}
                                            readOnly/>
                                        {/*<Text c="gray"*/}
                                        {/*      size="lg">{calculateRatings(singleRestaurant?.restaurant?.restaurantDetails?.rating?.totalRating, singleRestaurant?.restaurant?.restaurantDetails?.rating?.numRating)}</Text>*/}
                                        {singleRestaurant?.restaurant?.restaurantDetails?.rating?.totalRating &&
                                            <Text
                                                c="#A8AEBF">({singleRestaurant?.restaurant?.restaurantDetails?.rating?.totalRating}+
                                                ratings)</Text>}
                                        {/*<Text>3.10 miles away</Text>*/}
                                    </Flex>
                                    <Flex gap="lg" justify={isMobile ? "space-evenly" : ""}>
                                        <Button variant={isMobile ? "outline" : ""} size={isMobile ? "lg" : "md"}
                                                fw={400} radius="xl" color="#62BE12"
                                                onClick={() => {
                                                    navigate(`/chat/${singleRestaurant?.restaurant?.restaurantDetails?.userId}?name=${singleRestaurant?.restaurant?.restaurantDetails?.title}&avatar=${singleRestaurant?.restaurant?.restaurantDetails?.image}`)
                                                }}>Message</Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Group>
                            </Group>
                        </Flex>
                    </Card>
                    <Flex direction="column" mb="lg" w="100%" justify="center" align="center">
                        <Flex maw={isMobile ? "100vw" : "70%"} direction="column">
                            {singleRestaurant?.restaurant?.food?.length > 0 && foodTags.map((tag, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Flex justify="space-between" mx={isMobile ? "sm" : ""}>
                                            <Text fw={700}>{tag}</Text>
                                            <Anchor style={{color: "#62BE12"}} onClick={() => {
                                                if (location?.state?.user === "guest") {
                                                    navigate(`/view-all-foods/${id}`, {state: "viewAll"})
                                                } else {
                                                    navigate(`/buyer-view-all-foods/${id}`, {state: "viewAll"})
                                                }

                                            }}>View all</Anchor>
                                        </Flex>
                                        <Carousel
                                            nextControlIcon={<IconArrowRight
                                                style={{
                                                    width: rem(30),
                                                    height: rem(30),
                                                    boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                                    borderRadius: "100px"
                                                }}/>}
                                            previousControlIcon={<IconArrowLeft style={{
                                                width: rem(30), height: rem(30),
                                                boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                                borderRadius: "100px"
                                            }}/>}
                                            slideSize={isMobile ? "150px" : "150px"}
                                            slideGap="xs"
                                            align="start"
                                            p="xs"
                                            dragFree
                                            classNames={classes}
                                        >
                                            {singleRestaurant?.restaurant?.food?.map((food, index) => {
                                                return <Carousel.Slide>
                                                    <FoodItemCard user={location?.state?.user} food={food} key={index}/>
                                                </Carousel.Slide>
                                            })}
                                        </Carousel>
                                    </Fragment>
                                )
                            })}
                        </Flex>
                    </Flex>
                </Flex>
            }
        </>
    );
};

export default SellerFoodShop;