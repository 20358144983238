import {useNavigate} from "react-router-dom";
import {ActionIcon, Card, em, Flex, Image, Text} from "@mantine/core";
import {useHover, useMediaQuery} from "@mantine/hooks";
import {IProduct} from "../../interfaces/buyer/product/IProductResponse.ts";
import {discountCalculator} from "../../utils/discountCalculator.ts";
import {IconHeartFilled, IconPlus} from "@tabler/icons-react";
import {Product} from "../../interfaces/buyer/product/ISuggestedProductsResponse.ts";
import fallbackImage from "../../assets/Placholder-01.png";

type IProductCardProps = {
    product: IProduct | Product
}
const GuestProductInfoCard = ({product}: IProductCardProps) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const {hovered, ref} = useHover()

    return (
        <Flex my="xs" w={isMobile ? "150px" : "155px"} h={isMobile ? "230px" : "225px"} style={{cursor: "pointer"}}
              ml="xs"
              onClick={() => {
                  navigate(`/product/${product?._id}`)
              }}>
            <Card ref={ref} shadow={hovered ? "sm" : "xs"} w="100%" pos="relative" radius="sm" p={0}>
                <Flex direction="column" justify="space-between" p="sm">
                    <Flex align="center" justify="center">
                        <Image loading="lazy" src={product?.productImage} alt="image of mobile"
                               w={isMobile ? "160px" : "300px"}
                               h="130px" fallbackSrc={fallbackImage}/>
                    </Flex>
                    <ActionIcon variant="subtle" style={{position: "absolute", top: 8, right: 6}}
                                color="#E0E0E0"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    navigate("/sign-in")
                                }}
                                radius="xl">
                        <IconHeartFilled color="blue"/>
                    </ActionIcon>
                    <ActionIcon size="sm" variant="filled" style={{position: "absolute", top: 130, right: 6}}
                                color="#62BE12"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    navigate("/sign-in")
                                }}
                                radius="xl">
                        <IconPlus color="white" size="18px"/>
                    </ActionIcon>
                    <Flex direction="column" mx="xs">
                        <Text lineClamp={2} size="sm">{product?.productName}</Text>
                        <Flex gap="xs">
                            <Text
                                size="sm"
                                fw={700}>{discountCalculator(product?.productPrice.amount, product?.discount)}{" "}{product?.productPrice.currency}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    );
};

export default GuestProductInfoCard;