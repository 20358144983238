import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {notificationSystem} from "../../utils/notificationSystem.ts";
import {ISubCategoriesResponse} from "../../interfaces/buyer/categories/ISubCategoriesResponse.ts";

export const getAllCategoriesAction = createAsyncThunk("categories/getAllCategories", async () => {
    try {
        const res = await axiosInstance.get("category")
        if (!res.data.success) {
            notificationSystem("Something went wrong", res.data.message, true)
        }
        return res.data
    } catch (e) {
        console.log(e)
        notificationSystem("Something went wrong", "", true)
    }
})
export const getAllGuestCategoriesAction = createAsyncThunk("categories/getAllGuestCategories", async () => {
    try {
        const res = await axiosInstance.get("guest/categories")
        if (!res.data.success) {
            notificationSystem("Something went wrong", res.data.message, true)
        }
        return res.data
    } catch (e) {
        console.log(e)
        notificationSystem("Something went wrong", "", true)
    }
})

export const getSubCategoriesAction = createAsyncThunk("categories/getSubCategories", async (id: string) => {
    try {
        const res = await axiosInstance.get<ISubCategoriesResponse>(`subCategory/get-subCategories-specific-category?categoryId=${id}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})