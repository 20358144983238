import {Card, em, Flex, Skeleton} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";

const RestaurantsSkeleton = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    return (
        <Flex pl="sm" style={{cursor: "pointer"}}>
            <Card
                withBorder
                radius="md"
                shadow="sm"
                w={isMobile ? "300px" : "400px"} h="230px"
            >
                <Card.Section>
                    <Skeleton

                        height={160}
                        width="100%"
                    />
                </Card.Section>
                <Flex gap="lg" mt="sm" direction="column">
                    <Skeleton height={8}/>
                    <Skeleton height={8}/>
                </Flex>
            </Card>
        </Flex>
    );
};

export default RestaurantsSkeleton;