import {IStatsResponse} from "../../interfaces/business/seller/IStatsResponse.ts";
import {createSlice} from "@reduxjs/toolkit";
import {getAllBusinessOrdersAction, getAllStatsAction, getAllTransactionsAction} from "./sellerActions.ts";
import {IBusinessOrdersResponse} from "../../interfaces/business/seller/IBusinessOrdersResponse.ts";
import {IAllTransactionsResponse} from "../../interfaces/business/seller/IAllTransactionsResponse.ts";

interface initialState {
    stats: IStatsResponse
    allBusinessOrders: IBusinessOrdersResponse
    allTransactions: IAllTransactionsResponse
    loader: {
        gettingStats: boolean
        gettingAllBusinessOrders: boolean
        gettingAllTransactions: boolean
    }
}

const initialState: initialState = {
    allTransactions: {
        success: false,
        transactions: []
    },
    stats: {
        success: false,
        saleStats: {
            mySale: [],
            myOrders: [],
            storeTraffic: []
        }
    },
    allBusinessOrders: {
        success: false,
        length: 0,
        orders: []
    },
    loader: {
        gettingStats: false,
        gettingAllBusinessOrders: false,
        gettingAllTransactions: false
    }
}

const sellerSlice = createSlice({
    name: "seller",
    initialState,
    reducers: {},
    extraReducers: builder =>
        builder
            // get all stats actions
            .addCase(getAllStatsAction.pending, (state) => {
                state.loader.gettingStats = true
            })
            .addCase(getAllStatsAction.fulfilled, (state, action) => {
                state.loader.gettingStats = false
                state.stats = action.payload
            })
            .addCase(getAllStatsAction.rejected, (state) => {
                state.loader.gettingStats = false
            })
            // get all transactions
            .addCase(getAllTransactionsAction.pending, (state) => {
                state.loader.gettingAllTransactions = true
            })
            .addCase(getAllTransactionsAction.fulfilled, (state, action) => {
                state.loader.gettingAllTransactions = false
                if (!action.payload) return
                state.allTransactions = action.payload
            })
            .addCase(getAllTransactionsAction.rejected, (state) => {
                state.loader.gettingAllTransactions = false
            })
            // get all business orders
            .addCase(getAllBusinessOrdersAction.pending, (state) => {
                state.loader.gettingAllBusinessOrders = true
            })
            .addCase(getAllBusinessOrdersAction.fulfilled, (state, action) => {
                state.loader.gettingAllBusinessOrders = false
                if (!action.payload) return
                state.allBusinessOrders = action.payload
            })
            .addCase(getAllBusinessOrdersAction.rejected, (state) => {
                state.loader.gettingAllBusinessOrders = false
            })
})

const sellerReducer = sellerSlice.reducer
export default sellerReducer