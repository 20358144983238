import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {notificationSystem} from "../../utils/notificationSystem.ts";

export const getAllRestaurantsAction = createAsyncThunk("restaurants/getAllRestaurants", async () => {
    try {
        const res = await axiosInstance.get("buyer/nearby-restaurants")
        if (!res.data.success) {
            notificationSystem("Something went wrong", res.data.message, true)
        }
        return res.data
    } catch (e) {
        console.log(e)
        notificationSystem("Something went wrong", "", true)
    }
})
export const getOneRestaurantAction = createAsyncThunk("restaurants/getOneRestaurant", async ({id}: { id: string }) => {
    try {
        const res = await axiosInstance.get(`buyer/restaurant-with-foods?restaurantId=${id}`)
        if (!res.data.success) {
            notificationSystem("Something went wrong", res.data.message, true)
        }
        return res.data
    } catch (e) {
        console.log(e)
        notificationSystem("Something went wrong", "", true)
    }
})

export const getRestaurantBySearchAction = createAsyncThunk("restaurants/getRestaurantBySearch", async (foodName: string) => {
    try {
        const res = await axiosInstance.get(`buyer/search-food?foodName=${foodName}`)
        if (!res.data.success) {
            notificationSystem("Something went wrong", res.data.message, true)
        }
        return res.data
    } catch (e) {
        console.log(e)
        notificationSystem("Something went wrong", "", true)
    }
})