import {configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {accountReducer} from "./account/accountSlice.ts";
import productReducer from "./product/productSlice.ts";
import categoryReducer from "./category/categoriesSlice.ts";
import restaurantReducer from "./restaurants/restaureantSlice.ts";
import sellerReducer from "./seller/sellerSlice.ts";
import ordersReducer from "./oders/ordersSlice.ts";
import walletReducer from "./wallet/walletSlice.ts";
import chatReducer from "./chat/chatSlice.ts";
import businessReducer from "./business/businessSlice.ts";
import notificationsReducer from "./notifications/notificationsSlice.ts";
// ...

const store = configureStore({
    reducer: {
        account: accountReducer,
        product: productReducer,
        categories: categoryReducer,
        restaurants: restaurantReducer,
        seller: sellerReducer,
        orders: ordersReducer,
        wallet: walletReducer,
        chat: chatReducer,
        business: businessReducer,
        notifications: notificationsReducer
    },
})
export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

