import {Badge, Card, Flex, NumberInput, Select, Text, Textarea} from "@mantine/core";
import {UseFormReturnType} from "@mantine/form";
import {IProductAdd} from "../../../../interfaces/business/seller/IProductCreate.ts";

type AddProductSpecificationProps = {
    form: UseFormReturnType<IProductAdd, (values: IProductAdd) => IProductAdd>
}


const productColorsData = ["Red", "Green", "Blue", "Yellow", "Orange", "Purple", "Pink", "Brown", "Black", "White", "Grey", "Cyan", "Lime", "Other"]
const foodTagsData = ["Starter", "Dessert", "Main Course", "Soup and Salad", "Fast Food", "Other"]

const AddProductSpecification = ({form}: AddProductSpecificationProps) => {
    return (
        <Card>
            <Flex direction="column" gap="sm">
                <Text fw={600}>Product Specification</Text>
                <NumberInput hideControls
                             label="Product Price" {...form.getInputProps("price")}/>
                <NumberInput hideControls label="Quantity" {...form.getInputProps("quantity")}/>
                <Select data={["item", "food"]} label="Product Type" {...form.getInputProps("type")}/>
                {form.values.type === "food" ? <Select onChange={(e) => {
                        if (e) {
                            form.setValues({...form, tags: [...form.values.tags, e]})
                        }
                    }} defaultValue="Starter" data={foodTagsData} label="Food tags"/>
                    : <Select {...form.getInputProps("color")} defaultValue="Red" data={productColorsData}
                              label="Product Color"/>
                }
                <Flex gap="sm">
                    {form.values.tags.length > 0 && form.values.tags.map(t => <Badge key={t}
                                                                                     color="#62BE12">{t}</Badge>)}
                </Flex>
                {/*<TextInput label="Product Color" {...form.getInputProps("color")}/>*/}
                <NumberInput hideControls label="Discount" {...form.getInputProps("discount")}/>

                <Textarea rows={6} label="What's inside the box"
                          placeholder="What's inside the box" {...form.getInputProps("whatInsideTheBox")} {...form.getInputProps("whatInsideTheBox")}/>
            </Flex>
        </Card>
    );
};

export default AddProductSpecification;