import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {INotificationsResponse} from "../../interfaces/notifications/INotificationsResponse.ts";
import {updateNotifications} from "./notificationsSlice.ts";

export const getAllNotificationsAction = createAsyncThunk("notification/getAllNotifications", async () => {
    try {
        const res = await axiosInstance.get<INotificationsResponse>("common/specific-user-notification")
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const updateNotificationAction = createAsyncThunk("notification/updateNotification", async (id: string, {dispatch}) => {
    try {
        const res = await axiosInstance.patch(`common/notifications/${id}`)
        dispatch(updateNotifications(id))
        return res.data
    } catch (e) {
        console.log(e)
    }
})