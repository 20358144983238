import {Alert, Anchor, Avatar, Button, em, Flex, Loader, Select, Text, TextInput} from "@mantine/core";
import {useEffect, useRef, useState} from "react";
import {getUser, setAuthUser} from "../../../../utils/storage.ts";
import {useAppDispatch, useAppSelector} from "../../../../store/store.ts";
import {
    getCurrenciesAction,
    getProfileDataAction,
    profileUpdateAction
} from "../../../../store/account/accountActions.ts";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";
import GoogleMapDrawer from "../../../../components/drawers/GoogleMapDrawer.tsx";
import GoogleMapModal from "../../../../components/modals/GoogleMapModal.tsx";
import {DatePickerInput} from "@mantine/dates";
import {Dropzone} from "@mantine/dropzone";
import axiosInstance from "../../../../config/axios.config.ts";
import {IAuthUserUpdateResponse, PersonalInformation} from "../../../../interfaces/account/IAuthUserUpdateResponse.ts";
import {IconInfoCircle} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";

const MyAccount = () => {
    const [mapOpened, {open, close}] = useDisclosure(false);
    const authUser = getUser()
    const [profilePic, setProfilePic] = useState(authUser?.profilePic)
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState(false)
    const [alert, setAlert] = useState(false)
    const [pendingAlert, setPendingAlert] = useState(false)
    const navigate = useNavigate()
    const {address, loaders} = useAppSelector(state => state.account)
    const [profileData, setProfileData] = useState<PersonalInformation>({
        dob: authUser.dob,
        firstName: authUser?.firstName,
        gender: authUser?.gender,
        lastName: authUser?.lastName,
        personalAddress: {
            formattedAddress: "",
            latitude: 0,
            longitude: 0,
            country: "",
            isoCode: "",
            state: "",
            city: "",
            type: "",
            coordinates: []
        },
        phoneNumber: authUser?.phoneNumber,
        profilePic: ""
    })
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const openRef = useRef<() => void>(null);

    const isAgeVerificationRejected = authUser?.ageVerification === "rejected";
    const isAgeVerificationPending = authUser?.ageVerification === "pending";

    const handleClick = () => {
        if (isAgeVerificationRejected) {
            navigate("/buyer/age-verification");
        } else if (isAgeVerificationPending) {
            setPendingAlert(true);
        } else {
            setAlert(true);
        }
    };

    useEffect(() => {
        dispatch(getProfileDataAction())
        dispatch(getCurrenciesAction())
    }, []);
    return (
        <>
            {loaders.gettingUserProfile ?
                <Flex h="500px" w="100%" justify="center" align="center">
                    <Loader/>
                </Flex> :
                <Flex direction="column" justify="center" align="center">
                    <Flex direction="column" w={isMobile ? "100%" : 1240} p="md">
                        <Text fw={700} size="24px" my="lg">My Account</Text>
                        <Flex w="100%" direction="column" p={isMobile ? "" : "lg"} gap="lg">
                            <Flex my="lg" w="100%" direction={isMobile ? "column" : "row"}
                                  align="center"
                                  justify="space-between" gap="lg">
                                <Flex direction={isMobile ? "column" : "row"} align="center" gap="sm">
                                    <Dropzone
                                        loading={loading}
                                        unstyled
                                        onReject={(files) => console.log('rejected files', files)}
                                        maxSize={5 * 1024 ** 2} openRef={openRef} onDrop={async (files) => {
                                        const formData = new FormData
                                        formData.append("profilePic", files[0])
                                        setLoading(true)
                                        const res = await axiosInstance.put<IAuthUserUpdateResponse>("auth/update-profile-pic", formData)
                                        setLoading(false)
                                        setAuthUser({
                                            firstName: res.data.updatedProfilePic.personalInformation.firstName,
                                            lastName: res.data.updatedProfilePic.personalInformation.lastName,
                                            profilePic: res.data.updatedProfilePic.personalInformation.profilePic,
                                            email: res.data.updatedProfilePic.credentialDetails.email,
                                            role: res.data.updatedProfilePic.role,
                                            loyaltyPoints: res.data.updatedProfilePic.loyaltyPoints,
                                            ageVerification: res.data.updatedProfilePic.personalInformation.dob,
                                            phoneNumber: res.data.updatedProfilePic.personalInformation.phoneNumber,
                                            dob: res.data.updatedProfilePic.personalInformation.dob,
                                            gender: res.data.updatedProfilePic.personalInformation.gender,
                                            currency: res.data.updatedProfilePic.preferences.currency.name
                                        })
                                        setProfilePic(res?.data?.updatedProfilePic?.personalInformation?.profilePic)
                                    }}>
                                        <Avatar style={{cursor: "pointer"}} src={profilePic}
                                                size="xl"/>
                                    </Dropzone>
                                    <Flex direction="column">
                                        <Text ta={isMobile ? "center" : "start"}
                                              fw={400}>{authUser?.firstName ? authUser?.firstName : "name"}</Text>
                                        <Text fw={400}
                                              opacity={0.5}>{authUser?.email ? authUser?.email : "email@mail.com"}</Text>
                                    </Flex>
                                </Flex>
                                {!profile && <Button radius="xl" variant="outline" fw={50} color="#62BE12" size="sm"
                                                     onClick={() => {
                                                         setProfile(!profile)
                                                     }}>Edit profile</Button>}
                            </Flex>
                            <Flex w="100%" justify="space-between" direction={isMobile ? "column" : "row"}
                                  gap={isMobile ? "lg" : ""}>
                                <TextInput defaultValue={authUser?.firstName} disabled={!profile} onChange={(v) => {
                                    setProfileData({...profileData, firstName: v.target.value})
                                }} w={isMobile ? "100%" : "40%"} label="First Name" placeholder={authUser?.firstName}
                                           size={isMobile ? "sm" : "md"}
                                           radius="md"/>
                                <TextInput defaultValue={authUser?.lastName} disabled={!profile} onChange={(v) => {
                                    setProfileData({...profileData, lastName: v.target.value})
                                }} w={isMobile ? "100%" : "40%"} label="Last name"
                                           placeholder={authUser?.lastName}
                                           size={isMobile ? "sm" : "md"}
                                           radius="md"/>
                            </Flex>
                            <Flex w="100%" justify="space-between" direction={isMobile ? "column" : "row"}
                                  gap={isMobile ? "lg" : ""}>
                                <TextInput defaultValue={authUser?.phoneNumber} disabled={!profile} onChange={(v) => {
                                    setProfileData({...profileData, phoneNumber: v.target.value})
                                }} w={isMobile ? "100%" : "40%"} label="Phone" size={isMobile ? "sm" : "md"}
                                           radius="md"/>
                                <TextInput
                                    disabled={!profile}
                                    onClick={open}
                                    defaultValue={address}
                                    w={isMobile ? "100%" : "40%"} label="Address"
                                    size={isMobile ? "sm" : "md"}
                                    radius="md"/>

                            </Flex>

                            <Flex w="100%" justify="space-between" direction={isMobile ? "column" : "row"}
                                  gap={isMobile ? "lg" : ""}>
                                <Select
                                    disabled={!profile}
                                    w={isMobile ? "100%" : "40%"}
                                    size={isMobile ? "sm" : "md"}
                                    radius="md"
                                    withCheckIcon={false}
                                    rightSection={""}
                                    label="Gender"
                                    defaultValue={authUser.gender}
                                    placeholder={authUser.gender}
                                    data={['Male', 'Female', "Not Specified"]}
                                    onChange={(e) => setProfileData({...profileData, gender: e ? e : ""})}
                                />
                                <Flex w={isMobile ? "100%" : "40%"} align="center" gap="sm" justify="space-between">
                                    <DatePickerInput
                                        disabled={!profile}
                                        clearable
                                        w={isMobile ? "100%" : "70%"}
                                        defaultValue={authUser.dob ? new Date(authUser.dob) : new Date()}
                                        label="Date of birth"
                                        placeholder="Pick date"
                                        size={isMobile ? "sm" : "md"}
                                        radius="md"
                                        onChange={(e) => {
                                            // let options = {year: 'numeric', month: '2-digit', day: '2-digit'}
                                            if (e) {
                                                setProfileData({...profileData, dob: e.toLocaleDateString()})
                                            }
                                        }}
                                    />
                                    {profile ?
                                        <Flex visibleFrom="lg" align="center" mt="sm" gap="xs">
                                            <IconInfoCircle color="#62BE12"/>
                                            <Anchor c="#62BE12" ta="center" onClick={handleClick}>
                                                {isAgeVerificationRejected ? "Verify Now" : isAgeVerificationPending ? "Age Verification Pending" : "Age verified"}
                                            </Anchor>
                                        </Flex> :
                                        <Flex visibleFrom="lg" align="center" mt="sm" gap="xs">
                                            <IconInfoCircle color="black" opacity={0.5}/>
                                            <Anchor c="black" opacity={0.5} ta="center">
                                                {isAgeVerificationRejected ? "Verify Now" : isAgeVerificationPending ? "Age Verification Pending" : "Age verified"}
                                            </Anchor>
                                        </Flex>
                                    }
                                </Flex>

                                {profile ?
                                    <Flex hiddenFrom="lg" align="center" mt="sm" gap="xs">
                                        <IconInfoCircle color="#62BE12"/>
                                        <Anchor c="#62BE12" ta="center" onClick={handleClick}>
                                            {isAgeVerificationRejected ? "Verify Now" : isAgeVerificationPending ? "Age Verification Pending" : "Age verified"}
                                        </Anchor>
                                    </Flex> :
                                    <Flex hiddenFrom="lg" align="center" mt="sm" gap="xs">
                                        <IconInfoCircle color="black" opacity={0.5}/>
                                        <Anchor c="black" opacity={0.5} ta="center">
                                            {isAgeVerificationRejected ? "Verify Now" : isAgeVerificationPending ? "Age Verification Pending" : "Age verified"}
                                        </Anchor>
                                    </Flex>
                                }
                            </Flex>
                            {/*<Select*/}
                            {/*    disabled={!profile}*/}
                            {/*    w={isMobile ? "100%" : "40%"}*/}
                            {/*    size={isMobile ? "sm" : "md"}*/}
                            {/*    radius="md"*/}
                            {/*    withCheckIcon={false}*/}
                            {/*    rightSection={""}*/}
                            {/*    label="Currencies"*/}
                            {/*    defaultValue={authUser.currency}*/}
                            {/*    placeholder={authUser.currency}*/}
                            {/*    data={currencies?.currencies?.map((currency) => currency.name)}*/}
                            {/*    onOptionSubmit={(e) => {*/}
                            {/*        const currency = currencies?.currencies?.find((currency) => currency.name === e)*/}
                            {/*        if (!currency) return*/}
                            {/*        const setCurrency: ISetCurrency = {*/}
                            {/*            currency: {name: currency.name, code: currency.code}*/}
                            {/*        }*/}
                            {/*        dispatch(setCurrenciesAction(setCurrency)).then((value) => {*/}
                            {/*            if (value.payload) {*/}
                            {/*                setProfile(!profile)*/}
                            {/*            }*/}
                            {/*        })*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {profile && <Flex gap="sm" align="center" justify="end" my="sm">
                                <Button radius="xl" fw={50} color="#62BE12" size="sm"
                                        onClick={() => {
                                            dispatch(profileUpdateAction(profileData)).then((value) => {
                                                if (value.payload) {
                                                    navigate("/buyer")
                                                }
                                            })
                                        }}>Save</Button>
                                <Button radius="xl" variant="outline" fw={50} color="#62BE12" size="sm"
                                        onClick={() => setProfile(!profile)}>Cancel</Button>
                            </Flex>}
                        </Flex>
                        {isMobile ? <GoogleMapDrawer opened={mapOpened} onClose={close}/> :
                            <GoogleMapModal opened={mapOpened} onClose={close}/>}
                        {alert &&
                            <Alert onClose={() => setAlert(false)} withCloseButton my="sm" variant="light" color="red"
                                   title="Age Verified">Your age has already been verified</Alert>}
                        {pendingAlert &&
                            <Alert onClose={() => setPendingAlert(false)} withCloseButton my="sm" variant="light"
                                   color="red"
                                   title="Age Verification Pending">Your age verification is pending</Alert>}
                    </Flex>

                </Flex>
            }
        </>
    );
};

export default MyAccount;