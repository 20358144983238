const Cloudinary = async (image: File): Promise<string> => {

    const cloudUpdatePreset: string = import.meta.env.VITE_API_CLOUD_UPDATE_PRESENT;
    const cloudName: string = import.meta.env.VITE_API_CLOUD_NAME;
    const cloudApi: string = import.meta.env.VITE_API_CLOUD_API;

    let imgArr: string = "";

    try {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("upload_preset", cloudUpdatePreset);
        formData.append("cloud_name", cloudName);

        const res = await fetch(cloudApi, {
            method: 'POST',
            body: formData
        });

        const data = await res.json();
        if (data && data.public_id && data.secure_url) {
            imgArr = data.secure_url
        } else {
            console.error("Invalid response from Cloudinary:", data);
        }
    } catch (error) {
        console.error((error as Error).message);
        throw error;
    }
    return imgArr;
};

export default Cloudinary;