import {ActionIcon, Avatar, Badge, Card, Menu, Table} from "@mantine/core";
import {IconDotsVertical} from "@tabler/icons-react";
import {IBusinessProduct} from "../../../interfaces/business/IAllProductsByBusinessResponse.ts";

// const elements = [
//     {
//         itemCode: "#34991",
//         category: "Mobile",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         price: '$4500',
//         status: "In Stock",
//         action: "Pending"
//     }, {
//         itemCode: "#34991",
//         category: "Mobile",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         price: '$4500',
//         status: "In Stock",
//         action: "Pending"
//     }, {
//         itemCode: "#34991",
//         category: "Mobile",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         price: '$4500',
//         status: "In Stock",
//         action: "Pending"
//     }, {
//         itemCode: "#34991",
//         category: "Mobile",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         price: '$4500',
//         status: "In Stock",
//         action: "Pending"
//     }, {
//         itemCode: "#34991",
//         category: "Mobile",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         price: '$4500',
//         status: "In Stock",
//         action: "Pending"
//     }, {
//         itemCode: "#34991",
//         category: "Mobile",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         price: '$4500',
//         status: "In Stock",
//         action: "Pending"
//     }, {
//         itemCode: "#34991",
//         category: "Mobile",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         price: '$4500',
//         status: "In Stock",
//         action: "Pending"
//     },
//     {
//         itemCode: "#34991",
//         category: "Mobile",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         price: '$4500',
//         status: "In Stock",
//         action: "Pending"
//     },
// ];

type ActiveProductsProps = {
    products: IBusinessProduct[]
}

const ActiveProducts = ({products}: ActiveProductsProps) => {

    const items = products?.map((element, index) => {
        return (
            <Table.Tr key={index}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>
                    <Avatar src={element.productImage} radius="md" size="lg"/>
                </Table.Td>
                <Table.Td>{element?.productName}</Table.Td>
                <Table.Td>${element?.productPrice?.amount.toLocaleString("en-US", {minimumFractionDigits: 0})}</Table.Td>
                <Table.Td>
                    <Badge variant="light" color="#20C375">{element?.availabilityStatus}</Badge>
                </Table.Td>
                <Table.Td>{element?.category[0]}</Table.Td>
                <Table.Td>
                    <Menu shadow="md" width={200} position="left-start" offset={1}>
                        <Menu.Target>
                            <ActionIcon radius="xl" color="#F5F6FA" c="black" size="lg">
                                <IconDotsVertical/>
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item>
                                Edit Product
                            </Menu.Item>
                            <Menu.Item>
                                Delete Product
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>

                </Table.Td>
            </Table.Tr>
        )
    })

    return (
        <Card bg="white" radius="lg" my="sm">
            <Table.ScrollContainer minWidth={500}>
                <Table verticalSpacing="md" withRowBorders={false} style={{cursor: "pointer"}}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>#</Table.Th>
                            <Table.Th>Product</Table.Th>
                            <Table.Th></Table.Th>
                            <Table.Th>Price</Table.Th>
                            <Table.Th>Status</Table.Th>
                            <Table.Th>Category</Table.Th>
                            <Table.Th>Actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {items}
                    </Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </Card>
    );
};

export default ActiveProducts;