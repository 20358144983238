export const daysCalculator = (date: string) => {
  // Current date
  const today = new Date();

  // Target date
  const targetDate = new Date(date);

  // Calculate the difference in milliseconds
  const differenceMs = targetDate.getTime() - today.getTime();

  // Convert milliseconds to days
  const differenceDays = differenceMs / (1000 * 60 * 60 * 24);

  // Round the difference to get whole days
  return Math.round(differenceDays);
};
