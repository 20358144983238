import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {ISingleBusinessResponse} from "../../interfaces/business/ISingleBusinessResponse.ts";
import {Business, IAllBusinessResponse} from "../../interfaces/business/IAllBusinessResponse.ts";
import {IAllProductsByBusinessResponse} from "../../interfaces/business/IAllProductsByBusinessResponse.ts";
import {IDashboardStatsResponse} from "../../interfaces/business/seller/IDashboardStatsResponse.ts";
import {ISingleOrderResponse} from "../../interfaces/business/ISingleOrderResponse.ts";
import {notificationSystem} from "../../utils/notificationSystem.ts";
import {deleteBusiness} from "./businessSlice.ts";
import {ISingleBusinessByIdResponse} from "../../interfaces/business/ISingleBusinessByIdResponse.ts";
import {IBusinessUpdateResponse} from "../../interfaces/business/IBusinessUpdateResponse.ts";

export const getBusinessByIdAction = createAsyncThunk("business/getSingleBusiness", async (id: string) => {
    try {
        const res = await axiosInstance.get<ISingleBusinessResponse>(`buyer/business-profile?businessId=${id}`)
        return res.data;
    } catch (e) {
        console.log(e)
    }
})

export const getAllBusinessesAction = createAsyncThunk("business/getAllBusinesses", async () => {
    try {
        const res = await axiosInstance.get<IAllBusinessResponse>("business")
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getAllProductsByBusinessIdAction = createAsyncThunk("business/getAllProductsByBusinessId", async ({
                                                                                                                   id,
                                                                                                                   status
                                                                                                               }: {
    id: string,
    status: string | undefined
}) => {
    try {
        const res = await axiosInstance.get<IAllProductsByBusinessResponse>(`business/business-products?businessId=${id}&status=${status}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getDashboardStatsAction = createAsyncThunk("business/getDashboardStats", async () => {
    try {
        const res = await axiosInstance.get<IDashboardStatsResponse>("business/stats")
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getSingleOrderByIdAction = createAsyncThunk("business/getSingleOrderById", async (id: string) => {
    try {
        const res = await axiosInstance.get<ISingleOrderResponse>(`business/order/${id}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const createBusinessAction = createAsyncThunk("business/createBusiness", async (data: FormData) => {
    try {
        const res = await axiosInstance.post("business/create", data)
        if (res.data.success) {
            notificationSystem("Success", "Business Created Successfully", false)
        }
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getSingleBusinessByIdAction = createAsyncThunk("business/getSingleBusinessById", async (id: string) => {
    try {
        const res = await axiosInstance.get<ISingleBusinessByIdResponse>(`business/business/${id}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})
export const updateSingleBusinessByIdAction = createAsyncThunk("business/updateSingleBusinessById", async ({data}: {
    data: FormData
}) => {
    try {
        const res = await axiosInstance.patch<IBusinessUpdateResponse>(`business/business-info`, data)
        if (res.data.success) {
            notificationSystem("Success", "Business Updated Successfully", false)
        } else {
            notificationSystem("Failed", "Something went wrong", true)
        }
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const deleteBusinessAction = createAsyncThunk("business/deleteBusiness", async (id: string, {dispatch}) => {
    try {
        const res = await axiosInstance.delete<{
            success: boolean
            stats: Business
        }>(`business/?businessId=${id}`)
        if (res.data.success) {
            dispatch(deleteBusiness(res.data.stats))
            notificationSystem("Success", "Business Deleted Successfully", false)
        } else {
            notificationSystem("Failed", "Something went wrong", true)
        }
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const updatePickupOrderAction = createAsyncThunk("business/updatePickupOrder", async ({id, secret}: {
    id: string,
    secret: string
}) => {
    try {
        const res = await axiosInstance.patch(`business/pickuporders/status?orderId=${id}&secret=${secret}`)
        if (!res.data.success) {
            notificationSystem("Failed", res.data.message, true)
        }
        return res.data
    } catch (e) {
        console.log(e)
        notificationSystem("Failed", "Something went wrong", true)
    }
})