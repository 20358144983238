import React, {useState} from 'react';
import {Box} from '@mantine/core';
import './ImageZoom.css';

interface ImageZoomProps {
    src: string;
    alt: string;
}

const ImageZoom: React.FC<ImageZoomProps> = ({src, alt}) => {
    const [backgroundPosition, setBackgroundPosition] = useState<string>('center');

    // Function to handle mouse movement and calculate background position
    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const {left, top, width, height} = e.currentTarget.getBoundingClientRect();
        const xPos = ((e.pageX - left) / width) * 100;
        const yPos = ((e.pageY - top) / height) * 100;
        setBackgroundPosition(`${xPos}% ${yPos}%`);
    };

    return (
        <Box className="image-container" onMouseMove={handleMouseMove}>
            <Box
                className="zoomed-image"
                style={{
                    backgroundImage: `url(${src})`,
                    backgroundPosition: backgroundPosition,
                }}
                aria-label={alt}
            />
        </Box>
    );
};

export default ImageZoom;
