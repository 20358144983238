import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import {useMediaQuery} from "@mantine/hooks";
import {em, Flex, Loader, Text} from "@mantine/core";
import {useEffect, useState} from "react";
import {getAllProductsAction} from "../../store/product/productActions.ts";
import GuestProductInfoCard from "./GuestProductInfoCard.tsx";
import {getCentre} from "../../utils/storage.ts";

const GuestProductsViewAll = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const dispatch = useAppDispatch()
    const {products, loaders} = useAppSelector(state => state.product)
    const isSmall = useMediaQuery(`(max-width: ${em(768)})`)
    const [state, setState] = useState("")
    const centre = getCentre()
    useEffect(() => {
        if (!category) return
        const decodedCategory = decodeURIComponent(category)
        setState(decodedCategory)
        dispatch(getAllProductsAction(centre))
    }, [category]);
    return (
        <>
            {loaders.gettingAllProducts ?
                <Flex h="100vh" w="100%" align="center" justify="center">
                    <Loader/>
                </Flex> :
                products?.data?.categoryProducts?.length === 0 ?
                    <Flex w="100%" justify="center" align="center" my="xl" py="xl">
                        <Text>No product found in this category</Text>
                    </Flex> :
                    <Flex justify="center" align="center">
                        <Flex direction="column" maw={isSmall ? "100%" : 1240}>
                            <Flex my="lg" justify="space-between">
                                <Text fw={700} m="xs">All Items Related
                                    to {state}</Text>
                            </Flex>
                            <Flex wrap="wrap" justify={isSmall ? "center" : ""} gap="xs" mx="xs">
                                {products?.data?.categoryProducts?.map((product) => {
                                    if (product.category === state) {
                                        return product?.products.map((p) => {
                                            return (
                                                <GuestProductInfoCard product={p}/>
                                            )
                                        })
                                    }
                                })
                                }
                            </Flex>
                        </Flex>
                    </Flex>
            }

        </>
    );
};

export default GuestProductsViewAll;