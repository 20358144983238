import {ISingleBusinessResponse} from "../../interfaces/business/ISingleBusinessResponse.ts";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    getAllBusinessesAction,
    getAllProductsByBusinessIdAction,
    getBusinessByIdAction,
    getDashboardStatsAction,
    getSingleBusinessByIdAction,
    getSingleOrderByIdAction
} from "./businessesActions.ts";
import {Business, IAllBusinessResponse} from "../../interfaces/business/IAllBusinessResponse.ts";
import {IAllProductsByBusinessResponse} from "../../interfaces/business/IAllProductsByBusinessResponse.ts";
import {IDashboardStatsResponse} from "../../interfaces/business/seller/IDashboardStatsResponse.ts";
import {ISingleOrderResponse} from "../../interfaces/business/ISingleOrderResponse.ts";
import {ISingleBusinessByIdResponse} from "../../interfaces/business/ISingleBusinessByIdResponse.ts";

interface InitialState {
    dashboardStats: IDashboardStatsResponse
    singleBusiness: ISingleBusinessResponse,
    allBusiness: IAllBusinessResponse,
    allBusinessProducts: IAllProductsByBusinessResponse
    singleOrder: ISingleOrderResponse
    singleBusinessById: ISingleBusinessByIdResponse
    loaders: {
        gettingSingleBusiness: boolean
        gettingAllBusiness: boolean
        gettingAllBusinessProducts: boolean
        gettingDashboardStats: boolean
        gettingSingleOrder: boolean
        gettingSingleBusinessById: boolean
    }
}

const initialState: InitialState = {
    singleBusinessById: {
        success: false,
        business: {
            _id: "",
            userId: "",
            address: {
                formattedAddress: "",
                latitude: 0,
                longitude: 0,
                country: "",
                isoCode: "",
                state: "",
                city: "",
                type: "",
                coordinates: []
            },
            title: "",
            image: "",
            type: "",
            registrationNumber: "",
            phoneNumber: "",
            category: "",
            email: "",
            description: "",
            verificationStatus: "",
            rating: {
                totalRating: 0,
                numRating: 0
            },
            businessDescription: ""
        }
    },
    singleOrder: {
        success: false,
        order: {
            _id: "",
            orderStatus: "",
            shippingAddress: {
                formattedAddress: "",
                isoCode: "",
                longitude: 0,
                latitude: 0,
                country: "",
                state: "",
                city: "",
                type: "",
                coordinates: []
            },
            pickupAddress: {
                formattedAddress: "",
                isoCode: "",
                longitude: 0,
                latitude: 0,
                country: "",
                state: "",
                city: "",
                type: "",
                coordinates: []
            },
            note: "",
            statusDetails: [],
            deliverType: "",
            pickupSecret: 0,
            driverDetails: [],
            createdAt: "",
            products: [],
            totals: [],
            totalOrderAmount: {
                amount: 0,
                currency: ""
            },
            orderReview: undefined
        }
    },
    dashboardStats: {
        success: false,
        stats: {
            mySales: [],
            myOrders: [],
            storeTraffic: [],
            warning: {
                message: "",
                type: "",
                navigation: ""
            }
        }
    },
    allBusinessProducts: {
        success: false,
        length: 0,
        products: []
    },
    allBusiness: {
        success: false,
        businesses: []
    },
    singleBusiness: {
        success: false,
        latestProducts: [],
        topRatedProducts: [],
        reviews: {
            averageRating: 0,
            reviews: []
        },
        businessInfo: {
            address: {
                formattedAddress: "",
                latitude: 0,
                longitude: 0,
                country: "",
                isoCode: "",
                state: "",
                city: "",
                type: "",
                coordinates: []
            },
            rating: {
                totalRating: 0,
                numRating: 0,
                sales: 0
            },
            _id: "",
            userId: "",
            title: "",
            image: "",
            type: "",
            registrationNumber: "",
            phoneNumber: "",
            category: "",
            email: "",
            description: "",
            verificationStatus: "",
            createdAt: "",
            updatedAt: "",
            __v: 0,
            businessDescription: ""
        }
    },
    loaders: {
        gettingSingleBusiness: false,
        gettingAllBusiness: false,
        gettingAllBusinessProducts: false,
        gettingDashboardStats: false,
        gettingSingleOrder: false,
        gettingSingleBusinessById: false
    }
}

const businessSlice = createSlice({
    name: "businessSlice",
    initialState,
    reducers: {
        deleteBusiness: (state, {payload}: PayloadAction<Business>) => {
            state.allBusiness.businesses = state.allBusiness.businesses.filter((business) => business._id !== payload._id)
        }
    },
    extraReducers: builder => {
        builder
            // get single business
            .addCase(getBusinessByIdAction.pending, (state) => {
                state.loaders.gettingSingleBusiness = true
            })
            .addCase(getBusinessByIdAction.fulfilled, (state, action) => {
                state.loaders.gettingSingleBusiness = false
                if (!action.payload) return
                state.singleBusiness = action.payload
            })
            .addCase(getBusinessByIdAction.rejected, (state) => {
                state.loaders.gettingSingleBusiness = false
            })
            // get all businesses
            .addCase(getAllBusinessesAction.pending, (state) => {
                state.loaders.gettingAllBusiness = true
            })
            .addCase(getAllBusinessesAction.fulfilled, (state, action) => {
                state.loaders.gettingAllBusiness = false
                if (!action.payload) return
                state.allBusiness = action.payload
            })
            .addCase(getAllBusinessesAction.rejected, (state) => {
                state.loaders.gettingAllBusiness = false
            })
            // get all business products
            .addCase(getAllProductsByBusinessIdAction.pending, (state) => {
                state.loaders.gettingAllBusinessProducts = true
            })
            .addCase(getAllProductsByBusinessIdAction.fulfilled, (state, action) => {
                state.loaders.gettingAllBusinessProducts = false
                if (!action.payload) return
                state.allBusinessProducts = action.payload
            })
            .addCase(getAllProductsByBusinessIdAction.rejected, (state) => {
                state.loaders.gettingAllBusinessProducts = false
            })
            // get all dashboard stats
            .addCase(getDashboardStatsAction.pending, (state) => {
                state.loaders.gettingDashboardStats = true
            })
            .addCase(getDashboardStatsAction.fulfilled, (state, action) => {
                state.loaders.gettingDashboardStats = false
                if (!action.payload) return
                state.dashboardStats = action.payload
            })
            .addCase(getDashboardStatsAction.rejected, (state) => {
                state.loaders.gettingDashboardStats = false
            })
            // get all dashboard stats
            .addCase(getSingleOrderByIdAction.pending, (state) => {
                state.loaders.gettingSingleOrder = true
            })
            .addCase(getSingleOrderByIdAction.fulfilled, (state, action) => {
                state.loaders.gettingSingleOrder = false
                if (!action.payload) return
                state.singleOrder = action.payload
            })
            .addCase(getSingleOrderByIdAction.rejected, (state) => {
                state.loaders.gettingSingleOrder = false
            })
            // get all dashboard stats
            .addCase(getSingleBusinessByIdAction.pending, (state) => {
                state.loaders.gettingSingleBusinessById = true
            })
            .addCase(getSingleBusinessByIdAction.fulfilled, (state, action) => {
                state.loaders.gettingSingleBusinessById = false
                if (!action.payload) return
                state.singleBusinessById = action.payload
            })
            .addCase(getSingleBusinessByIdAction.rejected, (state) => {
                state.loaders.gettingSingleBusinessById = false
            })
    }
})

const businessReducer = businessSlice.reducer
export const {deleteBusiness} = businessSlice.actions

export default businessReducer