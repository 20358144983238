import {Avatar, Box, Group, rem, Text, UnstyledButton} from '@mantine/core';
import {IconChevronRight} from '@tabler/icons-react';
import classes from './UserButton.module.css';
import {getUser} from "../../utils/storage.ts";
import {useNavigate} from "react-router-dom";

type UserButtonProps = {
    toggle: () => void
    isSeller: boolean
}

export function UserButton({toggle, isSeller}: UserButtonProps) {
    const user = getUser()
    const navigate = useNavigate()
    return (
        <UnstyledButton className={classes.user} onClick={() => {
            if (isSeller) {
                navigate("/seller/seller-profile")
            } else {
                navigate("my-account")
            }
            toggle()
        }}>
            <Group>
                <Avatar
                    src={user?.profilePic}
                    radius="xl"
                />
                <div style={{flex: 1}}>
                    <Text size="sm" fw={500}>
                        {user?.firstName}
                    </Text>

                    <Box w="175px">
                        <Text truncate="end" c="dimmed" size="xs">
                            {user?.email}
                        </Text>
                    </Box>
                </div>

                <IconChevronRight style={{width: rem(14), height: rem(14)}} stroke={1.5}/>
            </Group>
        </UnstyledButton>
    );
}