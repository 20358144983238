import {Card, em, Flex, Image, Text} from "@mantine/core";
import {useState} from "react";
import france from "../../../../assets/FR.png"
import spanish from "../../../../assets/ES.png"
import english from "../../../../assets/LR.png"
import russian from "../../../../assets/RU.png"
import japan from "../../../../assets/JP.png"
import italian from "../../../../assets/IT.png"
import {IconCircleCheckFilled} from "@tabler/icons-react";
import {useMediaQuery} from "@mantine/hooks";

const Language = () => {
    const [role, setRole] = useState("english")
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    return (
        <>
            <Text fw={700} size="24px" my={isMobile ? "xs" : ""}>Language</Text>
            <Flex w="100%" gap="lg" direction={isMobile ? "column" : "row"}>
                <Flex direction="column" w={isMobile ? "100%" : "45%"}>
                    <Card style={{cursor: "pointer"}} w="100%" withBorder my="lg" onClick={() => {
                        setRole("english")
                    }}>
                        <Flex justify="space-between">
                            <Flex gap="lg">
                                <Image src={english} w="40px" h="28px"/>
                                <Text>English</Text>
                            </Flex>
                            {role === "english" && <IconCircleCheckFilled style={{color: "#20C375"}}/>}
                        </Flex>
                    </Card>
                    <Card style={{cursor: "pointer"}} withBorder onClick={() => {
                        setRole("french")
                    }}>
                        <Flex justify="space-between">
                            <Flex gap="lg">
                                <Image src={france} w="40px" h="28px"/>
                                <Text>French</Text>
                            </Flex>
                            {role === "french" && <IconCircleCheckFilled style={{color: "#20C375"}}/>}
                        </Flex>
                    </Card>
                    <Card style={{cursor: "pointer"}} withBorder my="lg" onClick={() => {
                        setRole("russian")
                    }}>
                        <Flex justify="space-between">
                            <Flex gap="lg">
                                <Image src={russian} w="40px" h="28px"/>
                                <Text>Russian</Text>
                            </Flex>
                            {role === "russian" && <IconCircleCheckFilled style={{color: "#20C375"}}/>}
                        </Flex>
                    </Card>
                </Flex>
                <Flex direction="column" w={isMobile ? "100%" : "45%"}>
                    <Card style={{cursor: "pointer"}} w="100%" withBorder my="lg" onClick={() => {
                        setRole("japan")
                    }}>
                        <Flex justify="space-between">
                            <Flex gap="lg">
                                <Image src={japan} w="40px" h="28px"/>
                                <Text>Japan</Text>
                            </Flex>
                            {role === "japan" && <IconCircleCheckFilled style={{color: "#20C375"}}/>}
                        </Flex>
                    </Card>
                    <Card style={{cursor: "pointer"}} w="100%" withBorder onClick={() => {
                        setRole("spanish")
                    }}>
                        <Flex justify="space-between">
                            <Flex gap="lg">
                                <Image src={spanish} w="40px" h="28px"/>
                                <Text>Spanish</Text>
                            </Flex>
                            {role === "spanish" && <IconCircleCheckFilled style={{color: "#20C375"}}/>}
                        </Flex>
                    </Card>
                    <Card style={{cursor: "pointer"}} w="100%" withBorder my="lg" onClick={() => {
                        setRole("italian")
                    }}>
                        <Flex justify="space-between">
                            <Flex gap="lg">
                                <Image src={italian} w="40px" h="28px"/>
                                <Text>Italian</Text>
                            </Flex>
                            {role === "italian" && <IconCircleCheckFilled style={{color: "#20C375"}}/>}
                        </Flex>
                    </Card>
                </Flex>

            </Flex>
        </>
    );
};

export default Language;