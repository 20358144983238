import {Avatar, Card, em, Flex, Text, Title} from "@mantine/core";
import {MySale} from "../interfaces/business/seller/IDashboardStatsResponse.ts";
import {round} from "../utils/roundOffFunction.ts";
import {useMediaQuery} from "@mantine/hooks";

type SparkLinkCardProps = {
    sale: MySale
}

const SparkLineCard = ({sale}: SparkLinkCardProps) => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    return (
        <Card radius="lg" bg="#62BE12">
            <Flex direction={isMobile ? "row" : "column"} align={isMobile ? "center" : ""}
                  justify={isMobile ? "space-between" : ""} gap="lg">
                <Avatar bg="white" c="#62BE12" p="sm" size={isMobile ? 55 : 65} src={sale?.icon} radius="xl"/>
                <Flex direction="column" align={isMobile ? "end" : ""} gap="xs">
                    {sale?.type === "amount" ?
                        <Title c="white"
                               order={isMobile ? 4 : 1}>{round(sale?.amount?.amount || 0)}{" "}{sale?.amount?.currency}</Title> :
                        <Title c="white" order={isMobile ? 4 : 1}>{sale?.count}</Title>}
                    <Text c="white">{sale?.title}</Text>
                </Flex>
                {/*<Text c="#767C8C" size="xs">{name}</Text>*/}
                {/*<Flex align="center" justify="space-between">*/}
                {/*    <Title order={3}>$ {rates}</Title>*/}
                {/*    <Badge color={positive ? "#20C375" : "#F5222D"}*/}
                {/*           leftSection={positive ? <IconArrowUpRight size="14px"/> : <IconArrowDownRight size="14px"/>}*/}
                {/*           radius="sm"*/}
                {/*           variant="light">{graphValue ? graphValue : 0} %</Badge>*/}
                {/*</Flex>*/}
                {/*<Sparkline*/}
                {/*    h={80}*/}
                {/*    data={[10, 20, 40, 20, 40, 10, 50]}*/}
                {/*    curveType="linear"*/}
                {/*    color={positive ? "#1CA361" : "#F5222D"}*/}
                {/*    fillOpacity={0.6}*/}
                {/*    strokeWidth={2}*/}
                {/*/>*/}
            </Flex>
        </Card>
    );
};

export default SparkLineCard;