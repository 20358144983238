import {ActionIcon, Box, Button, Card, Flex, Group, Image, Modal, Text} from "@mantine/core";
import {IconMinus, IconPlus} from "@tabler/icons-react";
import {useState} from "react";
import {Product} from "../../interfaces/buyer/product/ISingleProductResponse.ts";
import {useNavigate} from "react-router-dom";
import {setBuyNowItems} from "../../utils/storage.ts";
import {round} from "../../utils/roundOffFunction.ts";
import {discountCalculator} from "../../utils/discountCalculator.ts";

type BuyNowModalProps = {
    opened: boolean;
    onClose: () => void;
    product: Product
}
const BuyNowModal = ({opened, onClose, product}: BuyNowModalProps) => {
    // const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const [items, setItems] = useState([
        {
            product: product?._id,
            quantity: 1
        }
    ]);
    const navigate = useNavigate();
    const updateQuantity = (index: number, newQuantity: number) => {
        setItems(prevItems => {
            const updatedItems = [...prevItems];
            updatedItems[index].quantity = newQuantity;
            return updatedItems;
        });
    };

    const increaseQuantity = (index: number) => {
        updateQuantity(index, items[index].quantity + 1);
    };

    const decreaseQuantity = (index: number) => {
        if (items[index].quantity > 1) {
            updateQuantity(index, items[index].quantity - 1);
        }
    };
    return (
        <Modal title="Order Summary" opened={opened} onClose={onClose} centered>
            <Flex direction="column" gap="sm">
                <Flex align="center" justify="space-between">
                    <Flex align="center">
                        <Image h="60px" w="60px" src={product.productImage}/>
                        <Flex mx="lg" direction="column">
                            <Box w={180}>
                                <Text size="sm" truncate="end">{product.productName}</Text>
                            </Box>
                            <Flex gap="md">
                                <Text
                                    size="sm"
                                    fw={10}
                                    td={"line-through"}>{round(product?.productPrice?.amount)}{" "} {product?.productPrice?.currency}</Text>
                                <Text
                                    size="sm"
                                    style={{
                                        color: "black",
                                        fontWeight: 600
                                    }}>{round(discountCalculator(product?.productPrice?.amount, product?.discount))} {" "} {product?.productPrice?.currency}</Text>
                                {/*<Text size="sm"*/}
                                {/*      style={{color: "#62BE12"}}>{value}%*/}
                                {/*    off</Text>*/}
                            </Flex>
                        </Flex>
                    </Flex>
                    {items.map((item, index) => {
                        return (
                            <Card key={index} radius="xl" p="8px" bg="#F5F6FA" w="120px"
                                  h="40px">
                                <Flex h="100%" justify="space-between">
                                    <ActionIcon size="sm" onClick={() => {
                                        decreaseQuantity(index)
                                    }} bg="white"
                                                radius="xl" c="black"
                                                variant="subtle"><IconMinus/></ActionIcon>
                                    <Text>{item.quantity}</Text>
                                    <ActionIcon size="sm"
                                                onClick={() => increaseQuantity(index)}
                                                radius="xl"
                                                color="#62BE12"><IconPlus/></ActionIcon>
                                </Flex>
                            </Card>
                        )
                    })}
                </Flex>
                <Group w="100%" justify="space-between">
                    <Flex gap="xs">
                        <Text>Total Price:</Text>
                        <Text>{round(items[0].quantity * round(discountCalculator(product?.productPrice?.amount, product?.discount)))}{" "} {product?.productPrice?.currency}</Text>
                    </Flex>
                    <Button radius="xl" color="#62BE12"
                            onClick={() => {
                                items[0].product = product?._id
                                setBuyNowItems(items)
                                navigate("/payment?state=buyNow")
                            }}>Checkout</Button>
                </Group>
            </Flex>
        </Modal>
    );
};

export default BuyNowModal;