import {Anchor, Button, em, Flex, Text, TextInput, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import {forgotPasswordAction} from "../../store/account/accountActions.ts";
import {useMediaQuery} from "@mantine/hooks";
import CenterLayout from "./CenterLayout.tsx";

const ForgotPassword = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {loaders} = useAppSelector(state => state.account)
    const isMobile = useMediaQuery(`(max-width: ${em(576)})`);
    const form = useForm({
        initialValues: {email: ''},

        // functions will be used to validate values at corresponding key
        validate: {
            email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });
    return (
        <CenterLayout>
            <Flex w={'100%'} direction={'column'} p={isMobile ? 10 : 30}>
                <Flex
                    direction='column'
                    justify='center'
                    align={isMobile ? 'center' : 'center'}
                >
                    <Title fw={500} order={isMobile ? 3 : 1}>Forgot password</Title>
                    <Text ta='center' my='sm'>
                        Enter the email address you used to register to reset your new
                        password
                    </Text>
                </Flex>
                <form onSubmit={form.onSubmit((values) => {
                    dispatch(forgotPasswordAction({email: values.email})).then((response) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        if (response.payload.success) {
                            navigate("/otp", {state: {email: values.email, forgotPassword: true}})
                        }
                    })

                })}>
                    <TextInput size={isMobile ? "md" : "lg"} radius={isMobile ? "md" : "lg"} withAsterisk w="100%"
                               label="Email"
                               placeholder="jhondoe@expamle.com" {...form.getInputProps('email')}/>
                    <Button fw={200} radius="xl" loading={loaders.forgotPasswordLoading} type="submit" size="lg"
                            color="#62BE12"
                            w="100%"
                            mt="lg">Reset Password</Button>
                </form>
                <Flex direction='column' justify='center' align='center' my='sm'>
                    <Flex gap='xs' align='center' w='100%' justify='center'>
                        <Text size='sm' ta='center'>
                            By continuing, you agree to our{' '}
                            <Anchor underline='always' c='black' fw={700}
                                    href="https://tigerit.app/terms-and-conditions"
                                    target="_blank">
                                {' '}
                                Terms of Service
                            </Anchor>{' '}
                            and
                        </Text>
                    </Flex>
                    <Flex gap='xs' align='center' w='100%' justify='center'>
                        <Text size='sm'>
                            acknowledge our{' '}
                            <Anchor underline='always' c='black' fw={700} href="https://tigerit.app/privacy-policy"
                                    target="_blank">
                                Privacy Policy
                            </Anchor>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </CenterLayout>
    );
};

export default ForgotPassword;