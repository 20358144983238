import {ActionIcon, Avatar, Badge, Card, CopyButton, Divider, Flex, rem, Text, Title, Tooltip} from "@mantine/core";
import {IconCheck, IconCopy} from "@tabler/icons-react";
import {dateConverter} from "../utils/dateConverter.ts";
import {InactiveCoupon} from "../interfaces/buyer/cart/ICouponsResponse.ts";
import {round} from "../utils/roundOffFunction.ts";

const CouponCard = ({coupon, isMobile}: { coupon: InactiveCoupon, isMobile?: boolean }) => {

    return (
        <Card radius="lg" mt="xs" w={isMobile ? "100%" : "70%"} bg="#F5F6FA">
            <Flex gap="sm" justify="space-between" direction={isMobile ? "column" : "row"}>
                <Flex gap="sm" align="center">
                    <Avatar radius="xs" size="lg" src={coupon?.coupon?.creator?.icon}/>
                    <Flex direction="column" gap={isMobile ? "" : "xs"}>
                        <Title order={isMobile ? 4 : 3}>
                            {coupon?.coupon?.title}
                        </Title>
                        <Text opacity={0.5}>
                            {coupon?.coupon?.creator?.type}
                        </Text>
                        <Badge color="#20C375"
                               radius="sm">{round(coupon?.coupon?.discountAmount?.amount)}{" "}{coupon?.coupon?.discountAmount?.currency} Off</Badge>
                    </Flex>
                </Flex>
                {isMobile && <Divider variant="dashed"/>}
                <Flex gap="xl">
                    <Flex direction="column" mx={isMobile ? "" : "lg"}>
                        <Text opacity={0.5}>Expires</Text>
                        <Text size={isMobile ? "sm" : ""}>{dateConverter(coupon?.coupon?.expirationDate)}</Text>
                    </Flex>
                    <Flex direction="column">
                        <Text opacity={0.5}>Coupon Code</Text>
                        <Flex align="center" gap="xs">
                            <Text size={isMobile ? "sm" : ""}>{coupon?.coupon?.code}</Text>
                            <CopyButton value={coupon?.coupon?.code} timeout={2000}>
                                {({copied, copy}) => (
                                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                        <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle"
                                                    onClick={copy}>
                                            {copied ? (
                                                <IconCheck style={{width: rem(16)}}/>
                                            ) : (
                                                <IconCopy style={{width: rem(16)}}/>
                                            )}
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                            </CopyButton>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
};

export default CouponCard;