import {IMessageSendResponse} from "../../interfaces/chat/IMessageSendResponse.ts";
import {createSlice} from "@reduxjs/toolkit";
import {getChatInboxMessagesAction, getOneChatAction, sendMessageAction} from "./chatActions.ts";
import {IChatInbox} from "../../interfaces/chat/IChatInbox.ts";
import {ISingleChat} from "../../interfaces/chat/ISingleChat.ts";

interface InitialState {
    messageSentResponse: IMessageSendResponse
    inbox: IChatInbox
    singleChatDetail: ISingleChat
    loaders: {
        sendingMessage: boolean
        gettingInbox: boolean
        gettingSingleChat: boolean
    }
}

const initialState: InitialState = {
    messageSentResponse: {
        success: false,
        message: {
            _id: "",
            sender: "",
            text: "",
            type: "",
            createdAt: ""
        }
    },
    inbox: {
        success: false,
        length: 0,
        chats: []
    },
    singleChatDetail: {
        success: false,
        chat: {
            _id: "",
            chatRoomId: "",
            __v: 0,
            createdAt: "",
            members: [],
            messages: [],
            seenBy: "",
            updatedAt: ""
        }
    },
    loaders: {
        sendingMessage: false,
        gettingInbox: false,
        gettingSingleChat: false
    }
}

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        updateChat: (state, action) => {
            {
                state.singleChatDetail.chat.messages.push(action.payload)
            }
        },
        updateSeenByChat: (state, action) => {
            state.inbox.chats = state.inbox.chats.map((chat) => {
                if (chat._id === action.payload) {
                    return {
                        _id: chat._id,
                        seenBy: action.payload,
                        chatRoomId: chat.chatRoomId,
                        chatPartnerInfo: chat.chatPartnerInfo,
                        mostRecentMessage: chat.mostRecentMessage
                    }
                }
                return chat
            })
        }
    },
    extraReducers: builder =>
        builder
            // send message
            .addCase(sendMessageAction.pending, (state) => {
                state.loaders.sendingMessage = true
            })
            .addCase(sendMessageAction.fulfilled, (state, action) => {
                state.loaders.sendingMessage = false
                if (!action.payload) return
                state.messageSentResponse = action.payload
            })
            .addCase(sendMessageAction.rejected, (state) => {
                state.loaders.sendingMessage = false
            })
            // get all messages in inbox
            .addCase(getChatInboxMessagesAction.pending, (state) => {
                state.loaders.gettingInbox = true
            })
            .addCase(getChatInboxMessagesAction.fulfilled, (state, action) => {
                state.loaders.gettingInbox = false
                if (!action.payload) return
                state.inbox = action.payload
            })
            .addCase(getChatInboxMessagesAction.rejected, (state) => {
                state.loaders.gettingInbox = false
            })
            // get all messages in inbox
            .addCase(getOneChatAction.pending, (state) => {
                state.loaders.gettingSingleChat = true
            })
            .addCase(getOneChatAction.fulfilled, (state, action) => {
                state.loaders.gettingSingleChat = false
                if (!action.payload) return
                state.singleChatDetail = action.payload
            })
            .addCase(getOneChatAction.rejected, (state) => {
                state.loaders.gettingSingleChat = false
            })
})

const chatReducer = chatSlice.reducer
export const {updateChat, updateSeenByChat} = chatSlice.actions
export default chatReducer