import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {getRestaurantBySearchAction} from "../../../store/restaurants/restaurantsActions.ts";
import {em, Flex, Loader, Text} from "@mantine/core";
import RestaurantCard from "./RestaurantCard.tsx";
import {useMediaQuery} from "@mantine/hooks";

const FoodsBySearch = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('search');
    const dispatch = useAppDispatch();
    const {restaurants, loaders} = useAppSelector(state => state.restaurants)
    const isSmall = useMediaQuery(`(max-width: ${em(768)})`)

    useEffect(() => {
        if (!category) return;
        dispatch(getRestaurantBySearchAction(category))
    }, []);

    return (
        <>
            {loaders.gettingAllRestaurants ?
                <Flex h="100vh" w="100%" align="center" justify="center">
                    <Loader/>
                </Flex> :
                restaurants?.length === 0 ?
                    <Flex h="100%" w="100%" justify="center" align="center">
                        <Text>No restaurant available for now</Text>
                    </Flex> :
                    <Flex justify="center" align="center">
                        <Flex direction="column" maw={isSmall ? "100%" : 1240}>
                            <Flex my="lg" justify="space-between">
                                <Text fw={700} m="xs">All Restaurants near you</Text>
                            </Flex>
                            <Flex wrap="wrap" justify={isSmall ? "center" : ""} gap="xs" mx="xs">
                                {restaurants?.restaurants?.map((restaurant, index) => {
                                    return (
                                        <RestaurantCard key={index} restaurant={restaurant}/>
                                    )
                                })}
                            </Flex>
                        </Flex>
                    </Flex>
            }

        </>
    );
};

export default FoodsBySearch;