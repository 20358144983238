import {
    ActionIcon,
    Avatar,
    Badge,
    Card,
    CopyButton,
    Divider,
    em,
    Flex,
    Loader,
    Modal,
    rem,
    Text,
    Title,
    Tooltip
} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {InactiveCoupon} from "../../interfaces/buyer/cart/ICouponsResponse.ts";
import {dateConverter} from "../../utils/dateConverter.ts";
import {IconCheck, IconCopy} from "@tabler/icons-react";
import {ICartUpdateData} from "../../interfaces/buyer/cart/ICartUpdateData.ts";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import {updateCartAction} from "../../store/product/productActions.ts";

type CouponsModalProps = {
    coupons: InactiveCoupon[],
    close: () => void,
    opened: boolean,
    loading: boolean,
    id: string
    setCouponId: (id: string) => void,
}
const CouponsModal = ({coupons, opened, close, loading, id, setCouponId}: CouponsModalProps) => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const dispatch = useAppDispatch()
    const {cart} = useAppSelector(state => state.product)
    return (
        <Modal opened={opened} onClose={close} centered radius="lg" bg="red" size="lg">
            {loading ?
                <Flex h="100%" w="100%" justify="center" align="center">
                    <Loader/>
                </Flex> :
                <>
                    {coupons?.map((coupon, index) => {
                        return (
                            <Card key={index} p="xs" radius="lg" withBorder my="sm" shadow="sm" onClick={() => {
                                setCouponId(coupon?.coupon?.code)
                                const extractedData = cart?.cart?.details?.map((item) => {
                                    const {products} = item
                                    return products.map(product => ({
                                        product: product._id,
                                        quantity: product.quantity
                                    }))
                                })
                                const combinedArray = extractedData.reduce((acc, curr) => acc.concat(curr), [])

                                const coupons = [{
                                    couponId: coupon.coupon._id,
                                    businessId: id
                                }]
                                const data: ICartUpdateData = {items: combinedArray, couponInfo: coupons}
                                dispatch(updateCartAction(data))
                                close()
                            }}>
                                <Flex gap="sm" justify="space-between" px="xl" direction="column">
                                    <Flex gap="sm" align="center">
                                        <Avatar radius="xs" size="md" src={coupon?.coupon?.creator?.icon}/>
                                        <Flex direction="column">
                                            <Title order={isMobile ? 5 : 4}>
                                                {coupon?.coupon?.title}
                                            </Title>
                                            <Flex align="center" gap="sm">
                                                <Text opacity={0.5} size="sm">
                                                    {coupon?.coupon?.creator?.type} Bonus
                                                </Text>
                                                <Badge variant="light" color="#20C375"
                                                       radius="sm">{coupon?.coupon?.discountAmount?.amount}$ Off</Badge>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Divider variant="dashed"/>
                                    <Flex gap="xl">
                                        <Flex direction="column" mx={isMobile ? "" : "lg"}>
                                            <Text opacity={0.5} size="sm">Expires</Text>
                                            <Text
                                                size="sm">{dateConverter(coupon?.coupon?.expirationDate)}</Text>
                                        </Flex>
                                        <Flex direction="column">
                                            <Text opacity={0.5} size="sm">Coupon Code</Text>
                                            <Flex align="center" gap="xs">
                                                <Text size="sm">{coupon?.coupon?.code}</Text>
                                                <CopyButton value={coupon?.coupon?.code} timeout={2000}>
                                                    {({copied, copy}) => (
                                                        <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow
                                                                 position="right">
                                                            <ActionIcon size="sm" color={copied ? 'teal' : 'gray'}
                                                                        variant="subtle"
                                                                        onClick={copy}>
                                                                {copied ? (
                                                                    <IconCheck style={{width: rem(16)}}/>
                                                                ) : (
                                                                    <IconCopy style={{width: rem(16)}}/>
                                                                )}
                                                            </ActionIcon>
                                                        </Tooltip>
                                                    )}
                                                </CopyButton>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Card>
                        )
                    })}
                </>
            }
        </Modal>
    );
};

export default CouponsModal;