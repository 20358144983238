import {ActionIcon, Avatar, Badge, Card, em, Flex, Image, LoadingOverlay, Text, Title} from "@mantine/core";
import wallet from "../../../assets/wallet-icon.png";
import {IconArrowDown} from "@tabler/icons-react";
import {useMediaQuery} from "@mantine/hooks";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {dateConverter} from "../../../utils/dateConverter.ts";
import {round} from "../../../utils/roundOffFunction.ts";
import {getTransactionHistory} from "../../../store/wallet/walletActions.ts";
import fallbackImage from "../../../assets/Placholder-01.png";

const SellerWallet = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const {walletWithTransactionHistory, loaders} = useAppSelector(state => state.wallet)
    // const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getTransactionHistory())
    }, []);

    return (
        <Flex w="100%" direction="column" p="md">
            <LoadingOverlay visible={loaders.gettingWallet}/>
            <Flex justify="space-between" align="center" w={isMobile ? "100%" : 1000}>
                <Title order={isMobile ? 4 : 3}>My Wallet</Title>
                {/*<Button radius="xl" color="#62BE12">Create Coupon</Button>*/}
            </Flex>
            <Flex justify="center">
                <Flex direction="column" w="100%" mt="sm">
                    <Card w="100%" h={192} bg="#62BE12" radius="lg">
                        <Flex justify="space-between">
                            <Flex direction="column" gap="xs">
                                <Text c="white">Total Balance</Text>
                                {/*<Title*/}
                                {/*    c="white">{walletWithTransactionHistory?.wallet?.balance?.amount.toLocaleString("en-US", {*/}
                                {/*    style: "currency",*/}
                                {/*    currency: "USD"*/}
                                {/*})}</Title>*/}
                                <Title
                                    c="white">{walletWithTransactionHistory?.wallet?.balance?.amount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                })}</Title>
                                {/*<Flex gap="sm">*/}
                                {/*    <Button variant="white" c="#62BE12" radius="xl">Details</Button>*/}
                                {/*    <Button variant="outline" radius="xl" c="white" color="white"*/}
                                {/*            onClick={() => navigate("/buyer/deposit-money")}>Top-ups</Button>*/}
                                {/*</Flex>*/}
                            </Flex>
                            <Image fallbackSrc={fallbackImage} opacity={0.4} pos="absolute" right={0} h={149} top={0}
                                   w={272} src={wallet}/>
                        </Flex>
                    </Card>
                    <Card shadow="sm" mt="sm" radius="lg" px={isMobile ? "md" : "xl"}>
                        <Flex justify="space-between" align="center">
                            <Text>Recent Transactions</Text>
                            {/*<Text>view all</Text>*/}
                        </Flex>
                        {walletWithTransactionHistory?.wallet?.transactions?.length <= 0 ?
                            <Flex align="center" direction="column">
                                <Avatar
                                    radius="xs"
                                    src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724137845/Icons/uhbcoejd09h9iabpuvjx.png"
                                    size={isMobile ? 150 : 250}/>
                                <Text>No <span style={{color: "#62BE12"}}>Transactions</span> yet</Text>
                            </Flex>
                            :
                            walletWithTransactionHistory?.wallet?.transactions?.map((transaction, index) => {
                                return (
                                    <Flex w="100%" align="center" justify="space-between" my="xs" key={index}>
                                        <Flex align="center" gap="sm">
                                            <ActionIcon radius="xl" color="#F0F1F5" c="#62BE12">
                                                <IconArrowDown/>
                                            </ActionIcon>
                                            <Flex direction="column">
                                                <Text>{transaction?.title}</Text>
                                                <Text opacity={0.5}>{dateConverter(transaction?.createdAt)}</Text>
                                            </Flex>
                                        </Flex>
                                        <Badge variant="light" color="#62BE12" size="lg"
                                               radius="sm">${round(transaction?.price?.amount)}</Badge>
                                    </Flex>
                                )
                            })}

                    </Card>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SellerWallet;