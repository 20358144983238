import MyAccount from "./account/MyAccount.tsx";
import {useState} from "react";
import {PersonalInformation} from "../../../interfaces/account/IAuthUserUpdateResponse.ts";

// const data = [
//
//     {label: 'My Account', leftSection: <IconUser/>},
//     {label: 'My Orders', leftSection: <IconUser/>},
//     {label: 'Seller account', leftSection: <IconUser/>},
//     {label: 'Invoices', leftSection: <IconUser/>},
//     {label: 'Track Order', leftSection: <IconUser/>},
//     {label: 'Payment Option', leftSection: <IconUser/>},
//     {label: 'Inbox', leftSection: <IconUser/>},
//     {label: 'Complaints', leftSection: <IconUser/>},
//     {label: 'Language', leftSection: <IconUser/>},
// ];

const SellerProfile = () => {


    const [profileData, setProfileData] = useState<PersonalInformation>({
        phoneNumber: "", profilePic: "",
        dob: "",
        firstName: "",
        gender: "",
        lastName: "",
        personalAddress: {
            formattedAddress: "",
            latitude: 0,
            longitude: 0,
            country: "",
            isoCode: "",
            state: "",
            city: "",
            type: "",
            coordinates: []
        }
    })

    // const items = data.map((item, index) => (
    //     <NavLink
    //         key={index}
    //         active={index === active}
    //         label={item.label}
    //         leftSection={item.leftSection}
    //         onClick={() => {
    //             setActive(index)
    //             if (isMobile) {
    //                 close()
    //             }
    //         }}
    //         color="lime"
    //         variant="filled"
    //         style={{borderRadius: "8px"}}
    //         my="lg"
    //     />
    // ));

    return (
        <MyAccount profileData={profileData} setProfileData={setProfileData}/>
    );
};

export default SellerProfile;