export const buyerFaqs = [
    {
        value: "How do I create an account on Tiger it?",
        description: "To create an account, click on the \"Sign Up\" button on the homepage and follow the prompts to enter your details. By default, your account will be set as a buyer."
    },
    {
        value: "How do I place an order?",
        description: "After logging in, browse the available products, add items to your cart, and proceed to checkout to place your order."
    },
    {
        value: "What payment methods are accepted?",
        description: "We accept various payment methods including credit/debit cards, mobile wallets, and online banking."
    },
    {
        value: "How can I track my order?",
        description: "You can track your order status in the \"My Orders\" section of your account dashboard."
    },
    {
        value: "What should I do if I receive a damaged or incorrect product?",
        description: "Please contact our customer support immediately with your order details and we will assist you with a replacement or refund."
    }
]

export const sellersFaqs = [
    {
        value: "How do I register as a seller on Tiger it?",
        description: "If you already have an account, go to the account settings and select the seller role. Fill out your business information to become an authenticated seller."
    },
    {
        value: "What are the fees for selling on Tiger it?",
        description: "The fees vary depending on the product category and sales volume. Detailed fee structures are available in your seller dashboard."
    },
    {
        value: "How do I list my products?",
        description: "Once your business is verified by the admin, you can log into your seller dashboard, go to the \"Product Listings\" section, and follow the instructions to add your products."
    },
    {
        value: "How do I manage my orders?",
        description: "You can manage your orders through the \"Orders\" section in your seller dashboard, where you can view, process, and update orders."
    },
    {
        value: "How do I handle returns and refunds?",
        description: "Our platform has a streamlined process for returns and refunds. Details can be found in the \"Returns & Refunds\" policy in your seller dashboard."
    }
]

export const driverFaqs = [
    {
        value: "How do I sign up as a driver?",
        description: "If you already have an account, go to the account settings and select the driver role. Complete your vehicle information to get started."
    },
    {
        value: "What are the requirements to become a driver?",
        description: "Drivers must have a valid driver's license, a clean driving record, and a vehicle that meets our platform's requirements."
    },
    {
        value: "How do I receive delivery assignments?",
        description: "Once your vehicle information is approved by the admin, delivery assignments will be sent to your driver dashboard based on your availability and location."
    },
    {
        value: "How do I get paid for deliveries?",
        description: "Payments are processed weekly and transferred to your registered bank account. You can view your earnings in the driver dashboard."
    },
    {
        value: "What should I do if I encounter an issue during delivery?",
        description: "Contact our driver support team through the app for immediate assistance with any delivery-related issues."
    }
]

export const privacyFaqs = [
    {
        value: "What personal information does Tiger it collect?",
        description: "We collect personal information such as your name, email, phone number, payment details, current location, and delivery location."
    },
    {
        value: "How is my personal information used?",
        description: "Your information is used to provide and improve our services, process transactions, and communicate with you."
    },
    {
        value: "How does Tiger it protect my information?",
        description: "We implement various security measures to protect your personal information from unauthorized access and disclosure."
    },
    {
        value: "Can I delete my personal information?",
        description: "Yes, you can request the deletion of your personal information by contacting our support team."
    },
    {
        value: "Does Tiger it share my information with third parties?",
        description: "We do not share your personal information with third parties without your consent, except as required by law."
    }
]

export const termsAndCondition = [
    {
        value: "What are the terms for using Tiger it?",
        description: "Our terms of service outline the rules and regulations for using our platform, which you can review on our website."
    },
    {
        value: "How do I agree to the terms and conditions?",
        description: "By using our platform, you automatically agree to our terms and conditions."
    },
    {
        value: "What happens if I violate the terms and conditions?",
        description: "Violations may result in account suspension or termination, depending on the severity of the breach."
    },
    {
        value: "Can the terms and conditions change?",
        description: "Yes, we reserve the right to update the terms and conditions. Users will be notified of significant changes."
    },
    {
        value: "How can I contact Tiger it for more information?",
        description: "For any queries regarding our terms and conditions, you can contact our customer support team at support@tigerit.com."
    }
]