import {Avatar, Button, Card, em, Flex, Group, Skeleton, Text} from "@mantine/core";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";
import {IconBuildingStore} from "@tabler/icons-react";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../store/store.ts";
import {getAllUnreviewedProductsAction} from "../../../../store/product/productActions.ts";
import {IReviewProductSubmit} from "../../../../interfaces/buyer/product/IReviewProductSubmit.ts";
import RatingModal from "../../../../components/modals/RatingModal.tsx";
import {IDriverTipSubmit} from "../../../../interfaces/buyer/product/IDriverTipSubmit.ts";
import DriverTipModal from "../../../../components/modals/DriverTipModal.tsx";

const UnreviewedProducts = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const dispatch = useAppDispatch();
    const {unreviewedProducts, loaders} = useAppSelector(state => state.product);
    const [opened, {open, close}] = useDisclosure(false);
    const [tipModalOpened, {open: openTipModal, close: closeTipModal}] = useDisclosure(false);
    const [ratingForm, setRatingForm] = useState<IReviewProductSubmit>({
        productId: "",
        rating: 0,
        review: ""
    })
    const [driverTipForm, setDriverTipForm] = useState<IDriverTipSubmit>({
        driverId: "",
        orderId: "",
        rating: 0,
        review: "",
        tipAmount: ""
    })

    useEffect(() => {
        dispatch(getAllUnreviewedProductsAction())
    }, []);

    return (
        <Flex direction="column" justify="center" align="center">
            <Flex direction="column" w={isMobile ? "100%" : "70%"} p="md" gap="md">
                <Text>Review Products</Text>
                {unreviewedProducts?.data?.map((product, index) => {
                    return (
                        <Card withBorder key={index}>
                            <Card.Section inheritPadding withBorder py="xs">
                                <Flex gap="lg" justify="space-between">
                                    <Group>
                                        <IconBuildingStore/>
                                        {loaders.gettingUnreviewedProducts ? <Skeleton height={8} width={100}/> :
                                            <Text size={isMobile ? "xs" : ""}>{product?.business?.title}</Text>}
                                    </Group>
                                    <Group>
                                        {product.driver && !product?.driverReviewed &&
                                            <Button size={isMobile ? "xs" : ""} fw={200} radius="md" onClick={() => {
                                                setDriverTipForm({
                                                    ...driverTipForm,
                                                    driverId: product?.driver?._id || "",
                                                    orderId: product?._id
                                                })
                                                openTipModal()
                                            }}>Tip Driver</Button>}
                                    </Group>
                                </Flex>
                            </Card.Section>
                            {product?.items?.map((item, index) => (
                                <Flex gap="sm" mt="md" key={index}>
                                    {loaders.gettingUnreviewedProducts ? <Skeleton height={50} width={50}/> :
                                        <Avatar radius="xs" size="xl" src={item?.productImage}/>}
                                    <Flex gap="sm" w="100%" justify="space-between" direction="column">
                                        {loaders.gettingUnreviewedProducts ? <Skeleton height={12} width={200}/> :
                                            <Text lineClamp={2} size={isMobile ? "xs" : ""}>{item?.productName}</Text>}
                                        <Button size={isMobile ? "xs" : ""} style={{alignSelf: "end"}} variant="outline"
                                                fw={200} radius="md"
                                                onClick={() => {
                                                    setRatingForm({...ratingForm, productId: item._id})
                                                    open()
                                                }}>Review</Button>
                                    </Flex>
                                </Flex>))}
                        </Card>
                    )
                })}
            </Flex>
            <RatingModal opened={opened} close={close} setRatingForm={setRatingForm} ratingForm={ratingForm}/>
            <DriverTipModal opened={tipModalOpened} close={closeTipModal} setDriverTipForm={setDriverTipForm}
                            driverTipForm={driverTipForm}/>
        </Flex>
    );
};

export default UnreviewedProducts;