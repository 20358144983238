export function customShortenId(id: string): string {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
        const char = id.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    // Convert the hash to a string and take the first 5 characters
    return Math.abs(hash).toString(36).substring(0, 5);
}
