import {Accordion, Badge, Box, Card, Divider, Flex, Group, Image, Skeleton, Text} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {Order} from "../interfaces/orders/IOrdersList.ts";
import {round} from "../utils/roundOffFunction.ts";
import {customShortenId} from "../utils/shortenId.ts";
import fallbackImage from "../assets/Placholder-01.png";

type OrderCardProps = {
    order: Order
    isBuyer: boolean
    loading: boolean
}

const OrderCard = ({order, isBuyer, loading}: OrderCardProps) => {
    const navigate = useNavigate()
    return (
        <Card radius="md" style={{cursor: "pointer"}} bg={isBuyer ? "#F5F6FA" : ""}>
            <Card.Section withBorder inheritPadding py="xs"
                          onClick={() => {
                              if (isBuyer) {
                                  navigate(`/order-detail/${order._id}`)
                              } else {
                                  navigate(`/seller/order-details/${order?._id}`)
                              }
                          }}>
                <Flex gap="sm">
                    <Box h="56px" w="96px" bg={isBuyer ? "#F5F6FA" : ""}>
                        {loading ? <Skeleton height="56px" width="96px"/> : <Image h="56px" w="96px"
                                                                                   src={order?.items[0]?.image}
                                                                                   radius="md"
                                                                                   fallbackSrc={fallbackImage}
                        />}
                    </Box>
                    {loading ? <Skeleton height={8}/> : <Text fw={600}>{order?.items[0]?.title}</Text>}
                </Flex>
            </Card.Section>
            <Group my="sm" justify="space-between">
                <Flex direction="column">
                    <Text c="#767C8C">Order ID</Text>
                    {loading ? <Skeleton height={8}/> : <Text>{customShortenId(order?._id)}</Text>}
                </Flex>
                <Flex direction="column">
                    <Text c="#767C8C">Amount</Text>
                    {loading ? <Skeleton height={8}/> :
                        <Text>{round(order?.totalOrderAmount?.amount).toLocaleString("en-US", {
                            style: "currency",
                            currency: order?.totalOrderAmount?.currency
                        })}</Text>}
                </Flex>
            </Group>
            <Group justify="space-between">
                <Flex direction="column">
                    <Text c="#767C8C">Quantity</Text>
                    {loading ? <Skeleton height={8}/> : <Text>{order?.items?.length}</Text>}
                </Flex>
                <Flex direction="column" align="center">
                    <Text c="#767C8C">Status</Text>
                    {loading ? <Skeleton height={8}/> : <Badge variant="light" color="#20C375" fw={200}
                        // color={business?.verificationStatus === "pending" ? "#FFAB40" : "#62BE12"}
                    >{order?.orderStatus}</Badge>}
                </Flex>
            </Group>
            <Divider mt="sm"/>
            {loading ? <Skeleton height={20}/> : <Accordion defaultValue="Item Details">
                <Accordion.Item value="Item Details">
                    <Accordion.Control>Item Details</Accordion.Control>
                    {order?.items?.map((item, index) => {
                        return (
                            <Accordion.Panel key={index}>
                                <Flex justify="space-between">
                                    <Flex gap="sm">
                                        {/*<Text opacity={0.3}>{item?.quantity}x</Text>*/}
                                        <Box w={210}>
                                            <Text truncate="end" opacity={0.7}>{item?.title}</Text>
                                        </Box>
                                    </Flex>
                                    {/*<Text>{round(item?.price?.amount).toLocaleString("en-US", {*/}
                                    {/*    style: "currency",*/}
                                    {/*    currency: item?.price?.currency*/}
                                    {/*})}</Text>*/}
                                </Flex>
                            </Accordion.Panel>
                        )
                    })}
                </Accordion.Item>
            </Accordion>}
        </Card>
    );
};

export default OrderCard;