import {Anchor, Avatar, Button, Card, Divider, em, Flex, Text, TextInput, Title} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import {signInWithGoogleAction, signupAction} from "../../store/account/accountActions.ts";
import {IUserSignUp} from "../../interfaces/account/IAccount.ts";
import {useMediaQuery} from "@mantine/hooks";
import {auth, provider, signInWithPopup} from "../../config/firebaseConfig.ts";
import google from "../../assets/google.png";
import {getFcmToken} from "../../utils/storage.ts";
import CenterLayout from "./CenterLayout.tsx";

const SignUp = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {loaders} = useAppSelector(state => state.account)
    const isMobile = useMediaQuery(`(max-width: ${em(576)})`);
    const form = useForm<IUserSignUp>({
        initialValues: {email: ''},
        // functions will be used to validate values at corresponding key
        validate: {
            email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider)
            const user = result.user
            const token = await user.getIdToken()

            dispatch(signInWithGoogleAction({
                IdToken: token, deviceInfo: {
                    fcm: getFcmToken() || ""
                }
            })).then((data) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                if (data.payload.success) {
                    navigate("/buyer")
                }
            })
        } catch (error) {
            if ((error as Error).message === 'Firebase: Error (auth/wrong-password).')
                console.log('Password is incorrect! Please try with correct password')
            else if (
                (error as Error).message === 'Firebase: Error (auth/user-not-found).'
            )
                console.log(
                    'Email does not exist! Please try with correct email / create an account'
                )
            else console.log(error)
        }
    }

    // const handleAppleLogin = () => {
    //     signInWithPopup(auth, appleProvider)
    //         .then((result) => {
    //             // The signed-in user info.
    //             const user = result.user;
    //
    //             // Apple credential
    //             const credential = OAuthProvider.credentialFromResult(result);
    //
    //             const idToken = credential?.idToken;
    //             console.log("user", user.uid)
    //             console.log("id token", idToken)
    //             dispatch(signInWithAppleAction({idToken: idToken, user: user.uid}))
    //             // IdP data available using getAdditionalUserInfo(result)
    //             // ...
    //         })
    //         .catch((error) => {
    //             // Handle Errors here.
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             console.log(errorMessage, errorCode)
    //             // The email of the user's account used.
    //             const email = error.customData.email;
    //             // The credential that was used.
    //             console.log("email error", email)
    //             const credential = OAuthProvider.credentialFromError(error);
    //             console.log("creds error", credential)
    //             // ...
    //         });
    // }
    return (
        <CenterLayout>
            <Flex w={'100%'} direction={'column'} p={isMobile ? 0 : "sm"}>
                <Flex
                    direction='column'
                    justify='center'
                    align={isMobile ? 'start' : 'center'}
                >
                    <Title order={isMobile ? 3 : 1}>Welcome !</Title>
                    <Text>Signup or login in to continue</Text>
                </Flex>
                <form onSubmit={form.onSubmit((values) => {
                    dispatch(signupAction(values)).then((data) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        if (data.payload.success) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            navigate("/otp", {state: {email: values.email}})
                        }
                    })

                })}>
                    <TextInput fw={200} size={isMobile ? "md" : "lg"} radius={isMobile ? "md" : "lg"} withAsterisk
                               w="100%"
                               label="Your Email"
                               placeholder="jhondoe@expamle.com" {...form.getInputProps('email')}/>
                    <Flex justify="end" mt="lg">
                        <Anchor onClick={() => {
                            navigate("/forgot-password")
                        }} style={{color: "#62BE12"}}>Forgot Password?</Anchor>
                    </Flex>
                    <Button fw={200} radius="xl" loading={loaders.signupLoader} type="submit" size="lg" color="#62BE12"
                            w="100%"
                            mt="lg">Sign
                        up</Button>
                </form>
                <Divider my="xs" label="or login with" labelPosition="center"/>
                <Flex direction="column" justify={isMobile ? "space-between" : "space-evenly"} gap="lg" my="sm">
                    <Card style={{cursor: "pointer"}} bg="#F0F1F5" withBorder onClick={handleGoogleLogin} w="100%"
                          h="50px"
                          p={0} radius="xl">
                        <Flex align="center" h="100%" w="100%">
                            <Avatar mx="sm" size="30px"
                                    src={google}/>
                            <Flex h="100%" w={isMobile ? "75%" : "85%"} justify="center" align="center">
                                <Text>Continue with Google</Text>
                            </Flex>
                        </Flex>
                    </Card>
                </Flex>
                <Divider my="xs" label="or" labelPosition="center"/>
                <Flex gap="sm" justify="center">
                    <Text>Already have an account?</Text>
                    <Anchor c="#62BE12" onClick={() => {
                        navigate("/sign-in")
                    }}>Sign in</Anchor>
                </Flex>
                <Flex direction='column' justify='center' align='center' my='sm'>
                    <Flex gap='xs' align='center' w='100%' justify='center'>
                        <Text size='sm' ta='center'>
                            By continuing, you agree to our{' '}
                            <Anchor underline='always' c='black' fw={700}
                                    href="https://tigerit.app/terms-and-conditions"
                                    target="_blank">
                                {' '}
                                Terms of Service
                            </Anchor>{' '}
                            and
                        </Text>
                    </Flex>
                    <Flex gap='xs' align='center' w='100%' justify='center'>
                        <Text size='sm'>
                            acknowledge our{' '}
                            <Anchor underline='always' c='black' fw={700} href="https://tigerit.app/privacy-policy"
                                    target="_blank">
                                Privacy Policy
                            </Anchor>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </CenterLayout>
    );
};

export default SignUp;