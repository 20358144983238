import {initializeApp} from "firebase/app";
import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth";
// import {getMessaging, getToken, onMessage} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyD9QCVYT3NYA0QWJEZNwCvigs2Zc-nhQog",
    authDomain: "tigerit-5c321.firebaseapp.com",
    databaseURL: "https://tigerit-5c321-default-rtdb.firebaseio.com",
    projectId: "tigerit-5c321",
    storageBucket: "tigerit-5c321.appspot.com",
    messagingSenderId: "456934912184",
    appId: "1:456934912184:web:9c057d209ee3cd1a42444d",
    measurementId: "G-GTXFXHD3ZR"
};

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const provider = new GoogleAuthProvider()
const appleProvider = new OAuthProvider('apple.com')
// const messaging = getMessaging(app)


export {
    auth,
    createUserWithEmailAndPassword,
    provider,
    signInWithEmailAndPassword,
    signInWithPopup,
    appleProvider,
    OAuthProvider
}