import {
    Accordion,
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Divider,
    em,
    Flex,
    Grid,
    Group,
    Image,
    LoadingOverlay,
    Menu,
    rem,
    ScrollArea,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {modals} from "@mantine/modals";
import {IconDotsVertical, IconPhoto} from "@tabler/icons-react";
import {useAppSelector} from "../../../store/store.ts";
import {useState} from "react";

const items = [
    {title: 'Products', href: '/seller/products', color: '#62BE12'},
    {title: 'Product Detail', href: '#', color: 'black'},
].map((item, index) => (
    <Anchor size='sm' c={item.color} href={item.href} key={index}>
        {item.title}
    </Anchor>
))
const ProductDetails = () => {
    // const navigate = useNavigate();
    const isLaptopL = useMediaQuery(`(max-width: ${em(1440)})`)
    const isLaptop = useMediaQuery(`(max-width: ${em(1024)})`)
    const {singleProduct, loaders} = useAppSelector((state) => state.product)
    const [selectedImage, setSelectedImage] = useState(singleProduct.product.productImage)
    const handleImageClick = (image: string) => {
        setSelectedImage(image)
    }

    const openModal = (approval: string) =>
        modals.openConfirmModal({
            title: 'Are You Sure?',
            centered: true,
            children: (
                <Text size='sm'>
                    You want to change the approval type
                </Text>
            ),
            labels: {confirm: 'Confirm', cancel: 'Cancel'},
            // confirmProps: {color: Colors.primaryColor, c: Colors.secondaryColor},
            onCancel: () => console.log('Cancel'),
            onConfirm: () => {
                console.log(approval)
                // setLoading(true)
                // const data = {
                //     productId: singleProduct?.product?._id,
                //     status: approval,
                // }
                // if (data) {
                //     dispatch(updateProductByIdAction(data))
                //         .then(() => {
                //             // setLoading(false)
                //             navigate('dasboard/products')
                //             navigate(-1)
                //         })
                //         .catch(() => {
                //             // setLoading(false)
                //             navigate(-1)
                //         })
                // }
            },
        })
    return (
        <Flex w='100%' gap='sm' direction='column'>
            <LoadingOverlay
                visible={loaders.gettingOneProduct}
                zIndex={1000}
                overlayProps={{radius: 'sm', blur: 2}}
                loaderProps={{color: "#62BE12", type: 'bars'}}
            />
            <Breadcrumbs separator='>' separatorMargin='md'>
                {items}
            </Breadcrumbs>
            <Flex align={'center'} justify='space-between'>
                <Flex align={'center'}>
                    <Title order={3}>Product Detail</Title>
                </Flex>
                {/* <Group>
              <Button
                component='a'
                fw={700}
                w={160}
                h={56}
                my='sm'
                radius='xl'
                color={Colors.primaryColor}
                bg={"white"}
                variant="outline"
                onClick={() => navigate('')}
              >
                <Text c={"black"}>Delete</Text>
              </Button>
              <Button
                component='a'
                fw={700}
                w={160}
                h={56}
                my='sm'
                radius='xl'
                variant='filled'
                // leftSection={<IconPlus />}
                color={Colors.primaryColor}
                c={"black"}
                onClick={() => navigate('')}
              >
                Edit
              </Button>

            </Group> */}
                <Group>
                    <Button
                        onClick={() => openModal('rejected')}
                        // disabled={singleProduct?.product?.availabilityStatus === 'rejected'}
                        radius='xl'
                        variant="outline"
                        color="#62BE12"
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={() => openModal('active')}
                        // disabled={singleProduct?.product?.availabilityStatus === 'active'}
                        radius='xl'
                        color="#62BE12"
                    >
                        Edit
                    </Button>
                    <Menu withArrow shadow='md' position='bottom-end'>
                        <Menu.Target>
                            <ActionIcon variant='white' c='black' radius='xl'>
                                <IconDotsVertical
                                    style={{width: rem(16), height: rem(16)}}
                                />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                onClick={() => openModal('inactive')}
                                // disabled={singleProduct?.product?.availabilityStatus === 'inactive'}
                            >
                                Inactive
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Flex>
            {/* <Flex justify="space-between">
            <Title order={3}>Product Detail</Title>
            <Group>
              <Button onClick={() => openModal("rejected")} disabled={
                singleProduct?.product?.availabilityStatus === "rejected"}
                radius="xl" fw={200} color="red">Reject</Button>
              <Button onClick={() => openModal("active")} disabled={
                singleProduct?.product?.availabilityStatus === "active"}
                fw={200} radius="xl" color="#20C375">Accept</Button>
              <Menu withArrow shadow="md" position="bottom-end">
                <Menu.Target>
                  <ActionIcon variant="white" c="black" radius="xl">
                    <IconDotsVertical style={{ width: rem(16), height: rem(16) }} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item onClick={() => openModal("inactive")}
                    disabled={singleProduct?.product?.availabilityStatus === "inactive"}>
                    Inactive
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>

            </Group>
          </Flex> */}

            <Flex
                gap='xs'
                justify={
                    isLaptop ? 'space-between' : isLaptopL ? 'space-between' : ''
                }
            >
                <Flex w={isLaptop ? '45%' : isLaptopL ? '50%' : '30%'}>
                    <Box w={'100%'}>
                        {singleProduct?.product?.images.length > 0 ? (
                            <Grid pr={60} w={'100%'}>
                                <Grid.Col span={4}>
                                    {singleProduct?.product?.images.map((image, index) => (
                                        <Card
                                            h={isLaptop ? 65 : 80}
                                            w={isLaptop ? 60 : 90}
                                            p={0}
                                            key={index}
                                            onClick={() => handleImageClick(image)}
                                            style={{marginBottom: '10px', cursor: 'pointer'}}
                                        >
                                            <Image
                                                h={'100%'}
                                                w={'100%'}
                                                src={image}
                                                fit='contain'
                                            />
                                        </Card>
                                    ))}
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <Flex w='100%' justify='center'>
                                        <Box h={isLaptop ? 200 : 340} pl={20}>
                                            {selectedImage ? (
                                                <Image
                                                    src={selectedImage}
                                                    style={{
                                                        height: '100%',
                                                        width: 'auto',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            ) : (
                                                <Image
                                                    src={singleProduct?.product?.productImage}
                                                    style={{maxHeight: '100%', maxWidth: '100%'}}
                                                />
                                            )}
                                        </Box>
                                    </Flex>
                                </Grid.Col>
                            </Grid>
                        ) : (
                            <Flex justify='center' align='center' h={400}>
                                <IconPhoto size={200}/>
                            </Flex>
                        )}
                    </Box>
                </Flex>
                <Flex w={isLaptop ? '55%' : isLaptopL ? '60%' : '70%'} h='100%'>
                    <Card w='100%'>
                        <ScrollArea>
                            <Text c='gray'>Product name:</Text>
                            {!singleProduct?.product?.productName ? (
                                <Text fz='sm  '>N/A</Text>
                            ) : (
                                <Text fz='sm'>{singleProduct?.product?.productName}</Text>
                            )}
                            <Flex my='sm'>
                                <Stack w='100%'>
                                    <Flex direction='column'>
                                        <Text c='gray'>Product price:</Text>
                                        <Text fz='sm'>
                                            {singleProduct?.product?.productPrice.amount}{' '}
                                            {singleProduct?.product?.productPrice?.currency}
                                        </Text>
                                    </Flex>
                                    <Flex direction='column'>
                                        <Text c='gray'>Product Quantity:</Text>
                                        <Text fz='sm'>{singleProduct?.product?.quantity}</Text>
                                    </Flex>
                                    <Flex direction='column'>
                                        <Text c='gray'>Promoted:</Text>
                                        {singleProduct?.product?.promoted ? (
                                            <Text fz='sm'>yes</Text>
                                        ) : (
                                            <Text fz='sm'>No</Text>
                                        )}
                                    </Flex>

                                    <Flex direction='column'>
                                        <Text c='gray'>Stock available:</Text>
                                        {singleProduct?.product?.stockStatus ? (
                                            <Text fz='sm'>yes</Text>
                                        ) : (
                                            <Text fz='sm'>No</Text>
                                        )}
                                    </Flex>
                                    <Flex direction='column'>
                                        <Text c='gray'>Product type:</Text>
                                        <Text fz='sm'>{singleProduct?.product?.category}</Text>
                                    </Flex>
                                </Stack>
                                <Stack w='100%'>
                                    <Flex direction='column'>
                                        <Text c='gray'>Product discount:</Text>
                                        <Text fz='sm'>{singleProduct?.product?.discount}%</Text>
                                    </Flex>
                                    <Flex direction='column'>
                                        <Text c='gray'>Featured:</Text>
                                        {singleProduct?.product?.featured ? (
                                            <Text fz='sm'>yes</Text>
                                        ) : (
                                            <Text fz='sm'>No</Text>
                                        )}
                                    </Flex>
                                    <Flex direction='column'>
                                        <Text c='gray'>Color:</Text>
                                        {!singleProduct?.product?.color ? (
                                            <Text fz='sm'>N/A</Text>
                                        ) : (
                                            <Text fz='sm'>{singleProduct?.product?.color}</Text>
                                        )}
                                    </Flex>
                                    <Flex direction='column'>
                                        <Text c='gray'>Category:</Text>
                                        <Text fz='sm'>{singleProduct?.product?.category[0]}</Text>
                                    </Flex>
                                    <Flex direction='column'>
                                        <Text c='gray'>Availability status:</Text>
                                        <Text fz='sm'>
                                            {/* <Badge color="#62BE12"
                            variant="light">{singleProduct?.product?.availabilityStatus}</Badge> */}
                                            {singleProduct?.product?.availabilityStatus ===
                                            'active' ||
                                            singleProduct?.product?.availabilityStatus ===
                                            'rejected' ? (
                                                <Badge
                                                    size='lg'
                                                    component='button'
                                                    fw={200}
                                                    variant='light'
                                                    color={
                                                        singleProduct?.product?.availabilityStatus ===
                                                        'active'
                                                            ? '#20C375'
                                                            : 'red'
                                                    }
                                                >
                                                    {singleProduct?.product?.availabilityStatus}
                                                </Badge>
                                            ) : (
                                                <Badge
                                                    size='lg'
                                                    component='button'
                                                    fw={200}
                                                    variant='light'
                                                    color={'#20C375'}
                                                >
                                                    {singleProduct?.product?.availabilityStatus}
                                                </Badge>
                                            )}
                                        </Text>
                                    </Flex>
                                </Stack>
                            </Flex>
                            <Divider/>
                            <Accordion defaultValue='Product Highlights'>
                                <Accordion.Item value='Product Highlights'>
                                    <Accordion.Control>Product Highlights</Accordion.Control>
                                    {singleProduct?.product?.highlights && (
                                        <Accordion.Panel opacity={0.5}>
                                            {singleProduct?.product?.highlights}
                                        </Accordion.Panel>
                                    )}
                                </Accordion.Item>
                                <Accordion.Item value='Product Description'>
                                    <Accordion.Control>Product Description</Accordion.Control>
                                    {singleProduct?.product?.description && (
                                        <Accordion.Panel opacity={0.5}>
                                            {singleProduct?.product?.description}
                                        </Accordion.Panel>
                                    )}
                                </Accordion.Item>
                                <Accordion.Item value='In the Box'>
                                    <Accordion.Control>In the Box</Accordion.Control>
                                    {singleProduct?.product?.whatInsideTheBox && (
                                        <Accordion.Panel opacity={0.5}>
                                            {singleProduct?.product?.whatInsideTheBox}
                                        </Accordion.Panel>
                                    )}
                                </Accordion.Item>
                            </Accordion>
                            {/*<Text my="sm" c="gray">*/}
                            {/*    Product Highlights:*/}
                            {/*</Text>*/}
                            {/*{!singleProduct?.product?.highlights ? (*/}
                            {/*    <Text fz="sm">N/A</Text>*/}
                            {/*) : (*/}
                            {/*    <Text fz="sm">{singleProduct?.product?.highlights}</Text>*/}
                            {/*)}*/}
                            {/*<Text my="sm" c="gray">*/}
                            {/*    Product Description:*/}
                            {/*</Text>*/}
                            {/*{!singleProduct?.product?.description ? (*/}
                            {/*    <Text fz="sm">N/A</Text>*/}
                            {/*) : (*/}
                            {/*    <Text fz="sm">{singleProduct?.product?.description}</Text>*/}
                            {/*)}*/}
                            {/*<Text c="gray" my="sm">*/}
                            {/*    What in the box:*/}
                            {/*</Text>*/}
                            {/*{!singleProduct?.product?.whatInsideTheBox ? (*/}
                            {/*    <Text fz="sm">N/A</Text>*/}
                            {/*) : (*/}
                            {/*    <Text fz="sm">*/}
                            {/*        {singleProduct?.product?.whatInsideTheBox}*/}
                            {/*    </Text>*/}
                            {/*)}*/}
                        </ScrollArea>
                        {/*<Flex justify="flex-end" h="100%">*/}
                        {/*    <Button*/}
                        {/*        my="sm"*/}
                        {/*        loading={loading}*/}
                        {/*        type="submit"*/}
                        {/*        color="Colors.primaryColor"*/}
                        {/*        onClick={openModal}*/}
                        {/*        disabled={*/}
                        {/*            approvalType === singleProduct?.product?.availabilityStatus*/}
                        {/*        }*/}
                        {/*        style={{alignSelf: "end"}}*/}
                        {/*    >*/}
                        {/*        Save*/}
                        {/*    </Button>*/}
                        {/*</Flex>*/}
                    </Card>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ProductDetails;