import {ActionIcon, Avatar, Badge, Card, Menu, Skeleton, Table} from "@mantine/core";
import {IconDotsVertical} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import {customShortenId} from "../../../utils/shortenId.ts";
import {Order} from "../../../interfaces/orders/IOrdersList.ts";

// const elements = [
//     {
//         itemCode: "4991",
//         image: "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-3.png",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         orderId: "#34991",
//         price: '$4500',
//         status: "In Stock",
//         Type: "Pickup",
//         Quantity: "3"
//     },
//     {
//         itemCode: "34991",
//         image: "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-3.png",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         orderId: "#34991",
//         price: '$4500',
//         status: "In Stock",
//         Type: "Pickup",
//         Quantity: "3"
//     },
//     {
//         itemCode: "34991",
//         image: "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-3.png",
//         name: 'iPhone 14 Pro Max 256GB Deep Purple',
//         orderId: "#34991",
//         price: '$4500',
//         status: "In Stock",
//         Type: "Pickup",
//         Quantity: "3"
//     },
// ];

type OrderTableProps = {
    orders: Order[]
    isBuyer: boolean
    loading: boolean
}

const OrdersTable = ({orders, isBuyer, loading}: OrderTableProps) => {

    const navigate = useNavigate()


    const items = orders?.map((element, index) => {
        return (
            <Table.Tr key={index} style={{cursor: "pointer"}}
                      onClick={() => {
                          if (isBuyer) {
                              navigate(`/order-detail/${element?._id}`)
                          } else {
                              navigate(`/seller/order-details/${element?._id}`)
                          }
                      }}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>
                    {loading ? <Skeleton circle height={30} width="100%"/> :
                        <Avatar src={element?.items[0]?.image} radius="xs"/>}
                </Table.Td>
                <Table.Td>{loading ? <Skeleton height={8} width="310px"/> : element?.items[0]?.title}</Table.Td>
                <Table.Td>{loading ?
                    <Skeleton height={8} width="100%"/> : customShortenId(element?._id)}</Table.Td>
                <Table.Td>
                    {loading ? <Skeleton height={8} width="100%"/> :
                        <Badge variant="light" color="#20C375">{element?.orderStatus}</Badge>}
                </Table.Td>
                <Table.Td>
                    {loading ? <Skeleton height={8} width="100%"/> :
                        <Badge variant="light" color="#F5F6FA" c="black">{element?.deliverType}</Badge>}
                </Table.Td>
                <Table.Td>{loading ?
                    <Skeleton height={8} width="100%"/> : element?.totalOrderAmount?.amount?.toLocaleString("en-US", {
                        style: "currency",
                        currency: element?.totalOrderAmount?.currency
                    })}</Table.Td>
                <Table.Td>{loading ? <Skeleton height={8} width="100%"/> : element?.items?.length}</Table.Td>
                <Table.Td>
                    <Menu shadow="md" width={200} position="left-start" offset={1}>
                        <Menu.Target>
                            <ActionIcon radius="xl" color="#F5F6FA" c="black" size="lg">
                                <IconDotsVertical/>
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item>
                                Edit Order
                            </Menu.Item>
                            <Menu.Item>
                                Delete Order
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>

                </Table.Td>
            </Table.Tr>
        )
    })


    return (
        <Card bg="white" radius="lg" my="sm">
            <Table.ScrollContainer minWidth={500} type="native">
                <Table verticalSpacing="md" withRowBorders={false}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>#</Table.Th>
                            <Table.Th>Product</Table.Th>
                            <Table.Th></Table.Th>
                            <Table.Th>Order ID</Table.Th>
                            <Table.Th>Status</Table.Th>
                            <Table.Th>Type</Table.Th>
                            <Table.Th>Amount</Table.Th>
                            <Table.Th>Quantity</Table.Th>
                            <Table.Th>Actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {items}
                    </Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </Card>
    );
};

export default OrdersTable;