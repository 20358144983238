import {
  Anchor,
  em,
  Flex,
  Group,
  Image,
  rem,
  Stack,
  Text,
} from "@mantine/core";
import apple from "../assets/apple-store.png";
import google from "../assets/google-store.png";
import { IconLocation, IconMail } from "@tabler/icons-react";
import { useMediaQuery, useWindowScroll } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils/storage.ts";
import logo from "../assets/1-Tigerit-logo-2.png";
import { useEffect } from "react";
import fallbackImage from "../assets/Placholder-01.png";

const Footer = () => {
  const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
  const navigate = useNavigate();
  const token = getToken();
  const [scroll, scrollTo] = useWindowScroll();

  useEffect(() => {
    console.log("scrol", scroll);
  }, []);

  return (
    <Flex
      p={isMobile ? "sm" : "lg"}
      w="100%"
      style={{
        backgroundColor: "#ECEEE9",
        color: "white",
        borderWidth: 10,
        borderColor: "White",
      }}
    >
      <Flex
        m={isMobile ? "" : "xl"}
        direction={isMobile ? "column" : "row"}
        align={isMobile ? "center" : ""}
        justify={isMobile ? "" : "space-evenly"}
        w="100%"
      >
        <Stack w={isMobile ? "100%" : "25%"} h="100%" ml={isMobile ? "xs" : 50}>
          <Image
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/buyer");
              scrollTo({ y: 0 });
            }}
            src={logo}
            h="50px"
            w="170px"
            visibleFrom="lg"
            fallbackSrc={fallbackImage}
          />
          <Text c="black">
            We deliver your local favorites to you, CRAZY Fast! <br /> Just,
            TIGER IT! Now.
          </Text>
          <Group>
            <Anchor href="https://apps.apple.com/pk/app/tiger-it/id6474063205">
              <Image
                radius="md"
                h={50}
                w={150}
                src={apple}
                fallbackSrc={fallbackImage}
              />
            </Anchor>
            <Anchor href="https://play.google.com/store/apps/details?id=com.crazytechlabs.tigerit">
              <Image
                radius="md"
                h={50}
                w={150}
                src={google}
                fallbackSrc={fallbackImage}
              />
            </Anchor>
          </Group>
        </Stack>
        <Flex w={isMobile ? "100%" : "25%"} my={isMobile ? "lg" : ""}>
          <Stack w={isMobile ? "100%" : "50%"} h="100%">
            <Flex mih={50} gap="lg" direction="column" wrap="wrap">
              <Anchor c="black" underline="hover" href="about-us">
                About Us
              </Anchor>
              <Anchor
                c="black"
                underline="hover"
                onClick={() =>
                  token
                    ? navigate("/contact-us-buyer")
                    : navigate("/contact-us")
                }
              >
                FAQ'S
              </Anchor>
              <Anchor
                underline="hover"
                href="https://tigerit.app/terms-and-conditions"
                target="_blank"
                c="black"
              >
                Terms & Conditions
              </Anchor>
            </Flex>
          </Stack>
          <Stack w={isMobile ? "100%" : "50%"} h="100%">
            <Flex mih={50} gap="lg" direction="column" wrap="wrap">
              <Anchor
                underline="hover"
                href="https://tawk.to/chat/65eea7698d261e1b5f6b657f/1hom3rtkb"
                target="_blank"
                c="black"
              >
                Customer Service
              </Anchor>
              <Anchor
                underline="hover"
                href="https://tawk.to/chat/65eea7698d261e1b5f6b657f/1hom3rtkb"
                target="_blank"
                c="black"
              >
                Help Center
              </Anchor>
              <Anchor
                underline="hover"
                href="https://tigerit.app/privacy-policy"
                target="_blank"
                c="black"
              >
                Privacy Policy
              </Anchor>
              {/*<Text c="black">Installment Plan</Text>*/}
              {/*<Text c="black">Warranty Policy</Text>*/}
              {/*<Text component="a" href="http://localhost:5173/terms-and-conditions" target="_blank"*/}
              {/*      c="black">Terms*/}
              {/*    of Sale</Text>*/}
            </Flex>
          </Stack>
        </Flex>
        <Stack w={isMobile ? "100%" : "25%"} h="100%">
          <Flex
            mih={50}
            gap="lg"
            justify={isMobile ? "" : ""}
            align={isMobile ? "" : ""}
            direction="column"
            wrap="wrap"
            w="100%"
          >
            <Group style={{ minWidth: 200 }}>
              <IconMail
                style={{ width: rem(20), height: rem(20) }}
                stroke={2}
                color="black"
              />
              <Anchor
                href="mailto:contact@tigerit.com"
                underline="hover"
                c="black"
              >
                contact@tigerit.com
              </Anchor>
            </Group>

            <Group style={{ minWidth: 200 }}>
              <IconLocation
                style={{ width: rem(20), height: rem(20) }}
                stroke={2}
                color="black"
              />
              <Text c="black">Florida, USA</Text>
            </Group>
            <Group>
              <Image />
              <Image />
              <Image />
              <Image />
              <Image />
            </Group>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default Footer;
