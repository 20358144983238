export const calculateRatings = (totalRatings: number, numberRatings: number) => {
    if (totalRatings > 0 && numberRatings > 0) {
        return Math.round(((totalRatings / numberRatings) + Number.EPSILON) * 10) / 10;
    }
    return 0;
}

export const calculateStars = (totalRatings: number, numberRatings: number) => {
    if (totalRatings > 0 && numberRatings > 0) {
        return Math.round(((totalRatings / numberRatings) + Number.EPSILON) * 100) / 100;
    }
    return 0;
}