import {createSlice} from "@reduxjs/toolkit";
import {IOrdersListResponse} from "../../interfaces/orders/IOrdersList.ts";
import {
    getAllBusinessOrdersAction,
    getAllOrdersAction,
    getSingleBusinessOrderAction,
    getSingleOrderAction
} from "./ordersActions.ts";
import {ISingleOrder} from "../../interfaces/orders/ISingleOrder.ts";
import {ISingleBusinessOrder} from "../../interfaces/orders/ISingleBusinessOrder.ts";

interface initialState {
    orders: IOrdersListResponse
    singleOrder: ISingleOrder
    singleBusinessOrder: ISingleBusinessOrder
    loaders: {
        gettingOrders: boolean
        gettingSingleOrder: boolean
        updatingOrderStatus: boolean
        gettingSingleBusinessOrder: boolean
    }
}

const initialState: initialState = {
    orders: {
        success: false,
        hasNext: false,
        orders: [],
        totalPages: 0,
        page: "",
        limit: ""
    },
    singleOrder: {
        success: false,
        data: {
            _id: "",
            orderStatus: "",
            shippingAddress: {
                formattedAddress: "",
                isoCode: "",
                longitude: 0,
                latitude: 0,
                country: "",
                state: "",
                city: "",
                type: "",
                coordinates: []
            },
            pickupAddress: {
                formattedAddress: "",
                isoCode: "",
                longitude: 0,
                latitude: 0,
                country: "",
                state: "",
                city: "",
                type: "",
                coordinates: []
            },
            note: "",
            statusDetails: [],
            deliverType: "",
            pickupSecret: 0,
            driverDetails: [],
            createdAt: "",
            products: [],
            totals: [],
            totalOrderAmount: {
                amount: 0,
                currency: ""
            },
            orderReview: undefined
        }
    },
    singleBusinessOrder: {
        success: false,
        order: {
            _id: "",
            orderStatus: "",
            shippingAddress: {
                formattedAddress: "",
                isoCode: "",
                longitude: 0,
                latitude: 0,
                country: "",
                state: "",
                city: "",
                coordinates: []
            },
            pickupAddress: {
                formattedAddress: "",
                isoCode: "",
                longitude: 0,
                latitude: 0,
                country: "",
                state: "",
                city: "",
                type: "",
                coordinates: []
            },
            note: "",
            statusDetails: [],
            deliverType: "",
            pickupSecret: 0,
            driverDetails: [],
            createdAt: "",
            products: [],
            totals: [],
            buyerInfo: {
                name: "",
                email: "",
                phone: ""
            },
            totalOrderAmount: {
                amount: 0,
                currency: ""
            }
        }
    },
    loaders: {
        gettingOrders: false,
        gettingSingleOrder: false,
        updatingOrderStatus: false,
        gettingSingleBusinessOrder: false
    }

}

const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        setOrderStatus: (state, action) => {
            state.singleOrder.data.orderStatus = action.payload
        }
    },
    extraReducers: builder =>
        builder
            // getting all orders
            .addCase(getAllOrdersAction.pending, (state) => {
                state.loaders.gettingOrders = true
            })
            .addCase(getAllOrdersAction.fulfilled, (state, action) => {
                state.loaders.gettingOrders = false
                if (!action.payload) return
                state.orders = action.payload
            })
            .addCase(getAllOrdersAction.rejected, (state) => {
                state.loaders.gettingOrders = false
            })
            // getting all business
            .addCase(getAllBusinessOrdersAction.pending, (state) => {
                state.loaders.gettingOrders = true
            })
            .addCase(getAllBusinessOrdersAction.fulfilled, (state, action) => {
                state.loaders.gettingOrders = false
                if (!action.payload) return
                state.orders = action.payload
            })
            .addCase(getAllBusinessOrdersAction.rejected, (state) => {
                state.loaders.gettingOrders = false
            })
            // getting single order
            .addCase(getSingleOrderAction.pending, (state) => {
                state.loaders.gettingSingleOrder = true
            })
            .addCase(getSingleOrderAction.fulfilled, (state, action) => {
                state.loaders.gettingSingleOrder = false
                if (!action.payload) return
                state.singleOrder = action.payload
            })
            .addCase(getSingleOrderAction.rejected, (state) => {
                state.loaders.gettingSingleOrder = false
            })
            // getting single business order
            .addCase(getSingleBusinessOrderAction.pending, (state) => {
                state.loaders.gettingSingleBusinessOrder = true
            })
            .addCase(getSingleBusinessOrderAction.fulfilled, (state, action) => {
                state.loaders.gettingSingleBusinessOrder = false
                if (!action.payload) return
                state.singleBusinessOrder = action.payload
            })
            .addCase(getSingleBusinessOrderAction.rejected, (state) => {
                state.loaders.gettingSingleBusinessOrder = false
            })
    // updating order status
    // .addCase(updateOrderStatusAction.pending, (state) => {
    //     state.loaders.updatingOrderStatus = true
    // })
    // .addCase(updateOrderStatusAction.fulfilled, (state, action) => {
    //     state.loaders.updatingOrderStatus = false
    //     if (!action.payload) return
    //     state.singleOrder.data = action.payload.order
    // })
    // .addCase(updateOrderStatusAction.rejected, (state) => {
    //     state.loaders.updatingOrderStatus = false
    // })
})
const ordersReducer = ordersSlice.reducer
export const {setOrderStatus} = ordersSlice.actions
export default ordersReducer