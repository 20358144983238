import {Divider, em, Flex, Text, Title} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";

const TermsAndConditions = () => {

    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    
    return (
        <Flex p="sm" direction="column" w="100%" justify="center" align="center">
            <Flex direction="column" w={isMobile ? "100%" : "70%"}>
                <Title opacity={0.7}>Terms of Service</Title>
                <Text ml="xs" opacity={0.5}>Updated August 2024</Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Acceptance of Terms</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>By accessing or using the Tiger it platform, you agree to be bound by these Terms
                    and
                    Conditions. If you do not agree with any part of these terms, please do not use our services.
                    Users must register an account to access certain features. You agree to provide accurate, complete,
                    and
                    current information during the registration process. You are responsible for maintaining the
                    confidentiality of your account details and for all activities that occur under your account. Notify
                    us
                    immediately of any unauthorized use or breach of security.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Services Provided</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>The platform provides navigation, safety tools, and delivery management for drivers.
                    New drivers must complete vehicle information and get admin approval before starting deliveries. The
                    platform offers a seamless, secure shopping experience for buyers, allowing efficient browsing,
                    purchasing, and order management. The platform streamlines the selling process for sellers,
                    expanding their reach. New sellers must fill in their business information for verification and
                    approval by the admin. Sellers can manage their orders, products, and businesses through the seller
                    dashboard.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Use of the Platform</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>You may use the platform for lawful purposes only. Any unauthorized use or
                    interference with the platform's operation is prohibited. Users must not engage in activities that
                    harm or disrupt the platform, including hacking, spreading malware, or unauthorized access.
                </Text>
                <Divider mt="lg"
                         label={<Title opacity={0.7} order={3} c="black">Content and Intellectual Property</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>All content, trademarks, and data on the Tiger it platform are owned by Tiger it or
                    its licensors. Unauthorized use is prohibited. Users retain ownership of content they upload but
                    grant Tiger it a non-exclusive, royalty-free license to use, display, and distribute such content in
                    connection with the platform's operation.
                </Text>
                <Divider mt="lg"
                         label={<Title opacity={0.7} order={3} c="black">Payment and Fees</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>Fees may apply for certain services provided through the platform. All applicable
                    fees are clearly outlined on the platform. Users are responsible for making timely payments for
                    services used. Failure to make payments may result in the suspension or termination of your account
                    and access to the platform.
                </Text>
                <Divider mt="lg"
                         label={<Title opacity={0.7} order={3} c="black">Privacy and Data Protection</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>Tiger it collects and uses user data in accordance with our Privacy Policy. We
                    collect personal information, including current location and delivery location, to facilitate
                    product delivery. We implement reasonable measures to protect user data but cannot guarantee
                    absolute security. Users are encouraged to use the platform responsibly and take necessary
                    precautions to protect their information.
                </Text>
                <Divider mt="lg"
                         label={<Title opacity={0.7} order={3} c="black">Liability and Disclaimers</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>Tiger it is not liable for any indirect, incidental, or consequential damages
                    arising from the use of the platform. Our liability is limited to the maximum extent permitted by
                    law. The platform is provided "as is" without warranties of any kind, either express or implied. We
                    do not guarantee the accuracy, completeness, or reliability of any content on the platform.
                </Text>
                <Divider mt="lg"
                         label={<Title opacity={0.7} order={3} c="black">Termination and Governing Law</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>Users can terminate their account at any time, but obligations incurred before
                    termination remain in effect. Tiger it reserves the right to terminate or suspend access to the
                    platform for any reason, with or without notice. These Terms and Conditions are governed by and
                    construed in accordance with the laws of the State of Florida, USA. Any disputes arising from these
                    terms will be resolved in the state or federal courts located in Florida, USA.
                </Text>
            </Flex>
        </Flex>
    );
};

export default TermsAndConditions;