import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {IWalletTopUp} from "../../interfaces/wallet/IWalletTopUp.ts";
import {notificationSystem} from "../../utils/notificationSystem.ts";
import {IWallettopUpResponse} from "../../interfaces/wallet/IWalletTopUpResponse.ts";
import {IWalletWithTransactionHistoryResponse} from "../../interfaces/wallet/IWalletWithTransactionHistoryResponse.ts";

export const topUpAction = createAsyncThunk("wallet/topUp", async (data: IWalletTopUp) => {
    try {
        const res = await axiosInstance.post<IWallettopUpResponse>("buyer/topup", data)
        if (res.data.success) {
            notificationSystem("Top up successful", `${res.data.responsePayment.topupAmount.amount} ${res.data.responsePayment.topupAmount.currency} has been added to your wallet successfully!`, false)
        } else {
            notificationSystem("Something went wrong", "", true)
        }
        return res.data
    } catch (e) {
        notificationSystem("Something went wrong", "", true)
    }
})

export const getTransactionHistory = createAsyncThunk("wallet/getTransactionHistory", async () => {
    try {
        const res = await axiosInstance.get<IWalletWithTransactionHistoryResponse>("wallet")
        if (!res.data.success) {
            notificationSystem("Something went wrong", "", true)
        }
        return res.data
    } catch (e) {
        notificationSystem("Something went wrong", "", true)
    }
})