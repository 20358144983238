import {Box, Card, em, Flex, Skeleton, Text, Title} from "@mantine/core";
import {useMediaQuery, useWindowScroll} from "@mantine/hooks";
import {ISingleOrder} from "../../../../interfaces/orders/ISingleOrder.ts";
import {round} from "../../../../utils/roundOffFunction.ts";
import {useEffect} from "react";
import {currencyPrefixer} from "../../../../utils/discountCalculator.ts";

type ItemProps = {
    loading: boolean
    // openRatingModal: () => void
    order: ISingleOrder
}
const OrderItems = ({loading, order}: ItemProps) => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const [scroll, scrollTo] = useWindowScroll()

    useEffect(() => {
        scrollTo({y: 0})
        console.log(scroll)
    }, []);

    return (
        <Card bg="white" p="lg" radius="md" w={isMobile ? "100%" : "50%"}>
            <Flex direction="column" gap="sm">
                <Title order={isMobile ? 5 : 3}>Items Details</Title>
                {order?.data?.products?.length <= 0 ?
                    <Text ml="lg">No items in order</Text> : order?.data?.products?.map((value) => {
                        if (loading) {
                            return (
                                <Flex>
                                    <Skeleton h="60px" w="60px"/>
                                    <Flex mx="lg" direction="column">
                                        <Skeleton/>
                                        <Flex gap="md">
                                            <Skeleton/>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )
                        } else {
                            return (
                                <Flex gap="sm" justify="space-between">
                                    <Flex gap="sm">
                                        <Text opacity={0.5}>{value?.quantity}x</Text>
                                        <Box w={200}>
                                            <Text truncate="end" opacity={0.7}>{value?.item?.name}</Text>
                                        </Box>
                                    </Flex>
                                    <Text>{currencyPrefixer(value?.price?.amount, value?.price?.currency)}</Text>
                                </Flex>
                            )
                        }
                    })}
            </Flex>
            <Flex gap="lg" direction="column" my="sm">
                <Title order={isMobile ? 5 : 3}>Order Details</Title>
                {order?.data?.deliverType === "pickup" && <Flex gap="sm" justify="space-between" w="100%">
                    <Text opacity={0.7}>Pickup Code</Text>
                    <Box w={isMobile ? 200 : 400}>
                        <Text ta="end" truncate="end" size="lg" fw={700}
                              opacity={0.7}>{order?.data?.pickupSecret}</Text>
                    </Box>
                </Flex>}
                {order?.data?.deliverType === "delivery" &&
                    <Flex gap="sm" justify="space-between">
                        <Text>Delivery Address</Text>
                        <Text size={isMobile ? "md" : "lg"} c="gray"
                        >{order?.data?.shippingAddress?.formattedAddress}</Text>
                    </Flex>}
                <Title order={isMobile ? 5 : 3}>Order Summary</Title>

                {order?.data?.totals?.map((total, index) => {
                    return (
                        <Flex justify="space-between" key={index}>
                            <Text c="gray">{total?.title}</Text>
                            <Text c="gray">{round(total?.price?.amount)}{" "}{total?.price?.currency}</Text>
                        </Flex>
                    )
                })}
                <Flex justify="space-between">
                    <Text fw={600}>Total Order Amount</Text>
                    <Text
                        fw={600}>{round(order?.data?.totalOrderAmount?.amount)}{" "}{order?.data?.totalOrderAmount?.currency}</Text>
                </Flex>
                {/*<Flex w="100%" justify="space-between">*/}
                {/*    <Text>Rate this order</Text>*/}
                {/*    <Button fw={200}*/}
                {/*            color="#62BE12"*/}
                {/*            variant="outline"*/}
                {/*            radius="xl" onClick={openRatingModal}>Rate</Button>*/}
                {/*</Flex>*/}
            </Flex>
        </Card>
    );
};

export default OrderItems;