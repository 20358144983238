import {ActionIcon, Card, em, Flex, Image, Rating, Text} from "@mantine/core";
import {IconHeart} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import {IRestaurant} from "../../../interfaces/buyer/restaurants/IRestaurantsResponse.ts";
import {calculateRatings} from "../../../utils/ratingCalculator.ts";
import {useMediaQuery} from "@mantine/hooks";

type IRestaurantCardProps = {
    restaurant: IRestaurant
}
const RestaurantCard = ({restaurant}: IRestaurantCardProps) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);

    return (
        <Flex w={isMobile ? "150px" : "155px"} h={isMobile ? "230px" : "225px"} style={{cursor: "pointer"}}
              onClick={() => {
                  // navigate(`/buyer/product-detail/${restaurant?._id}`)
                  navigate(`/sellers-food-shop-buyer/${restaurant?._id}`, {
                      state: {
                          name: restaurant?.title,
                          address: restaurant?.address?.formattedAddress,
                          ratings: restaurant?.rating?.numRating,
                          image: restaurant?.image
                      }
                  })
              }}>
            <Card shadow="xs" w="100%" pos="relative" radius="sm">
                <Flex direction="column" justify="space-between" h="100%">
                    <Flex align="center" justify="center">
                        <Image src={restaurant?.image} alt="image of mobile"
                               w={isMobile ? "160px" : "200px"}
                               h="100px"/>
                    </Flex>
                    <ActionIcon style={{position: "absolute", top: 8, right: 6}} color="#E0E0E0"
                                radius="xl">
                        <IconHeart/>
                    </ActionIcon>
                    <Text fw={500}>{restaurant?.title}</Text>

                    <Flex gap="lg" align="center">
                        <Rating readOnly
                                value={calculateRatings(restaurant?.rating?.totalRating, restaurant?.rating?.numRating)}/>
                        <span
                            style={{color: "#A8AEBF"}}>{calculateRatings(restaurant?.rating?.totalRating, restaurant?.rating?.numRating)}</span>
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    );
};

export default RestaurantCard;