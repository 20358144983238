import {createSlice} from "@reduxjs/toolkit";
import {getAllRestaurantsAction, getOneRestaurantAction, getRestaurantBySearchAction} from "./restaurantsActions.ts";
import {IRestaurantResponse} from "../../interfaces/buyer/restaurants/IRestaurantResponse.ts";
import {IRestaurantsResponse} from "../../interfaces/buyer/restaurants/IRestaurantsResponse.ts";

interface initialState {
    restaurants: IRestaurantsResponse
    singleRestaurant: IRestaurantResponse
    loaders: {
        gettingAllRestaurants: boolean
        gettingFoodsOfRestaurant: boolean
    }
}

const initialState: initialState = {
    restaurants: {
        restaurants: [],
        length: 0,
        success: false
    },
    singleRestaurant: {
        success: false,
        restaurant: {
            restaurantDetails: {
                _id: "",
                userId: "",
                address: {
                    formattedAddress: "",
                    latitude: 0,
                    longitude: 0,
                    country: "",
                    isoCode: "",
                    state: "",
                    city: "",
                    type: "",
                    coordinates: []
                },
                title: "",
                image: "",
                type: "",
                registrationNumber: "",
                phoneNumber: "",
                category: "",
                email: "",
                description: "",
                verificationStatus: "",
                rating: {
                    totalRating: 0,
                    numRating: 0
                },
                businessDescription: ""
            },
            totalFood: 0,
            food: []
        }
    },
    loaders: {
        gettingAllRestaurants: false,
        gettingFoodsOfRestaurant: false
    }
}

const restaurantSlice = createSlice({
    name: "restaurants",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            // get all restaurants
            .addCase(getAllRestaurantsAction.pending, (state) => {
                state.loaders.gettingAllRestaurants = true
            })
            .addCase(getAllRestaurantsAction.fulfilled, (state, action) => {
                state.loaders.gettingAllRestaurants = false
                state.restaurants = action.payload
            })
            .addCase(getAllRestaurantsAction.rejected, (state) => {
                state.loaders.gettingAllRestaurants = false
            })
            // get all restaurants by food search
            .addCase(getRestaurantBySearchAction.pending, (state) => {
                state.loaders.gettingAllRestaurants = true
            })
            .addCase(getRestaurantBySearchAction.fulfilled, (state, action) => {
                state.loaders.gettingAllRestaurants = false
                state.restaurants = action.payload
            })
            .addCase(getRestaurantBySearchAction.rejected, (state) => {
                state.loaders.gettingAllRestaurants = false
            })
            // get one restaurant
            .addCase(getOneRestaurantAction.pending, (state) => {
                state.loaders.gettingFoodsOfRestaurant = true
            })
            .addCase(getOneRestaurantAction.fulfilled, (state, action) => {
                state.loaders.gettingFoodsOfRestaurant = false
                state.singleRestaurant = action.payload
            })
            .addCase(getOneRestaurantAction.rejected, (state) => {
                state.loaders.gettingFoodsOfRestaurant = false
            })
    }
})

const restaurantReducer = restaurantSlice.reducer
export default restaurantReducer