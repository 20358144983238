import {Card, CloseButton, Flex, Image, LoadingOverlay, ScrollArea, Select, Text, TextInput} from "@mantine/core";
import {Dropzone, FileWithPath, IMAGE_MIME_TYPE} from "@mantine/dropzone";
import {IconPlus} from "@tabler/icons-react";
import {UseFormReturnType} from "@mantine/form";
import {IProductAdd} from "../../../../interfaces/business/seller/IProductCreate.ts";
import DragZone from "../../../../components/DragZone.tsx";
import {useAppDispatch, useAppSelector} from "../../../../store/store.ts";
import {useEffect, useState} from "react";
import {getAllCategoriesAction, getSubCategoriesAction} from "../../../../store/category/categoriesActions.ts";
import CustomDropzone from "../../../../components/CustomDropzone.tsx";


type BasicInfoProps = {
    form: UseFormReturnType<IProductAdd, (values: IProductAdd) => IProductAdd>
    images?: File[]
    setFile: (file: FileWithPath[]) => void
    setCoverImage: (file: FileWithPath[]) => void
    coverImage: File | FileWithPath
}
const AddBasicInfo = ({form, setFile, images, setCoverImage, coverImage}: BasicInfoProps) => {

    const {categories, subCategories, loaders} = useAppSelector(state => state.categories)
    const dispatch = useAppDispatch()
    const [idForSubCategory, setIdForSubCategory] = useState<string | undefined>("")
    const handleImageDelete = (index: number) => {
        if (images) {
            const newArray = [...images];
            newArray.splice(index, 1);
            form.setValues({...form.values, imgCollection: newArray});
        }
    };

    const categoryOptions = categories?.categories?.map(category => ({
        value: category._id,
        label: category.name
    })) || [];

    useEffect(() => {
        dispatch(getAllCategoriesAction())
    }, []);


    useEffect(() => {
        if (!idForSubCategory) return
        dispatch(getSubCategoriesAction(idForSubCategory))
    }, [idForSubCategory]);

    return (
        <Card>
            <Flex direction="column" gap="sm">
                <LoadingOverlay visible={loaders.gettingAllCategories || loaders.gettingSubcategories}
                                zIndex={1000}
                                overlayProps={{radius: 'sm', blur: 2}}
                                loaderProps={{color: '#62BE12', type: 'bars'}}/>
                <Text fw={600}>Basic Information</Text>
                <CustomDropzone label="Drag & Drop files here to upload" onDrop={setCoverImage} url={coverImage}/>
                {images &&
                    <ScrollArea>
                        <Flex w="100%" gap="lg" align="center">
                            {images.length > 0 && images.map((image, index) => {
                                return (
                                    <Card h="100px" pos="relative" key={index}>
                                        <CloseButton pos="absolute" top={0} right={0} onClick={() => {
                                            handleImageDelete(index)
                                        }}/>
                                        <Image src={URL.createObjectURL(image)}
                                               alt={`Image ${index}`}
                                               h="100px" w="100px"
                                        />
                                    </Card>
                                )
                            })}
                            {images.length > 0 &&
                                <Dropzone accept={IMAGE_MIME_TYPE} h="100%" style={{border: "0px"}} bg="#F5F6FA"
                                          onDrop={(files) => {
                                              if (images) {
                                                  const newArr = [...images, files[0]]
                                                  if (newArr) {
                                                      setFile(newArr)
                                                      form.setValues({imgCollection: newArr})
                                                  }
                                              }
                                          }}>
                                    <Flex>
                                        <IconPlus color="#62BE12"/>
                                    </Flex>
                                </Dropzone>}
                        </Flex>
                    </ScrollArea>
                }
                {images?.length === 0 &&
                    <DragZone setFile={setFile} images={images} form={form}/>}
                <TextInput label="Product Name" placeholder="Enter product name" {...form.getInputProps("name")}/>
                <Select data={categoryOptions} placeholder="Select Category"
                        label="Product Category"
                        errorProps={form.errors}
                        onChange={(value) => {
                            const selectedCategory = categoryOptions.find(option => option.value === value)
                            setIdForSubCategory(selectedCategory?.value)
                            form.setFieldValue('Icategory', selectedCategory?.label ? selectedCategory?.label : "");
                        }}/>
                <Select
                    data={subCategories?.subCategories?.map((subCategory) => subCategory?.name)}
                    placeholder="Select Sub Category"
                    label="Sub Category"
                    {...form.getInputProps("subCategory")}
                />
                <TextInput label="Video URL" placeholder="Enter Video URL" {...form.getInputProps("video")}/>
            </Flex>
        </Card>
    );
};

export default AddBasicInfo;