import {Anchor, Button, Card, Divider, em, Flex, Image, PasswordInput, Text, TextInput, Title} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {useForm} from "@mantine/form";
import {signInAction, signInWithGoogleAction} from "../../store/account/accountActions.ts";
import {getFcmToken} from "../../utils/storage.ts";
import {IconEyeCheck, IconEyeOff} from "@tabler/icons-react";
import {auth, provider, signInWithPopup} from "../../config/firebaseConfig.ts";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import google from "../../assets/google.png"
import classes from "./Login.module.css"
import CenterLayout from "./CenterLayout.tsx";

const Login = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`)
    const navigate = useNavigate()
    const {loaders} = useAppSelector(state => state.account)
    const dispatch = useAppDispatch()
    // const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent)
    const form = useForm({
        initialValues: {email: '', password: ""},

        // functions will be used to validate values at corresponding key
        validate: {
            email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            password: (value) => (value.length === 0 ? 'Cannot be empty' : null)
        },
    });

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider)
            const user = result.user
            const token = await user.getIdToken()
            dispatch(signInWithGoogleAction({
                IdToken: token, deviceInfo: {
                    fcm: getFcmToken() || ""
                }
            })).then(() => {
                navigate("/buyer")
            })
        } catch (error) {
            if ((error as Error).message === 'Firebase: Error (auth/wrong-password).')
                console.log('Password is incorrect! Please try with correct password')
            else if (
                (error as Error).message === 'Firebase: Error (auth/user-not-found).'
            )
                console.log(
                    'Email does not exist! Please try with correct email / create an account'
                )
            else console.log(error)
        }
    }

    // const handleAppleLogin = () => {
    //
    //     signInWithPopup(auth, appleProvider)
    //         .then((result) => {
    //             // The signed-in user info.
    //             const user = result.user;
    //
    //             // Apple credential
    //             const credential = OAuthProvider.credentialFromResult(result);
    //
    //             const idToken = credential?.idToken;
    //             console.log("user", user.uid)
    //             console.log("id token", idToken)
    //             dispatch(signInWithAppleAction({idToken: idToken, user: user.uid}))
    //             // IdP data available using getAdditionalUserInfo(result)
    //             // ...
    //         })
    //         .catch((error) => {
    //             // Handle Errors here.
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             console.log(errorMessage, errorCode)
    //             // The email of the user's account used.
    //             const email = error.customData.email;
    //             // The credential that was used.
    //             console.log("email error", email)
    //             const credential = OAuthProvider.credentialFromError(error);
    //             console.log("creds error", credential)
    //             // ...
    //         });
    // }

    return (
        <CenterLayout>
            <Flex w={'100%'} direction={'column'} p={isMobile ? 0 : "sm"}>
                <Flex
                    direction='column'
                    justify='center'
                    gap={isMobile ? 10 : 0}
                    align={isMobile ? 'center' : 'center'}
                >
                    <Title fw={500} order={isMobile ? 3 : 3}>
                        Welcome to <span style={{color: '#9ED200'}}>Tiger it</span>{' '}
                    </Title>
                    <Title fw={500} ta={'center'} order={1}>
                        Login
                    </Title>
                </Flex>
                <form
                    onSubmit={form.onSubmit((values) => {
                        dispatch(signInAction({
                            email: values.email,
                            password: values.password,
                        }))
                    })}
                >
                    <TextInput
                        size={isMobile ? '' : 'md'}
                        radius='md'
                        label='Email'
                        classNames={{input: classes.input}}
                        fw={500}
                        placeholder='Enter Email'
                        my='sm'
                        {...form.getInputProps('email')}
                    />
                    <PasswordInput
                        size={isMobile ? 'sm' : 'md'}
                        radius='md'
                        label='Password'
                        classNames={{input: classes.input}}
                        fw={500}
                        visibilityToggleIcon={({reveal}) =>
                            reveal ? (
                                <IconEyeOff
                                    style={{
                                        width: 'var(--psi-icon-size)',
                                        height: 'var(--psi-icon-size)',
                                    }}
                                />
                            ) : (
                                <IconEyeCheck
                                    style={{
                                        width: 'var(--psi-icon-size)',
                                        height: 'var(--psi-icon-size)',
                                    }}
                                />
                            )
                        }
                        placeholder='******'
                        {...form.getInputProps('password')}
                    />
                    <Flex justify='end'>
                        <Anchor
                            size='sm'
                            py='sm'
                            c='#4285F4'
                            onClick={() => {
                                navigate('/forgot-password')
                            }}
                        >
                            Forgot Password?
                        </Anchor>
                    </Flex>
                    <Button
                        loading={loaders.signingIn}
                        w='100%'
                        radius='xl'
                        fw={200}
                        disabled={!(form.values.email && form.values.password)}
                        size={isMobile ? 'lg' : 'lg'}
                        type='submit'
                    >
                        Login
                    </Button>
                </form>
                <Divider my='xs' label='Or' labelPosition='center'/>
                <Card
                    w='100%'
                    my='sm'
                    radius='xl'
                    h={isMobile ? '52px' : '54px'}
                    style={{cursor: 'pointer', border: '0.95px solid #F0F0F0'}}
                    onClick={handleGoogleLogin}
                >
                    <Flex align='center' h='100%' w='100%'>
                        <Image src={google}/>
                        <Flex justify='center' w='100%'>
                            <Text ta='center'>Continue with Google</Text>
                        </Flex>
                    </Flex>
                </Card>
                {/*{isMac && }*/}
                {/*<Card*/}
                {/*    w='100%'*/}
                {/*    my='sm'*/}
                {/*    radius='xl'*/}
                {/*    h={isMobile ? '52px' : '54px'}*/}
                {/*    style={{cursor: 'pointer', border: '0.95px solid #F0F0F0'}}*/}
                {/*    onClick={handleAppleLogin}*/}
                {/*>*/}
                {/*    <Flex align='center' h='100%' w='100%'>*/}
                {/*        <Image src={apple}/>*/}
                {/*        <Flex justify='center' w='100%'>*/}
                {/*            <Text ta='center'>Continue with Apple</Text>*/}
                {/*        </Flex>*/}
                {/*    </Flex>*/}
                {/*</Card>*/}
                <Flex gap='xs' align='center' w='100%' justify='center' my='sm'>
                    <Text size='sm'>Don’t have an account?</Text>
                    <Anchor
                        c='black'
                        fw={700}
                        onClick={() => {
                            navigate('/sign-up')
                        }}
                    >
                        Sign up
                    </Anchor>
                </Flex>
                <Flex direction='column' justify='center' align='center' my='sm'>
                    <Flex gap='xs' align='center' w='100%' justify='center'>
                        <Text size='sm' ta='center'>
                            By continuing, you agree to our{' '}
                            <Anchor underline='always' c='black' fw={700}
                                    href="https://tigerit.app/terms-and-conditions"
                                    target="_blank">
                                {' '}
                                Terms of Service
                            </Anchor>{' '}
                            and
                        </Text>
                    </Flex>
                    <Flex gap='xs' align='center' w='100%' justify='center'>
                        <Text size='sm'>
                            acknowledge our{' '}
                            <Anchor underline='always' c='black' fw={700} href="https://tigerit.app/privacy-policy"
                                    target="_blank">
                                Privacy Policy
                            </Anchor>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </CenterLayout>
    );
};

export default Login;