import {Avatar, Card, em, Flex, Indicator, LoadingOverlay, Text, Title} from "@mantine/core";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {useEffect} from "react";
import {
    getAllNotificationsAction,
    updateNotificationAction
} from "../../../store/notifications/notificationsActions.ts";
import {dateConverter} from "../../../utils/dateConverter.ts";
import {useMediaQuery} from "@mantine/hooks";

const Notifications = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const dispatch = useAppDispatch();
    const {allNotifications, loaders} = useAppSelector(state => state.notifications)

    useEffect(() => {
        dispatch(getAllNotificationsAction())
    }, []);

    return (
        <Flex justify="center">
            <Flex w={isMobile ? "100%" : "50%"} direction="column" p={isMobile ? "" : "md"}>
                <LoadingOverlay visible={loaders.gettingNotifications}/>
                <Title order={3} m="sm">Notifications</Title>
                <Card radius="lg">
                    {allNotifications?.notifications?.length <= 0 ?
                        <Flex direction="column" justify="center" align="center" h="75vh">
                            <Avatar
                                src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724137455/Icons/xzyzot9qv3xgc3gzgosl.png"
                                size={isMobile ? 150 : 250} radius="xs"/>
                            <Title order={isMobile ? 5 : 3} fw={200}>Browse more for <span style={{color: "#62BE12"}}>CRAZY Notifications!</span></Title>
                        </Flex> : allNotifications?.notifications?.map((notification, index) => {
                            return (
                                <Flex style={{cursor: "pointer"}} my="lg" key={index} w="100%" direction="row"
                                      justify="space-between" onClick={() => {
                                    dispatch(updateNotificationAction(notification?._id))
                                }}>
                                    <Flex>
                                        <Indicator color="red" size={6} disabled={notification?.seen}
                                                   style={{zIndex: "1"}}>
                                            <Avatar size="md" src={notification?.icon} radius="xs"/>
                                        </Indicator>
                                        <Flex direction="column" justify="center" mx="lg">
                                            <Text fw={notification?.seen ? 200 : 600}>{notification?.title}</Text>
                                            <Text fw={notification?.seen ? 200 : 600} lineClamp={2}
                                                  size={isMobile ? "xs" : ""}
                                                  opacity={0.7}>{notification?.subtitle}</Text>
                                        </Flex>
                                    </Flex>
                                    <Text size={isMobile ? "xs" : ""} style={{alignSelf: "center"}}
                                          opacity={0.7}>{dateConverter(notification?.createdAt)}</Text>
                                </Flex>
                            )
                        })}
                </Card>
            </Flex>
        </Flex>
    );
};

export default Notifications;