import {ActionIcon, Badge, Box, Card, Divider, Flex, Group, Image, Menu, rem, Text} from "@mantine/core";
import {IconBox, IconDotsVertical, IconEdit, IconPlus, IconTrash} from "@tabler/icons-react";
import {Business} from "../interfaces/business/IAllBusinessResponse.ts";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../store/store.ts";
import {deleteBusinessAction} from "../store/business/businessesActions.ts";
import {notificationSystem} from "../utils/notificationSystem.ts";
import fallbackImage from "../assets/Placholder-01.png";

type BusinessCardProps = {
    business: Business;
}

const BusinessCard = ({business}: BusinessCardProps) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    return (
        <Card radius="md">
            <Card.Section withBorder inheritPadding py="xs">
                <Group justify="space-between">
                    <Group>
                        <Box h="56px" w="96px">
                            <Image h="56px" w="96px"
                                   src={business?.image}
                                   radius="md"
                                   fallbackSrc={fallbackImage}
                            />
                        </Box>
                        <Flex direction="column">
                            <Text fw={600}>{business?.title}</Text>
                            <Text c="#767C8C" fw={200}>{business?.category}</Text>
                        </Flex>
                    </Group>
                    <Menu withinPortal position="bottom-end" shadow="sm">
                        <Menu.Target>
                            <ActionIcon variant="subtle" color="gray">
                                <IconDotsVertical style={{width: rem(16), height: rem(16)}}/>
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                onClick={() => navigate(`/seller/update-business/${business?._id}`)}
                                leftSection={<IconEdit style={{width: rem(14), height: rem(14)}}/>}>
                                Edit Business
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => navigate(`/seller/products/${business?._id}`)}
                                leftSection={<IconBox style={{width: rem(14), height: rem(14)}}/>}>
                                All Products
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => {
                                    if (business?.verificationStatus === "pending") {
                                        notificationSystem("Business Verification", "Your business verification is pending", true)
                                    } else {
                                        navigate(`/seller/add-product/${business?._id}`)
                                    }
                                }}
                                leftSection={<IconPlus style={{width: rem(14), height: rem(14)}}/>}>
                                Add Product
                            </Menu.Item>
                            <Menu.Item color="red"
                                       onClick={() => dispatch(deleteBusinessAction(business?._id))}
                                       leftSection={<IconTrash style={{width: rem(14), height: rem(14)}}/>}>
                                Delete Business
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Card.Section>
            <Group my="sm" justify="space-between">
                <Flex direction="column">
                    <Text c="#767C8C">Products</Text>
                    <Text>{business?.productsCount}</Text>
                </Flex>
                <Flex direction="column">
                    <Text c="#767C8C">Sales</Text>
                    <Text>{business?.salesCount}</Text>
                </Flex>
                <Flex direction="column" align="center">
                    <Text c="#767C8C">Status</Text>
                    <Badge variant="light" fw={200}
                           color={business?.verificationStatus === "pending" ? "#FFAB40" : "#62BE12"}>{business?.verificationStatus}</Badge>
                </Flex>
            </Group>
            <Flex direction="column">
                <Text c="#767C8C">Store Address</Text>
                <Text>{business?.address?.formattedAddress}</Text>
            </Flex>
            <Divider my="sm"/>
        </Card>
    );
};

export default BusinessCard;