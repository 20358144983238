import {em, Flex, LoadingOverlay, Pagination, SimpleGrid, Text} from "@mantine/core";
import OrdersTable from "./OrdersTable.tsx";
import {useMediaQuery, useWindowScroll} from "@mantine/hooks";
import {Fragment, useEffect} from "react";
import OrderCard from "../../../components/OrderCard.tsx";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {getAllBusinessOrdersAction} from "../../../store/oders/ordersActions.ts";
import {IconClipboardList} from "@tabler/icons-react";

const Orders = () => {

    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const {orders, loaders} = useAppSelector(state => state.orders)
    const dispatch = useAppDispatch()
    const [scroll, scrollTo] = useWindowScroll()

    const handlePage = (page: number) => {
        dispatch(getAllBusinessOrdersAction(page))
        scrollTo({y: 0})
    }
    // const [currentOrders, setCurrentOrders] = useState<Order[]>([])
    // const [pastOrders, setPastOrders] = useState<Order[]>([])

    useEffect(() => {
        dispatch(getAllBusinessOrdersAction(1))
        console.log(scroll)
    }, []);

    // useEffect(() => {
    //     const pastOrders = allBusinessOrders?.orders?.filter(order =>
    //         order?.orderStatus === "delivered" || order?.orderStatus === "cancelled"
    //     );
    //     const confirmedOrders = allBusinessOrders?.orders?.filter(order =>
    //         order?.orderStatus === "confirmed" || order?.orderStatus === "assigned" || order?.orderStatus === "processed");
    //     setPastOrders(pastOrders)
    //     setCurrentOrders(confirmedOrders)
    //
    // }, [allBusinessOrders]);

    return (
        <Flex direction="column" m="md">
            <LoadingOverlay visible={loaders.gettingOrders}/>
            {orders?.orders?.length <= 0 ?
                <Flex direction="column" justify="center" align="center" h="75vh">
                    <IconClipboardList color="#62BE12" stroke={0.7} size={isMobile ? 150 : 250}/>
                    <Text>No Orders found</Text>
                </Flex>
                :
                <>
                    <Flex w="100%" justify="space-between" align="center">
                        <Text fw={700} size="24px" mb="sm">My Orders</Text>
                    </Flex>
                    {isMobile ? <SimpleGrid
                            cols={{base: 1, sm: 2, lg: 2}}
                            spacing={{base: 10, sm: 'xl'}}
                            verticalSpacing={{base: 'md', sm: 'xl'}}
                            mt="sm"
                        >
                            {orders?.orders?.map((order, i) => {
                                return (
                                    <Fragment key={i}>
                                        <OrderCard order={order} isBuyer={false} loading={loaders.gettingOrders}/>
                                    </Fragment>
                                )
                            })}
                        </SimpleGrid> :
                        <OrdersTable orders={orders?.orders} isBuyer={false} loading={loaders.gettingOrders}/>}
                </>
            }
            {orders?.orders?.length > 0 &&
                <Pagination color="#62BE12" m="sm" total={orders?.totalPages} onChange={handlePage}/>}
            {/*<Tabs color="lime" defaultValue="currentOrders">*/}
            {/*    <Tabs.List>*/}
            {/*        <Tabs.Tab value="currentOrders">*/}
            {/*            Current Orders*/}
            {/*        </Tabs.Tab>*/}
            {/*        <Tabs.Tab value="pastOrders">*/}
            {/*            Past Orders*/}
            {/*        </Tabs.Tab>*/}
            {/*    </Tabs.List>*/}
            {/*    <Tabs.Panel value="currentOrders">*/}

            {/*        */}
            {/*    </Tabs.Panel>*/}
            {/*    <Tabs.Panel value="pastOrders">*/}
            {/*        <LoadingOverlay visible={loader.gettingAllBusinessOrders}/>*/}
            {/*        {isMobile ? <SimpleGrid*/}
            {/*            cols={{base: 1, sm: 2, lg: 2}}*/}
            {/*            spacing={{base: 10, sm: 'xl'}}*/}
            {/*            verticalSpacing={{base: 'md', sm: 'xl'}}*/}
            {/*            mt="sm"*/}
            {/*        >*/}
            {/*            {pastOrders?.map((order, i) => {*/}
            {/*                return (*/}
            {/*                    <Fragment key={i}>*/}
            {/*                        <OrderCard order={order} isBuyer={false}/>*/}
            {/*                    </Fragment>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*        </SimpleGrid> : <OrdersTable orders={pastOrders} isBuyer={false}/>}*/}
            {/*    </Tabs.Panel>*/}
            {/*</Tabs>*/}
        </Flex>
    );
};

export default Orders;