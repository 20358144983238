import {IAddress} from "../interfaces/account/IAccount.ts";

const JWT_TOKEN: string = "_JWT_TOKEN_"
const FCM_TOKEN: string = "_FCM_TOKEN_"
const AUTH_USER: string = "_AUTH_USER_"
const _USER_ID_: string = "_USER_ID_"
const _SHORT_Token_: string = "_SHORT_Token_"

export type AuthUser = {
    firstName: string
    lastName: string
    profilePic: string
    email: string
    role: string
    loyaltyPoints: number
    ageVerification: string
    phoneNumber: string
    dob: string
    gender: string
    currency: string
}

export interface IBuyNowItems {
    product: string
    quantity: number
}


export const setShortToken = (token: string) => {
    localStorage.setItem(_SHORT_Token_, token)
}

export const getShortToken = () => {
    return localStorage.getItem(_SHORT_Token_)
}

export const setFcmToken = (token: string) => {
    localStorage.setItem(FCM_TOKEN, token)
}

export const getFcmToken = () => {
    return localStorage.getItem(FCM_TOKEN)
}
export const setToken = (token: string) => {
    localStorage.setItem(JWT_TOKEN, token)
}
export const getToken = () => {
    return localStorage.getItem(JWT_TOKEN)
}

export const clearToken = () => {
    localStorage.clear()
}

export const setAuthUser = (user: AuthUser) => {
    localStorage.setItem(AUTH_USER, JSON.stringify(user))
}

export const getUser = (): AuthUser => {
    const userJson = localStorage.getItem(AUTH_USER);
    if (userJson) {
        return JSON.parse(userJson);
    } else {
        // Return an empty AuthUser object
        return {
            firstName: "",
            lastName: "",
            profilePic: "",
            email: "",
            role: "",
            loyaltyPoints: 0,
            ageVerification: "",
            phoneNumber: "",
            dob: "",
            gender: "",
            currency: ""
        };
    }
}

export const setSellerStatus = (status: string) => {
    localStorage.setItem("status", status)
}
export const getSellerStatus = () => {
    return localStorage.getItem("status")
}
export const setUserId = (id: string) => {
    localStorage.setItem(_USER_ID_, id)
}
export const getUserId = (): string => {
    const user = localStorage.getItem(_USER_ID_)
    if (user) {
        return user
    }
    return ""
}

export const setAddress = (address: IAddress) => {
    localStorage.setItem("address", JSON.stringify(address))
}

export const getAddress = (): IAddress => {
    const address = localStorage.getItem("address")
    if (address) {
        return JSON.parse(address)
    } else {
        return {
            city: "",
            coordinates: [0, 0],
            country: "",
            formattedAddress: "",
            isoCode: "",
            latitude: 0,
            longitude: 0,
            phoneNumber: "",
            state: "",
            type: "Point"
        }
    }
}

export const setCartItemCount = (count: number) => {
    localStorage.setItem("cart-item-count", JSON.stringify(count))
}

export const getCartItemCount = () => {
    const count = localStorage.getItem("cart-item-count")
    if (!count) return;
    return JSON.parse(count)
}

export const setMapCentre = ({lat, lng}: {
    lat: number,
    lng: number
}) => {
    localStorage.setItem("centre", JSON.stringify({lat, lng}))
}
export const getCentre = () => {
    const centre = localStorage.getItem("centre")
    if (!centre) return
    return JSON.parse(centre);
}

export const setBuyNowItems = (items: IBuyNowItems[]) => {
    localStorage.setItem("buyNowItems", JSON.stringify(items))
}

export const getBuyNowItems = () => {
    const items: string | null = localStorage.getItem("buyNowItems")
    if (!items || items.length === 0) return
    return JSON.parse(items)
}