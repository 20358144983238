import {Card, em, Flex, Image, Rating, Text} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {IconClockFilled} from "@tabler/icons-react";
import {NearbyRestaurant} from "../interfaces/buyer/product/IProductResponse.ts";
import {metersToKilometers} from "../utils/distanceConverter.ts";
import {useNavigate} from "react-router-dom";
import {getToken} from "../utils/storage.ts";
import fallbackImage from "../assets/Placholder-01.png";

type CardProps = {
    restaurant: NearbyRestaurant
    user?: string
}
const RestaurantsCardsNew = ({restaurant, user}: CardProps) => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const navigate = useNavigate()

    return (
        <Flex pl="sm" style={{cursor: "pointer"}}>
            <Card
                withBorder
                radius="md"
                shadow="sm"
                w={isMobile ? "300px" : "400px"} h="230px"
                onClick={() => {
                    if (!getToken()) {
                        navigate("sign-in")
                    } else {
                        navigate(`/sellers-food-shop-buyer/${restaurant?._id}`, {
                            state: {
                                user: user
                            }
                        })
                    }
                }}
            >
                <Card.Section>
                    <Image
                        src={restaurant?.image}
                        h={160}
                        alt="No way!"
                        fallbackSrc={fallbackImage}
                    />
                </Card.Section>

                <Text fw={600} size="md" mt="xs">
                    {restaurant?.title}
                </Text>
                <Flex gap="xs">
                    <Flex align="center" p={0} gap="xs">
                        <Rating count={1} readOnly value={restaurant?.rating?.numRating}/>
                        <Text c="#767C8C" fw={isMobile ? 200 : 600} m={0}>{restaurant?.rating?.numRating}</Text>
                        <Text c="#A8AEBF" size={isMobile ? "sm" : ""} fw={isMobile ? 200 : 500}
                              truncate="end">({restaurant?.rating?.totalRating}+
                            ratings)</Text>
                    </Flex>
                    <Flex gap="xs" align="center">
                        <IconClockFilled size={isMobile ? "20px" : "25px"} color="#767C8C"/>
                        <Text size={isMobile ? "sm" : ""} lineClamp={1}
                        >{!restaurant?.distance ? "N/A" : metersToKilometers(restaurant?.distance) * 3} minutes
                            away</Text>
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    );
};

export default RestaurantsCardsNew;