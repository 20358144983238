export const timeConverter = (dateStr: string) => {
    const date = new Date(dateStr);

// Convert to local time zone
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

// Format to AM/PM format
    return localDate.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });

}

export function formatMessageTime(createdAt: string) {
    const now = new Date()
    const messageDate = new Date(createdAt)

    const diffTime = Math.abs(now.getTime() - messageDate.getTime())
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays === 0) {
        // If the message is from today, show only the time
        return messageDate.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        })
    } else if (diffDays === 1) {
        // If the message is from yesterday, show "1 day ago"
        return '1 day ago'
    } else if (diffDays < 7) {
        // If the message is from within the last 7 days, show "x days ago"
        return `${diffDays} days ago`
    } else if (diffDays < 14) {
        // If the message is from within the last 2 weeks, show "1 week ago"
        return '1 week ago'
    } else if (diffDays < 21) {
        // If the message is from within the last 3 weeks, show "2 weeks ago"
        return '2 weeks ago'
    } else if (diffDays < 28) {
        // If the message is from within the last 4 weeks, show "3 weeks ago"
        return '3 weeks ago'
    } else if (diffDays < 365) {
        // If the message is from within the last year, show "x months ago"
        const diffMonths = Math.floor(diffDays / 30)
        return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`
    } else {
        // If the message is from over a year ago, show "x years ago"
        const diffYears = Math.floor(diffDays / 365)
        return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`
    }
}