import CenterLayout from "../../components/centerlayout/CenterLayout.tsx";
import {Button, em, TextInput} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import {useMediaQuery} from "@mantine/hooks";

interface INameInfo {
    firstName: string
    lastName: string
}

const FullName = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery(`(max-width: ${em(576)})`);
    const form = useForm<INameInfo>({
        initialValues: {firstName: '', lastName: ''},

        // functions will be used to validate values at corresponding key
        validate: {
            firstName: (value) => (value.length === 0 ? 'First Name should not be empty' : null),
            lastName: (value) => (value.length === 0 ? 'Last Name should not be empty' : null)
        },
    });
    return (
        <CenterLayout title="Enter First and Last name" description="provode your frist name and last name">
            <form onSubmit={form.onSubmit((values) => {
                navigate("/create-password", {state: {firstName: values.firstName, lastName: values.lastName}})
            })}>
                <TextInput size={isMobile ? "md" : "lg"} radius={isMobile ? "md" : "lg"} withAsterisk label="First Name"
                           w="100%"
                           placeholder="First Name" {...form.getInputProps('firstName')}/>
                <TextInput size={isMobile ? "md" : "lg"} radius={isMobile ? "md" : "lg"} withAsterisk my="lg"
                           label="Last Name" w="100%"
                           placeholder="Last Name" {...form.getInputProps('lastName')}/>
                <Button fw={200} radius="xl" size="lg" color="#62BE12" type="submit" w="100%" mt="lg">Continue</Button>
            </form>
        </CenterLayout>
    );
};

export default FullName;