import {Box, Card, em, Flex, Image, rem, Text} from "@mantine/core";
import {ReactNode, useRef} from "react";
import Footer from "../../../components/Footer.tsx";
import {useMediaQuery} from "@mantine/hooks";
import {HomeSlide, TopCagtory} from "../../../interfaces/buyer/product/IProductResponse.ts";
import {Carousel} from "@mantine/carousel";
import Autoplay from 'embla-carousel-autoplay';
import {useNavigate} from "react-router-dom";
import classes from "./carousel.module.css"
import CustomMultiSelect from "../../../components/CustomMultiSelect.tsx";
import {useAppSelector} from "../../../store/store.ts";
import {IconArrowLeft, IconArrowRight} from "@tabler/icons-react";
import CategorySkeleton from "../../../components/skeletons/CategorySkeleton.tsx";
import SliderSkeleton from "../../../components/skeletons/SliderSkeleton.tsx";

type HomeProps = {
    children: ReactNode,
    slider?: HomeSlide[]
    topCagtories?: TopCagtory[]
    loading: boolean
}
const Home = ({children, slider, topCagtories, loading}: HomeProps) => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const autoplay = useRef(Autoplay({delay: 5000}))
    const navigate = useNavigate()
    // const [categories, setCategories] = useState<string[]>([])
    const {allRecentSearches} = useAppSelector(state => state.product)

    // useEffect(() => {
    //     const categoriesArr = topCagtories?.map((c) => {
    //         return c?.name
    //     })
    //     if (!categoriesArr) return
    //     setCategories(categoriesArr)
    // }, []);

    return (
        <>
            <Flex direction="column" justify="space-between">
                {/*Header Starts from here*/}
                <Flex w="100%" justify="center" visibleFrom="lg" mt="md">
                    <Carousel
                        nextControlIcon={<IconArrowRight
                            style={{
                                width: rem(25), height: rem(25),
                                background: "#F0F1F5",
                                // boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                borderRadius: "100px"
                            }}/>}
                        previousControlIcon={<IconArrowLeft style={{
                            width: rem(25), height: rem(25),
                            background: "#F0F1F5",
                            // boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                            borderRadius: "100px"
                        }}/>}
                        slideSize={70}
                        loop
                        align="start"
                        slideGap="sm"

                        px={isMobile ? 55 : 80}
                        mb="xl"
                    >
                        {loading ? [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                                <Carousel.Slide key={index}>
                                    <CategorySkeleton/>
                                </Carousel.Slide>
                            )
                        ) : topCagtories?.map((category, index) => {
                            return <Carousel.Slide key={index}>
                                <Flex h={isMobile ? "40%" : "90px"} w={isMobile ? 70 : 120} my="sm" align="center"
                                      justify="space-between"
                                      pos="relative"
                                      direction="column"
                                      style={{cursor: "pointer"}}
                                      onClick={() => {
                                          const params = encodeURIComponent(category.name)
                                          navigate(`/category?category=${params}`)
                                      }}>
                                    <Box style={{zIndex: 2}} p="sm" w={isMobile ? 60 : 80}>
                                        <Image
                                            loading={"eager"}
                                            right={15}
                                            h={isMobile ? 50 : 55}
                                            src={category.image}
                                        />
                                    </Box>
                                    <Card radius="md" component="a" style={{zIndex: 1}} opacity={0.3}
                                          top={isMobile ? -10 : -5}
                                          bg="#62BE12"
                                          pos="absolute"
                                          h={isMobile ? 50 : 60}
                                          w={isMobile ? 50 : 70}>
                                    </Card>

                                    <Text ta="center" size="sm"
                                          fw={isMobile ? 500 : 600}>{category.name}</Text>
                                </Flex>
                            </Carousel.Slide>
                        })}
                    </Carousel>
                </Flex>
                <Flex justify="center" hiddenFrom="md" my="sm" px="sm">
                    <CustomMultiSelect selectData={allRecentSearches?.searches}/>
                </Flex>
                <Flex w="100%" justify="center">
                    <Carousel
                        plugins={[autoplay.current]}
                        onMouseEnter={autoplay.current.stop}
                        onMouseLeave={autoplay.current.reset}
                        withControls={false}
                        slideSize={isMobile ? "100%" : 505}
                        loop
                        align="start"
                        withIndicators
                        slideGap="sm"
                        maw={isMobile ? "100%" : "75%"}
                        classNames={{indicator: classes.indicator}}
                    >
                        {loading ? [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                            <Carousel.Slide pr="xs" key={index}>
                                <SliderSkeleton/>
                            </Carousel.Slide>
                        )) : slider?.map((img, index) => {
                            return <Carousel.Slide pr="xs" key={index}>
                                <Box
                                    h={isMobile ? 200 : 301}
                                    w={isMobile ? "100%" : 505}
                                    p="sm"
                                    pr="lg"
                                    onClick={() => navigate("/business-details")}
                                >
                                    <Image
                                        loading={"eager"}
                                        radius="lg"
                                        h={isMobile ? 150 : "80%"}
                                        src={img.webImage}
                                    />
                                </Box>
                            </Carousel.Slide>
                        })}
                    </Carousel>
                </Flex>
                <Flex justify={"center"} align="center" p={0}>
                    {children}
                </Flex>
                <Footer/>
            </Flex>
        </>
    );
};

export default Home;