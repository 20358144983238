import {Avatar, Card, em, Flex, Loader, Text, Title} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {getAllCoupons} from "../../../store/product/productActions.ts";
import CouponCard from "../../../components/CouponCard.tsx";

const Coupons = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const dispatch = useAppDispatch()
    const {allCoupons, loaders} = useAppSelector(state => state.product)

    useEffect(() => {
        dispatch(getAllCoupons())
    }, []);
    return (
        <Flex w="100%" direction="column">
            <Card w="100%" pos="fixed" style={{zIndex: "100"}}>
                <Flex w="100%" justify="center">
                    <Flex justify="space-between" align="center" w={isMobile ? "100%" : "70%"}>
                        <Title order={isMobile ? 4 : 3}>Coupons</Title>
                        {/*<Button radius="xl" color="#62BE12">Create Coupon</Button>*/}
                    </Flex>
                </Flex>
            </Card>
            {loaders?.gettingAllCoupons ?
                <Flex w="100%" h="80vh" justify="center" align="center">
                    <Loader/>
                </Flex> :
                <Flex justify="center" align="center" direction="column" mx="sm" mt="60px">
                    {allCoupons?.inactiveCoupons?.length <= 0 ?
                        <Flex w="100%" h="80vh" justify="center" align="center" direction="column">
                            <Avatar radius="xs" size={isMobile ? 150 : 250}
                                    src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724137455/Icons/fkqdb0cy0waxlqyojlsq.png"/>
                            <Text>Shop more for <span style={{color: "#62BE12"}}>CRAZY Coupons!</span></Text>
                        </Flex> :
                        allCoupons?.inactiveCoupons?.map((coupon, index) => <CouponCard key={index}
                                                                                        isMobile={isMobile}
                                                                                        coupon={coupon}/>)}
                </Flex>
            }
        </Flex>
    );
};

export default Coupons;