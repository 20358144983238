import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import ProductInfoCard from "./ProductInfoCard.tsx";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {Anchor, em, Flex, Loader, rem, Stack, Text} from "@mantine/core";
import {getAllProductsActionByCategory} from "../../../store/product/productActions.ts";
import {useMediaQuery, useWindowScroll} from "@mantine/hooks";
import {IconArrowLeft, IconArrowRight} from "@tabler/icons-react";
import classes from "../home/carousel.module.css";
import {Carousel} from "@mantine/carousel";
import {getUser} from "../../../utils/storage.ts";

const ProductsFilterByCategory = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const dispatch = useAppDispatch()
    const {productsWithCategory, loaders} = useAppSelector(state => state.product)
    const navigate = useNavigate()
    const authUser = getUser()
    const [scroll, scrollTo] = useWindowScroll()

    useEffect(() => {
        if (!category) return
        const decodedCategory = decodeURIComponent(category)
        dispatch(getAllProductsActionByCategory({category: decodedCategory}))
    }, [category]);

    useEffect(() => {
        scrollTo({y: 0})
        console.log(scroll)
    }, []);

    return (
        <>
            {loaders.gettingAllProductsWithCategory ?
                <Flex h="100vh" w="100%" align="center" justify="center">
                    <Loader/>
                </Flex> :
                productsWithCategory?.subCategories?.length === 0 ?
                    <Flex w="100%" justify="center" align="center" my="xl" py="xl">
                        <Text>No product found in this category</Text>
                    </Flex> :
                    <Flex justify="center" align="center">
                        <Flex direction={productsWithCategory?.subCategories ? "column" : "row"}
                              maw={isMobile ? "100%" : 1240}>
                            {productsWithCategory?.subCategories ? productsWithCategory?.subCategories?.map((category, index) => {
                                    return (
                                        <>
                                            <Flex key={index} my="lg" justify="space-between" m="xs">
                                                <Text fw={700}>{category?.title}</Text>
                                                <Anchor style={{color: "#62BE12"}} underline="never" onClick={() => {
                                                    const param = encodeURIComponent(category?.title)
                                                    navigate(`/view-all-buyer?category=${param}`)
                                                }}>View all</Anchor>
                                            </Flex>
                                            <Carousel
                                                nextControlIcon={<IconArrowRight
                                                    style={{
                                                        width: rem(30),
                                                        height: rem(30),
                                                        boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                                        borderRadius: "100px"
                                                    }}/>}
                                                previousControlIcon={<IconArrowLeft style={{
                                                    width: rem(30), height: rem(30),
                                                    boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                                    borderRadius: "100px"
                                                }}/>}
                                                slideSize={isMobile ? "160px" : "160px"}
                                                slideGap={0}
                                                align="start"
                                                dragFree
                                                classNames={classes}
                                            >
                                                {category?.products?.map((product, index) => {
                                                    return <Carousel.Slide key={index}>
                                                        <ProductInfoCard
                                                            product={product} ageVerified={authUser?.ageVerification}/>
                                                    </Carousel.Slide>
                                                })}
                                            </Carousel>
                                        </>
                                    )
                                }) :
                                productsWithCategory?.products?.map((product) => {
                                    return (
                                        <Stack>
                                            <ProductInfoCard product={product}/>
                                        </Stack>
                                    )
                                })
                            }
                        </Flex>
                    </Flex>
            }

        </>
    );
};

export default ProductsFilterByCategory;