import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import {useMediaQuery} from "@mantine/hooks";
import {em, Flex, Loader, rem, Text} from "@mantine/core";
import {useEffect} from "react";
import {getAllProductsActionByCategory} from "../../store/product/productActions.ts";
import {Carousel} from "@mantine/carousel";
import {IconArrowLeft, IconArrowRight} from "@tabler/icons-react";
import classes from "../buyer/home/carousel.module.css";
import GuestProductInfoCard from "./GuestProductInfoCard.tsx";

const GuestProductsFilterByCategory = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const dispatch = useAppDispatch()
    const {productsWithCategory, loaders} = useAppSelector(state => state.product)
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);

    useEffect(() => {
        if (!category) return
        const decodedCategory = decodeURIComponent(category)
        dispatch(getAllProductsActionByCategory({category: decodedCategory}))

    }, [category]);
    return (
        <>
            {loaders.gettingAllProductsWithCategory ?
                <Flex h="100vh" w="100%" align="center" justify="center">
                    <Loader/>
                </Flex> :
                productsWithCategory?.subCategories?.length === 0 ?
                    <Flex w="100%" justify="center" align="center" my="xl" py="xl">
                        <Text>No product found in this category</Text>
                    </Flex> :
                    <Flex justify="center" align="center">
                        <Flex direction="column" maw={isMobile ? "100%" : 1240}>
                            {productsWithCategory?.subCategories?.map((category) => {
                                return (
                                    <>
                                        <Flex my="lg" justify="space-between" m="xs">
                                            <Text fw={700}>{category?.title}</Text>
                                        </Flex>
                                        <Carousel
                                            nextControlIcon={<IconArrowRight
                                                style={{
                                                    width: rem(30),
                                                    height: rem(30),
                                                    boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                                    borderRadius: "100px"
                                                }}/>}
                                            previousControlIcon={<IconArrowLeft style={{
                                                width: rem(30), height: rem(30),
                                                boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                                borderRadius: "100px"
                                            }}/>}
                                            slideSize={isMobile ? "160px" : "160px"}
                                            slideGap={0}
                                            align="start"
                                            dragFree
                                            classNames={classes}
                                        >
                                            {category?.products?.map((product, index) => {
                                                return <Carousel.Slide key={index}>
                                                    <GuestProductInfoCard
                                                        product={product}/>
                                                </Carousel.Slide>
                                            })}
                                        </Carousel>
                                    </>
                                )
                            })}
                        </Flex>
                    </Flex>
            }

        </>
    );
};

export default GuestProductsFilterByCategory;