import {BackgroundImage, Box, Divider, em, Flex, Text, Title} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";

const PrivacyPolicy = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);

    return (
        <Flex p="sm" direction="column" w="100%" justify="center" align="center">
            <Flex direction="column" w={isMobile ? "100%" : "70%"}>
                <Box h={isMobile ? 225 : 550}>
                    <BackgroundImage radius="md" h="100%"
                                     src="https://res.cloudinary.com/dhl8hhlsx/image/upload/v1724753730/Banners/k7jyktunb4b4pylpwuq9.jpg">
                        <Flex h="100%" justify="center" align={isMobile ? "end" : "center"}>
                            <Text size={isMobile ? "25px" : "60px"} pb="sm" c="white">We care about your privacy</Text>
                        </Flex>
                    </BackgroundImage>
                </Box>
                <Divider mt="xl" label={<Title opacity={0.7} order={3} c="black">Introduction</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>Welcome to Tiger it. We are committed to protecting your privacy and ensuring that
                    your personal information is handled in a safe and responsible manner. This Privacy Policy outlines
                    how we collect, use, and protect your information when you use our platform.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Information We Collect</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>We may collect personal information such as your name, email address, phone number,
                    payment details, current location, and delivery location for product delivery when you register an
                    account, make a purchase, or contact us for support. We collect information about your interactions
                    with our platform, including pages visited, features used, and time spent on the platform. We may
                    collect information about the device you use to access our platform, including hardware model,
                    operating system, and browser type.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">How We Use Your Information</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>We use your personal information to deliver the services you request, including
                    processing transactions, managing deliveries, and providing customer support. We analyze usage data
                    to improve the functionality and user experience of our platform. We may use your contact
                    information to send updates, newsletters, and promotional materials. You can opt out of these
                    communications at any time. We use your information to protect the security of our platform and
                    detect and prevent fraud or other malicious activities.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Sharing Your Information</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>We may share your information with third-party service providers who perform
                    services on our behalf, such as payment processing, delivery services, and data analysis. We may
                    disclose your information if required by law or in response to a legal request, such as a subpoena
                    or court order. We may share your information with third parties if you provide your explicit
                    consent.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Data Security</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>We implement appropriate technical and organizational measures to protect your
                    personal information from unauthorized access, loss, or misuse. However, no method of transmission
                    over the internet or electronic storage is completely secure, and we cannot guarantee absolute
                    security.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Your Rights</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>You have the right to access and update your personal information. You can do this
                    through your account settings or by contacting us directly. You have the right to request the
                    deletion of your personal information, subject to certain legal exceptions. You have the right to
                    object to the processing of your personal information in certain circumstances. You have the right
                    to request that we restrict the processing of your personal information in certain circumstances.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Third-Party Links</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>Our platform may contain links to third-party websites or services. We are not
                    responsible for the privacy practices or content of these third parties. We encourage you to review
                    the privacy policies of any third-party sites you visit.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Changes to This Privacy Policy</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>We may update this Privacy Policy from time to time. We will notify you of any
                    significant changes by posting the new policy on our platform and updating the effective date. Your
                    continued use of the platform after such changes constitutes your acceptance of the updated policy.
                </Text>
                <Divider mt="lg" label={<Title opacity={0.7} order={3} c="black">Contact Us</Title>}
                         labelPosition="left"/>
                <Text opacity={0.7}>If you have any questions or concerns about this Privacy Policy or our data
                    practices, please contact us at support@tigerit.com.
                </Text>
            </Flex>
        </Flex>
    );
};

export default PrivacyPolicy;