import {ActionIcon, Button, Card, em, Flex, Modal, Text} from "@mantine/core";
import {IconCreditCard, IconDots} from "@tabler/icons-react";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";
import type * as Square from "@square/web-sdk";
//@ts-ignore
import {CreditCard, PaymentForm} from "react-square-web-payments-sdk";

const PaymentOptions = () => {
    const [opened, {open, close}] = useDisclosure(false)
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    return (
        <>
            <Flex direction="column">
                <Flex my="lg" w="100%" direction="row"
                      justify="space-between" align="center">
                    <Text fw={700} size={isMobile ? "20px" : "22px"} my="lg">Payment Option</Text>
                    <Button color="#62BE12" size={isMobile ? "sm" : "md"} radius="xl" onClick={open}>Add</Button>
                </Flex>
                <Card my="lg" w="100%" radius="md" withBorder>
                    <Flex align="center" justify="space-between">
                        <Flex>
                            <IconCreditCard size="33px" color="#62BE12"/>
                            <Flex direction="column" mx="lg" gap="xs">
                                <Text fw={500} size="16px" color="#474C59">Lonnie ceejay</Text>
                                <Text fw={600} size={isMobile ? "14px" : "16px"}>3301 0241 1319 9871</Text>
                            </Flex>
                        </Flex>
                        <ActionIcon variant="subtle" color="black">
                            <IconDots/>
                        </ActionIcon>
                    </Flex>
                </Card>
                <Card w="100%" radius="md" withBorder>
                    <Flex align="center" justify="space-between">
                        <Flex>
                            <IconCreditCard size="33px" color="#62BE12"/>
                            <Flex direction="column" mx="lg" gap="xs">
                                <Text fw={500} size="16px" color="#474C59">Lonnie ceejay</Text>
                                <Text fw={600} size={isMobile ? "14px" : "16px"}>3301 0241 1319 9871</Text>
                            </Flex>
                        </Flex>
                        <ActionIcon variant="subtle" color="black">
                            <IconDots/>
                        </ActionIcon>
                    </Flex>
                </Card>
            </Flex>
            {/*add payment method modal*/}
            <Modal opened={opened} onClose={close} centered title="Add Payment Method">
                <PaymentForm
                    applicationId="sandbox-sq0idb-OkEiLAEVOCT8CwRO2POjIg"
                    cardTokenizeResponseReceived={(token: Square.TokenResult, verifiedBuyer: Square.VerifyBuyerResponseDetails | null) => {
                        console.log('token:', token);
                        console.log('verifiedBuyer:', verifiedBuyer);
                    }}
                    locationId='L4M3BD0TX1M8X'
                >
                    <CreditCard buttonProps={{
                        css: {
                            backgroundColor: "#62BE12",
                            fontSize: "14px",
                            color: "#fff",
                            "&:hover": {
                                backgroundColor: "#62BE12",
                            },
                        },
                    }}/>
                </PaymentForm>
            </Modal>
        </>
    );
};

export default PaymentOptions;