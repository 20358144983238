import {Box, Card, em, Flex, Image, Loader, rem, Stack, Stepper, Text} from "@mantine/core";
import {getSingleOrderAction} from "../../../../store/oders/ordersActions.ts";
import {useAppDispatch, useAppSelector} from "../../../../store/store.ts";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import OrderItems from "./OrderItems.tsx";
import {useMediaQuery} from "@mantine/hooks";
import fallbackImage from "../../../../assets/Placholder-01.png";

const OrderDetails = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const dispatch = useAppDispatch()
    const {singleOrder, loaders} = useAppSelector(state => state.orders)

    const {id} = useParams()

    useEffect(() => {
        if (!id) return
        dispatch(getSingleOrderAction(id))

    }, []);


    return (
        <>
            {loaders.gettingSingleOrder ?
                <Flex h="100vh" w="100%" justify="center" align="center">
                    <Loader/>
                </Flex> :
                <Flex direction="column" justify="center" align="center">
                    <Flex direction="column" w={isMobile ? "100%" : 1240} gap="sm" my="sm">
                        <Stepper w={isMobile ? "100%" : "55%"} size={isMobile ? "xs" : "md"} styles={{
                            stepIcon: {
                                borderWidth: rem(4),
                            },
                            step: {

                                display: "flex",
                                flexDirection: isMobile ? "column" : "row",
                                gap: "6px",
                                padding: 0
                            },
                            stepLabel: {
                                marginRight: isMobile ? "10px" : "0px"
                            },
                            separator: {
                                marginLeft: isMobile ? rem(-1) : "",
                                marginRight: isMobile ? rem(-1) : "",
                                marginBottom: isMobile ? rem(16) : "",
                                borderRadius: "12px",
                                height: isMobile ? rem(4) : rem(6),
                            },
                        }} active={singleOrder?.data?.statusDetails?.length + 1} color="#20C375">
                            <Stepper.Step label="Confirmed"/>
                            <Stepper.Step label="Packed"/>
                            <Stepper.Step label="Driver"/>
                            <Stepper.Step label="Delivered"/>
                        </Stepper>
                        <Flex gap="md" direction={isMobile ? "column-reverse" : "row"}>
                            <OrderItems order={singleOrder} loading={loaders.gettingSingleOrder}/>
                            <Card shadow="lg" padding="lg" radius="md" mt={isMobile ? "lg" : ""}
                                  ml={isMobile ? "" : "lg"}
                                  h="fit-content"
                                  w={isMobile ? "100%" : 500}>
                                <Card.Section withBorder>
                                    <Box h={200} w={400}>
                                        <Image
                                            src={singleOrder?.data?.products[0]?.item?.image}
                                            fallbackSrc={fallbackImage}
                                            // height={160}
                                            h={190}
                                            w={399}
                                            alt="Norway"
                                        />
                                    </Box>
                                </Card.Section>
                                <Stack my="sm">
                                    <Text fw={500} size={isMobile ? "" : ""}>Packing in Progress</Text>
                                    <Text size="sm">Our crew is carefully packing your order with love and
                                        precision.</Text>
                                </Stack>
                            </Card>
                        </Flex>

                    </Flex>
                </Flex>
            }
        </>
    );
};

export default OrderDetails;