import {Button, Flex, Group, Modal, NumberInput, Rating, rem, Text, Textarea} from "@mantine/core";
import React from "react";
import {IDriverTipSubmit} from "../../interfaces/buyer/product/IDriverTipSubmit.ts";
import {useAppDispatch} from "../../store/store.ts";
import {updateTipReviewAction} from "../../store/oders/ordersActions.ts";
import {IconCurrencyDollar} from "@tabler/icons-react";
import {getAllUnreviewedProductsAction} from "../../store/product/productActions.ts";

type DriverTipModalProps = {
    opened: boolean;
    close: () => void;
    setDriverTipForm: React.Dispatch<React.SetStateAction<IDriverTipSubmit>>
    driverTipForm: IDriverTipSubmit
}

const DriverTipModal = ({opened, close, setDriverTipForm, driverTipForm}: DriverTipModalProps) => {
    const dispatch = useAppDispatch();
    return (
        <Modal opened={opened} onClose={close} centered title="Rate & review driver">
            <Flex direction="column" gap="lg">
                <Group justify="space-between">
                    <Text>Rate Driver</Text>
                    <Rating defaultValue={5} onChange={(e) => {
                        setDriverTipForm({...driverTipForm, rating: e})
                    }} size="lg"/>
                </Group>
                <Textarea placeholder="Say something about driver" rows={4} autosize onChange={(e) => {
                    setDriverTipForm({...driverTipForm, review: e.target.value})
                }} label=""/>
                <NumberInput leftSection={<IconCurrencyDollar style={{width: rem(20), height: rem(20)}} stroke={1.5}/>}
                             hideControls onChange={(e) => {
                    setDriverTipForm({...driverTipForm, tipAmount: e})
                }} label="Tip "/>

            </Flex>
            <Flex justify="center" mt="sm">
                <Button fw={200} w="30%" radius="xl" color="#62BE12" onClick={() => {
                    dispatch(updateTipReviewAction(driverTipForm))
                    dispatch(getAllUnreviewedProductsAction())
                    close()
                }}>Submit</Button>
            </Flex>
        </Modal>
    );
};

export default DriverTipModal;