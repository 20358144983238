import {useEffect, useState} from "react";
import {io} from "socket.io-client";
import {getUserId} from "../utils/storage.ts";
import {IReceivedMessage} from "../interfaces/chat/IReceivedMessage.ts";
import {useDispatch} from "react-redux";
import {updateChat} from "../store/chat/chatSlice.ts";


const useSocketHook = () => {

    const [isConnected, setIsConnected] = useState(false);
    const dispatch = useDispatch();
    const userId = getUserId()

    useEffect(() => {

        const socket = io('https://tigeritserver-production-90fa.up.railway.app/socket')
        const onConnect = () => {
            setIsConnected(true);
            console.log("Socket connected");
            socket.emit("user-in", {userId: userId})
        };

        const onDisconnect = () => {
            setIsConnected(false);
            console.log("Socket disconnected");
        };

        socket.on("connect_error", (err) => {
            // the reason of the error, for example "xhr poll error"
            console.log("error:", err.message);
        });

        const onMessageSend = (data: IReceivedMessage) => {
            dispatch(updateChat(data.mostRecentMessage))
        };

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('/chat/message', onMessageSend);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('/chat/message', onMessageSend);
        };
    }, []);

    return {isConnected};
};

export default useSocketHook;
