import {CheckIcon, Combobox, em, Group, Pill, PillsInput, useCombobox} from "@mantine/core";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "@mantine/hooks";
import {IconSearch} from "@tabler/icons-react";

// const selectData = ['🍎 Apples', '🍌 Bananas', '🥦 Broccoli', '🥕 Carrots', '🍫 Chocolate'];

type InputData = {
    selectData: string[]
    toggleModal?: () => void
    selectType?: string
}
const CustomMultiSelect = ({selectData, toggleModal, selectType}: InputData) => {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });

    const [search, setSearch] = useState('');
    const [data, setData] = useState(selectData);
    const [value, setValue] = useState<string[]>([]);
    const navigate = useNavigate()
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const isLaptop = useMediaQuery(`(max-width: ${em(1200)})`);

    const exactOptionMatch = data?.some((item) => item === search);

    // const filteredOptions = useMemo(() => (
    //     data?.filter((item) => item?.toLowerCase().includes(search.trim().toLowerCase()))
    // ), [data, search]);

    const handleValueSelect = (val: string) => {
        setSearch('');

        if (selectType && selectType === "food") {
            if (val === '$create') {
                setData((current) => [...current, search]);
                setValue((current) => [...current, search]);
                navigate(`/search-food-buyer?search=${search}`)
                combobox.closeDropdown()
                if (toggleModal) {
                    toggleModal()
                }
            } else {
                setValue((current) =>
                    current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
                );
                navigate(`/search-food-buyer?search=${val}`)
                if (toggleModal) {
                    toggleModal()
                }
                combobox.closeDropdown()
            }
        } else {
            if (val === '$create') {
                setData((current) => [...current, search]);
                setValue((current) => [...current, search]);
                navigate(`/search-products-buyer?search=${search}`)
                combobox.closeDropdown()
                if (toggleModal) {
                    toggleModal()
                }
            } else {
                setValue((current) =>
                    current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
                );
                navigate(`/search-products-buyer?search=${val}`)
                if (toggleModal) {
                    toggleModal()
                }
                combobox.closeDropdown()
            }
        }
    };

    const handleValueRemove = (val: string) =>
        setValue((current) => current.filter((v) => v !== val));

    const values = value.map((item, index) => (
        <Pill key={index} withRemoveButton onRemove={() => handleValueRemove(item)}>
            {item}
        </Pill>
    ));

    const options = data?.filter((item) => item.toLowerCase().includes(search.trim().toLowerCase()))
        .map((item, index) => (
            <Combobox.Option value={item} key={index} active={value.includes(item)}>
                <Group gap="lg" align="centre">
                    {value.includes(item) ? <CheckIcon size={12}/> : <IconSearch size="23px"/>}
                    <span>{item}</span>
                </Group>
            </Combobox.Option>
        ));

    useEffect(() => {
        setData(selectData)
    }, [selectData]);


    return (
        <Combobox
            variant="unstyled"
            radius="lg"
            size={isMobile ? "lg" : "sm"} store={combobox} onOptionSubmit={handleValueSelect}
            withinPortal={false}>
            <Combobox.DropdownTarget>
                <PillsInput w={isMobile ? "100%" : isLaptop ? "100%" : "50%"} radius="xl"
                            size={isMobile ? "md" : "lg"} onClick={() => combobox.openDropdown()}>
                    <Pill.Group>
                        {values}
                        <Combobox.EventsTarget>
                            <PillsInput.Field
                                w={isMobile ? "100%" : isLaptop ? "20%" : "40%"}
                                onFocus={() => combobox.openDropdown()}
                                onBlur={() => combobox.closeDropdown()}
                                value={search}
                                placeholder="Search Tiger it"
                                onChange={(event) => {
                                    combobox.updateSelectedOptionIndex();
                                    setSearch(event.currentTarget.value);
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace' && search.length === 0) {
                                        event.preventDefault();
                                        handleValueRemove(value[value.length - 1]);
                                    }
                                }}
                            />
                        </Combobox.EventsTarget>
                    </Pill.Group>
                </PillsInput>
            </Combobox.DropdownTarget>
            <Combobox.Dropdown>
                <Combobox.Options>
                    {options}

                    {!exactOptionMatch && search.trim().length > 0 && (
                        <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
                    )}

                    {/*{filteredOptions.length === 0 && (*/}
                    {/*    <Combobox.Empty>Nothing found</Combobox.Empty>*/}
                    {/*)}*/}
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
};

export default CustomMultiSelect;