import {notifications} from "@mantine/notifications";

export function notificationSystem(title: string, message: string, error: boolean) {
    if (error) {
        notifications.show({
            title: title,
            message: message,
            color: "red"
        })
    } else {
        notifications.show({
            title: title,
            message: message,
            color: "#62BE12"
        })
    }
}