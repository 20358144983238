import {Button, Card, em, Flex, NumberInput, Select, Textarea, TextInput, Title} from "@mantine/core";
import CustomDropzone from "../../../components/CustomDropzone.tsx";
import {useState} from "react";
import {FileWithPath} from "@mantine/dropzone";
import {IconMapPin} from "@tabler/icons-react";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";
import GoogleMapDrawer from "../../../components/drawers/GoogleMapDrawer.tsx";
import GoogleMapModal from "../../../components/modals/GoogleMapModal.tsx";
import {useForm} from "@mantine/form";
import {notificationSystem} from "../../../utils/notificationSystem.ts";
import {useNavigate} from "react-router-dom";
import {getAddress} from "../../../utils/storage.ts";
import {createBusinessAction} from "../../../store/business/businessesActions.ts";

export interface ICreateBusinessForm {
    title: string
    type: string
    phoneNumber: string
    category: string
    email: string
    description: string
    registration: string
}

const AddNewBusiness = () => {
    const [image, setImage] = useState<File | null | FileWithPath>(null)
    const [loading, setLoading] = useState(false);
    const [imageUploading, setImageUploading] = useState(false)
    const {address} = useAppSelector(state => state.account)
    const [mapOpened, {open, close}] = useDisclosure(false)
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const navigate = useNavigate()
    const dispatch = useAppDispatch()


    const form = useForm<ICreateBusinessForm>({
        initialValues: {
            title: '',
            category: "",
            type: "",
            phoneNumber: "",
            email: "",
            description: "",
            registration: ""
        },

        validate: {
            title: (value: string) => (value.length === 0 ? 'Cannot be empty' : null),
            type: (value: string) => (value.length === 0 ? 'Cannot be empty' : null),
            phoneNumber: (value: string) => (value.length === 0 ? 'Cannot be empty' : null),
            email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            description: (value: string) => (value.length === 0 ? 'Cannot be empty' : null),
            category: (value) => (value.length === 0 ? 'Cannot be empty' : null),
            registration: (value) => (value.length === 0 ? 'Cannot be empty' : null),
        },
    });

    return (
        <Flex direction="column" p="md">
            <Title order={3} my="sm">Add Business</Title>
            <form onSubmit={form.onSubmit(async (values) => {

                if (!image) {
                    notificationSystem("Cannot be empty", "Please upload Image", true)
                    return
                }
                setLoading(true);
                try {
                    const address = getAddress();
                    const formData = new FormData();

                    formData.append("formattedAddress", address.formattedAddress);
                    formData.append("latitude", address.latitude.toString());
                    formData.append("longitude", address.longitude.toString());
                    formData.append("country", address.country);
                    formData.append("isoCode", address.isoCode);
                    formData.append("state", address.state);
                    formData.append("city", address.city);
                    formData.append("coordinates[0]", address.coordinates[0].toString());
                    formData.append("coordinates[1]", address.coordinates[1].toString());
                    formData.append("title", values.title);
                    formData.append("phoneNumber", values.phoneNumber);
                    formData.append("category", values.category.toLowerCase());
                    formData.append("email", values.email);
                    formData.append("description", values.description);
                    formData.append("registrationNumber", values.registration);
                    formData.append("image", image);
                    formData.append("type", values.type.toLowerCase());

                    await dispatch(createBusinessAction(formData));
                    navigate(-1);
                } catch (error) {
                    console.error("Error creating business:", error);
                } finally {
                    setLoading(false);
                }
            })}>
                <Card>
                    <CustomDropzone label="Drag & Drop files here to upload" onDrop={(file) => {
                        setImageUploading(true)
                        setImage(file[0])
                        setImageUploading(false)
                    }} loading={imageUploading} url={image}/>

                    <TextInput {...form.getInputProps("title")} radius="md" my="sm" size="md" label="Store Name"
                               placeholder="Enter store name"/>
                    <Select {...form.getInputProps("type")} radius="md"
                            data={["Food", "Business"]}
                            size="md" label="What type of products you wanna sell?"
                            placeholder="Select Product Type"/>
                    <Select {...form.getInputProps("category")} radius="md"
                            data={["Restaurants", "Grocery", "Pharmacy", "Other"]}
                            size="md" label="Store Category"
                            placeholder="Select Category"/>
                    <TextInput defaultValue={address} onClick={open} radius="md" my="sm" size="md"
                               label="Address"
                               rightSection={<IconMapPin/>}
                               placeholder="Select Address"/>
                    <NumberInput radius="md" size="md" prefix="+" label="Phone Number" placeholder="Enter Phone Number"
                                 hideControls {...form.getInputProps("phoneNumber")}/>
                    <TextInput radius="md" size="md" my="sm" label="Email" {...form.getInputProps("email")}
                               placeholder="Enter Email Address"/>
                    <TextInput radius="md" size="md" my="sm"
                               label="Registration" {...form.getInputProps("registration")}
                               placeholder="Enter Registration Number"/>
                    <Textarea radius="md" rows={4} my="sm"
                              label="Description" {...form.getInputProps("description")}
                              placeholder="Enter Description"/>
                    <Flex justify="end" gap="sm" my="sm" w="100%">
                        <Button radius="xl" color="#62BE12" variant="outline"
                                onClick={() => navigate(-1)}>Cancel</Button>
                        <Button loading={loading} radius="xl" color="#62BE12" type="submit">Submit</Button>
                    </Flex>
                </Card>
            </form>
            {isMobile ? <GoogleMapDrawer opened={mapOpened} onClose={close}/> :
                <GoogleMapModal opened={mapOpened} onClose={close}/>}
        </Flex>
    );
};

export default AddNewBusiness;