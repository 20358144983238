import {em, Flex, Text} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";
import {useLocation, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {useEffect} from "react";
import {getOneRestaurantAction} from "../../store/restaurants/restaurantsActions";
import FoodItemCard from "../../components/FoodItemCard.tsx";

const ViewAllFood = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const location = useLocation()

    const {id} = useParams()
    const dispatch = useAppDispatch()
    const {singleRestaurant} = useAppSelector(state => state.restaurants)
    // const navigate = useNavigate()


    useEffect(() => {
        if (!id) return
        dispatch(getOneRestaurantAction({id: id}))
    }, []);


    return (

        <Flex direction="column" mb="lg" justify="center" align="center">
            <Flex m="sm" justify={isMobile ? "center" : ""} miw={isMobile ? "100%" : 1240}>
                <Text ml={isMobile ? "" : "65px"} fw={700}>All food items</Text>
            </Flex>
            <Flex justify="center" align="center" wrap="wrap" gap="xs" maw={isMobile ? "100%" : 1240}>
                {singleRestaurant?.restaurant?.food?.length > 0 ? singleRestaurant?.restaurant?.food.map((food, index) => {
                        return (
                            <FoodItemCard user={location?.state?.user} food={food} key={index}/>
                        )
                    })
                    :
                    <Text mx="lg">No food items available for this restaurant</Text>
                }
            </Flex>
        </Flex>
    );
};

export default ViewAllFood;