import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {notificationSystem} from "../../utils/notificationSystem.ts";
import {IBusinessOrdersResponse} from "../../interfaces/business/seller/IBusinessOrdersResponse.ts";
import {IAllTransactionsResponse} from "../../interfaces/business/seller/IAllTransactionsResponse.ts";

export const getAllStatsAction = createAsyncThunk("seller/getStats", async () => {
    try {
        const res = await axiosInstance.get("seller/sellerSaleStats")
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const addProductAction = createAsyncThunk("seller/addProduct", async (data: any) => {
    try {
        const res = await axiosInstance.post("seller/add-product", data)
        if (res.data.success) {
            notificationSystem("product added successfully", `${res.data.product.productName} added`, false)
        } else {
            notificationSystem("Something went wrong", res.data.message, true)
        }
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getAllBusinessOrdersAction = createAsyncThunk("seller/getAllBusinessOrders", async () => {
    try {
        const res = await axiosInstance.get<IBusinessOrdersResponse>("business/business-orders")
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getAllTransactionsAction = createAsyncThunk("seller/getAllTransactions", async () => {
    try {
        const res = await axiosInstance.get<IAllTransactionsResponse>("wallet/transactions")
        return res.data
    } catch (e) {
        console.log(e)
    }
})