import {INotificationsResponse} from "../../interfaces/notifications/INotificationsResponse.ts";
import {createSlice} from "@reduxjs/toolkit";
import {getAllNotificationsAction} from "./notificationsActions.ts";

interface InitialState {
    allNotifications: INotificationsResponse
    loaders: {
        gettingNotifications: boolean
    }
}

const initialState: InitialState = {
    allNotifications: {
        success: false,
        length: 0,
        notifications: []
    },
    loaders: {
        gettingNotifications: false
    }
}

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        updateNotifications: (state, action) => {
            state.allNotifications.notifications = state.allNotifications.notifications.map((notification) => {
                if (action.payload === notification._id) {
                    return {
                        _id: notification._id,
                        userId: notification.userId,
                        title: notification.title,
                        subtitle: notification.subtitle,
                        icon: notification.icon,
                        createdAt: notification.createdAt,
                        updatedAt: notification.updatedAt,
                        __v: notification.__v,
                        seen: true
                    }
                }
                return notification
            })
        }
    },
    extraReducers: builder => {
        builder
            // getting all notification
            .addCase(getAllNotificationsAction.pending, (state) => {
                state.loaders.gettingNotifications = true
            })
            .addCase(getAllNotificationsAction.fulfilled, (state, action) => {
                state.loaders.gettingNotifications = false
                if (!action.payload) return
                state.allNotifications = action.payload
            })
            .addCase(getAllNotificationsAction.rejected, (state) => {
                state.loaders.gettingNotifications = false
            })
    }
})

const notificationsReducer = notificationsSlice.reducer
export const {updateNotifications} = notificationsSlice.actions
export default notificationsReducer