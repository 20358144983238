import {useDisclosure, useMediaQuery} from '@mantine/hooks';
import {AppShell, Box, Burger, Drawer, em, Flex, Group, Image, NavLink, SegmentedControl, Text} from '@mantine/core';
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {
    IconBell,
    IconBuildingStore,
    IconCategory,
    IconHeadset,
    IconLogout,
    IconMessage,
    IconTag,
    IconUser,
    IconWallet
} from "@tabler/icons-react";
import {clearToken, getToken} from "../../utils/storage.ts";
import {useEffect, useState} from "react";
import {modals} from "@mantine/modals";
import logo from "../../assets/1-Tigerit-logo-2.png";
import {getDashboardStatsAction} from "../../store/business/businessesActions.ts";
import {useAppDispatch} from "../../store/store.ts";
import {UserButton} from "../buyer-navbar/UserButton.tsx";
import navBar from "../../assets/navbar-logo.png";

const data = [
    {icon: IconCategory, label: 'Dashboard', navigate: "/seller"},
    {icon: IconUser, label: 'Account Settings', navigate: "/seller/seller-profile"},
    {icon: IconBuildingStore, label: 'Businesses', navigate: "/seller/businesses"},
    // {icon: IconBuildingStore, label: 'Products', navigate: "/seller/products"},
    {icon: IconTag, label: 'Orders', navigate: "/seller/orders"},
    {icon: IconWallet, label: 'My Wallet', navigate: "/seller/seller-wallet"},
    {icon: IconMessage, label: 'Messages', navigate: "/seller/seller-inbox"},
    {icon: IconBell, label: 'Notifications', navigate: "/seller/notification"},

    {icon: IconHeadset, label: 'Customer Support', navigate: "https://tawk.to/chat/65eea7698d261e1b5f6b657f/1hom3rtkb"},
];

export function SellerNavbar() {
    const [opened, {toggle}] = useDisclosure();
    const navigate = useNavigate()
    const isMobile = useMediaQuery(`(max-width: ${em(576)})`);
    const [active, setActive] = useState("");
    const dispatch = useAppDispatch()
    const location = useLocation()

    const items = data.map((item, index) => (
        <NavLink
            // href="/seller"
            key={index}
            active={item.label === active}
            label={item.label}
            color="lime"
            variant="filled"
            leftSection={<item.icon size="1rem" stroke={1.5}/>}
            onClick={() => {
                if (item.label === "Customer Support") {
                    setActive(item.label)
                    toggle()
                    window.open(item.navigate, "_blank")
                    return
                }
                setActive(item.label)
                navigate(item.navigate)
                toggle()
            }}
            style={{borderRadius: "8px"}}
        />
    ));
    useEffect(() => {
        const pathSegments = location.pathname.substring(1).split('/');
        const secondPathName = pathSegments.length > 1 ? pathSegments[1] : null;


        const activeTab = data.find(item => {
            const navigatePath = item.navigate.toLowerCase();
            return navigatePath.includes(secondPathName?.toLowerCase() || "");
        });

        if (activeTab) {
            setActive(activeTab.label);
        } else if (secondPathName === "chat") {
            setActive('Messages');
        } else if (secondPathName === "products") {
            setActive('Businesses');
        } else if (secondPathName === "add-product") {
            setActive('Businesses');
        } else if (secondPathName === "update-business") {
            setActive('Businesses');
        } else if (secondPathName === "new-business") {
            setActive('Businesses');
        } else if (secondPathName === "order-details") {
            setActive("Orders");
        } else {
            setActive('Dashboard');
        }

        dispatch(getDashboardStatsAction())
    }, [location, data]);

    if (!getToken()) {
        return <Navigate to="/" replace={true}/>
    }

    return (
        <AppShell
            header={{height: 60}}
            navbar={{width: 300, breakpoint: 'md', collapsed: {mobile: !opened}}}
            footer={{height: 80, collapsed: true}}
            // padding="md"
        >
            <AppShell.Header>
                <Group h="100%" w="100%" px="md">
                    <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="sm"/>
                    {/*<Text fw={700}>Tiger it</Text>*/}
                    <Image style={{cursor: "pointer"}} onClick={() => navigate("/seller")} src={logo} h="40px"
                           w="300px" visibleFrom="lg"/>
                    <Image style={{cursor: "pointer"}} onClick={() => navigate("/seller")} src={logo} h="35px"
                           w="300px" hiddenFrom="lg"/>
                </Group>
            </AppShell.Header>

            {isMobile ?
                <Drawer.Root size={isMobile ? "xs" : "sm"} opened={opened} onClose={toggle}>
                    <Drawer.Overlay/>
                    <Drawer.Content>
                        <Drawer.Header>
                            <Drawer.Title>
                                <Image src={navBar} w="130px"/>
                            </Drawer.Title>
                            <Drawer.CloseButton/>
                        </Drawer.Header>
                        <Drawer.Body h="92%" p={0}>
                            <Flex justify="space-between" h="100%" direction="column">
                                <Flex direction="column" px="sm">
                                    <Flex w="100%" justify="center">
                                        <SegmentedControl fw={200} w="60%" color="#62BE12"
                                                          onClick={() => navigate("/buyer")}
                                                          radius="xl"
                                                          data={[
                                                              {
                                                                  value: 'seller',
                                                                  label: 'Seller',
                                                              },
                                                              {
                                                                  value: 'buyer',
                                                                  label: 'Buyer',
                                                                  disabled: true,
                                                              }
                                                          ]}/>
                                        {/*<Button style={{alignSelf: "center"}} size="md"*/}
                                        {/*        onClick={() => navigate("/buyer")}*/}
                                        {/*        fw={200}*/}
                                        {/*        color="#62BE12"*/}
                                        {/*        variant="outline"*/}
                                        {/*        radius="xl">Switch to Buyer</Button>*/}
                                    </Flex>
                                    <Flex direction="column" mt="sm">
                                        {items}
                                    </Flex>
                                    <NavLink
                                        label="Logout"
                                        c="red"
                                        variant="filled"
                                        leftSection={<IconLogout size="1rem" stroke={1.5}/>}
                                        onClick={() => {
                                            modals.openConfirmModal({
                                                title: 'Logout your profile',
                                                centered: true,
                                                children: (
                                                    <Text size="sm">
                                                        Are you sure you want to Logout your profile?
                                                    </Text>
                                                ),
                                                labels: {confirm: 'Logout', cancel: "cancel"},
                                                confirmProps: {color: 'red'},
                                                onCancel: () => console.log('Cancel'),
                                                onConfirm: () => {
                                                    clearToken()
                                                    navigate("/")
                                                },
                                            });
                                        }}
                                        style={{borderRadius: "8px"}}
                                    />

                                </Flex>
                                <Flex>
                                    <UserButton toggle={toggle} isSeller={true}/>
                                </Flex>
                            </Flex>
                        </Drawer.Body>
                    </Drawer.Content>
                </Drawer.Root> :
                <AppShell.Navbar py="md" px={4}>
                    <SegmentedControl style={{alignSelf: "center"}} fw={200} w="60%" color="#62BE12"
                                      onClick={() => navigate("/buyer")}
                                      radius="xl"
                                      data={[
                                          {
                                              value: 'seller',
                                              label: 'Seller',
                                          },
                                          {
                                              value: 'buyer',
                                              label: 'Buyer',
                                          }
                                      ]}/>
                    {/*<Button style={{alignSelf: "center"}} size="md" onClick={() => navigate("/buyer")} fw={200}*/}
                    {/*        color="#62BE12"*/}
                    {/*        variant="outline"*/}
                    {/*        radius="xl">Switch to Buyer</Button>*/}
                    <Flex direction="column" h="100%" justify="space-between" mt="sm">
                        <Flex direction="column">
                            <Box>{items}</Box>
                            <Box>
                                <NavLink
                                    label="Logout"
                                    variant="filled"
                                    color="red"
                                    c="red"
                                    leftSection={<IconLogout size="1rem" stroke={1.5}/>}
                                    onClick={() => {
                                        modals.openConfirmModal({
                                            title: 'Logout your profile',
                                            centered: true,
                                            children: (
                                                <Text size="sm">
                                                    Are you sure you want to Logout your profile?
                                                </Text>
                                            ),
                                            labels: {confirm: 'Logout', cancel: "cancel"},
                                            confirmProps: {color: 'red'},
                                            onCancel: () => console.log('Cancel'),
                                            onConfirm: () => {
                                                clearToken()
                                                navigate("/")
                                            },
                                        });
                                    }}
                                    style={{borderRadius: "8px"}}
                                />
                            </Box>
                        </Flex>
                        <Flex>
                            <UserButton toggle={toggle} isSeller={true}/>
                        </Flex>
                    </Flex>
                </AppShell.Navbar>}
            <AppShell.Main bg="#F5F6FA">
                <Outlet/>
            </AppShell.Main>
            <AppShell.Footer style={{backgroundColor: "black"}} p="md">Footer</AppShell.Footer>
        </AppShell>
    );
}