import {
    ActionIcon,
    Anchor,
    AppShell,
    Avatar,
    Burger,
    Card,
    Divider,
    em,
    Flex,
    Group,
    Image,
    Text,
    UnstyledButton
} from '@mantine/core';
import {Navigate, Outlet, useNavigate} from "react-router-dom";
import {getToken} from "../../utils/storage.ts";
import {useMediaQuery} from "@mantine/hooks";
import {IconChevronDown, IconMapPin} from "@tabler/icons-react";
import logo from "../../assets/logo.png";
import {useEffect, useState} from "react";
import {getAllCategoriesAction} from "../../store/category/categoriesActions.ts";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import CustomMultiSelect from "../CustomMultiSelect.tsx";
// import {requestForToken} from "../../config/firebaseConfig.ts";

export function Navbar() {

    const navigate = useNavigate()
    const isMobile = useMediaQuery(`(max-width: ${em(576)})`);
    const dispatch = useAppDispatch()
    const [categories, setCategories] = useState<string[]>([])
    const {products} = useAppSelector(state => state.product)

    useEffect(() => {
        dispatch(getAllCategoriesAction())
        // requestForToken()
    }, []);

    useEffect(() => {
        const categoriesArr = products?.data?.categoryProducts?.map((c) => {
            return c?.category
        })

        setCategories(categoriesArr)
    }, [products]);


    if (getToken()) {
        return <Navigate to="/buyer" replace={true}/>
    }
    return (
        <AppShell
            header={{height: 80}}
            footer={{height: 80, collapsed: true}}
        >
            <AppShell.Header bg="#62BE12">
                <Group h="100%" w="100%" px="md">

                    <Flex w="100%" h={isMobile ? "100%" : ""} direction={isMobile ? "column" : "row"}
                          justify={isMobile ? "space-evenly" : ""}
                          align={isMobile ? "" : "center"}
                          gap="sm"
                    >
                        <Flex align="center" gap="md" hiddenFrom="md" justify={isMobile ? "space-between" : ""}>
                            <Flex hiddenFrom="md" gap="sm" align="center">
                                <Burger onClick={() => navigate("/sign-in")} hiddenFrom="sm" size="sm" color="white"/>
                                <Text onClick={() => navigate("/")} fw={600} c="white" size="xl">
                                    Tiger it
                                </Text>
                            </Flex>
                            <Flex visibleFrom="md">
                                <CustomMultiSelect selectData={categories}/>
                            </Flex>
                            <Group gap="sm" h="100%">
                                <ActionIcon onClick={() => navigate("/sign-in")} radius="xl" size="xl" color="white"
                                            c="black">
                                    <IconMapPin/>
                                </ActionIcon>
                                <UnstyledButton
                                    style={{
                                        padding: "var(--mantine-spacing-xs)",
                                        color: 'white',
                                        borderRadius: 'var(--mantine-radius-xl)',
                                        background: "#58AB10"
                                    }}
                                    onClick={() => navigate("/sign-in")}
                                >
                                    <Group>
                                        <ActionIcon radius="xl" color="white" c="black">
                                            <Avatar/>
                                        </ActionIcon>
                                        <IconChevronDown size="1rem"/>
                                    </Group>
                                </UnstyledButton>
                            </Group>
                        </Flex>
                        <Image style={{cursor: "pointer"}} onClick={() => navigate("/")} src={logo} h="40px"
                               w="300px" visibleFrom="md"/>
                        <Flex align="center" w="80%" visibleFrom="md">
                            <Flex gap="xl" w="100%" justify="center">
                                <CustomMultiSelect selectData={categories}/>
                                <Card style={{cursor: "pointer"}} onClick={() => navigate("/sign-in")} w="250px"
                                      h="50px"
                                      radius="xl"
                                      bg="#58AB10">
                                    <Flex w="100%" h="100%" align="center" justify="space-between">
                                        <ActionIcon radius="xl" size="lg" color="#3B720B" c="white">
                                            <IconMapPin/>
                                        </ActionIcon>
                                        <Flex w={150} direction="column">
                                            <Text size="sm" c="white">Deliver to</Text>
                                            <Anchor size="sm" c="white" truncate="end">
                                                address
                                            </Anchor>
                                        </Flex>
                                        <IconChevronDown color="white"/>
                                    </Flex>
                                </Card>
                            </Flex>
                        </Flex>
                        <Flex gap={isMobile ? "" : "sm"} visibleFrom="md" w="20%" justify="end">
                            <Anchor c="white" onClick={() => navigate("/sign-in")}>Sign In</Anchor>
                            <Divider orientation="vertical" h={25}/>
                            <Anchor c="white" onClick={() => navigate("/sign-up")}>Sign Up</Anchor>
                        </Flex>
                    </Flex>
                </Group>
            </AppShell.Header>
            <AppShell.Main>
                <Outlet/>
            </AppShell.Main>
            <AppShell.Footer style={{backgroundColor: "black"}} p="md">Footer</AppShell.Footer>
        </AppShell>
    );
}