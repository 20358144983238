import {Card, Flex, Text, Textarea} from "@mantine/core";
import {UseFormReturnType} from "@mantine/form";
import {IProductAdd} from "../../../../interfaces/business/seller/IProductCreate.ts";

type AddProductProps = {
    form: UseFormReturnType<IProductAdd, (values: IProductAdd) => IProductAdd>
}
const AddProductDescription = ({form}: AddProductProps) => {
    return (
        <Card>
            <Flex direction="column" gap="sm">
                <Text fw={600}>Product Details</Text>
                <Textarea rows={6} label="Product Description"
                          placeholder="Description" {...form.getInputProps("description")}/>
                <Textarea rows={6} label="Product Highlights"
                          placeholder="Highlights" {...form.getInputProps("highlight")}/>
            </Flex>
        </Card>
    );
};

export default AddProductDescription;