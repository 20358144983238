import {Alert, Card, Flex, Grid, LoadingOverlay, Text, Title} from "@mantine/core";
import {useAppSelector} from "../../../store/store.ts";
import SparkLineCard from "../../../components/SparkLineCard.tsx";
import {IconInfoCircle, IconTrendingUp} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";

export const data = [
    {
        date: 'Mar 22',
        activeOrders: 2890,
        completedOrder: 2338,
    },
    {
        date: 'Mar 23',
        activeOrders: 2756,
        completedOrder: 2103,
    },
    {
        date: 'Mar 24',
        activeOrders: 3322,
        completedOrder: 986,
    },
    {
        date: 'Mar 25',
        activeOrders: 3470,
        completedOrder: 2108,
    },
    {
        date: 'Mar 26',
        activeOrders: 3129,
        completedOrder: 1726,
    },
];

const Dashboard = () => {
    const {dashboardStats, loaders} = useAppSelector(state => state.business)
    const navigate = useNavigate()

    return (
        <Flex direction="column" gap="lg" mb="xs" m="md">
            {dashboardStats?.stats?.warning?.type === "warning" &&
                <Alert style={{cursor: "pointer"}} onClick={() => navigate("/seller/new-business")} variant="light"
                       color="red"
                       title={dashboardStats?.stats?.warning?.message}
                       icon={<IconInfoCircle/>}>
                </Alert>
            }
            <LoadingOverlay visible={loaders.gettingDashboardStats}/>
            <Title order={3}>Dashboard</Title>
            <Grid>
                {dashboardStats?.stats?.mySales?.map((sale, index) => {
                    return (
                        <Grid.Col span={{base: 12, md: 6, lg: 3}} key={index}>
                            <SparkLineCard sale={sale}/>
                        </Grid.Col>
                    )
                })}
            </Grid>
            <Flex>
                <Text fw={600}>My Orders</Text>
            </Flex>
            <Grid>
                {dashboardStats?.stats?.myOrders?.map((myOrder, index) => {
                    return (
                        <Grid.Col key={index} span={{base: 6, md: 6, lg: 3}}>
                            <Card radius="lg" bg="white">
                                <Flex direction="column" w="100%" align="center" gap="xs">
                                    <Title>{myOrder?.count}</Title>
                                    <IconTrendingUp color="#20C375"/>
                                    <Text>{myOrder?.title}</Text>
                                </Flex>
                            </Card>
                        </Grid.Col>
                    )
                })}
            </Grid>
        </Flex>


    );
};

export default Dashboard;