import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {useMediaQuery} from "@mantine/hooks";
import {Card, em, Flex, Loader, Text, Title} from "@mantine/core";
import {useEffect} from "react";
import {searchProductsAction} from "../../../store/product/productActions.ts";
import ProductInfoCard from "./ProductInfoCard.tsx";

const ProductsBySearch = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('search');
    const dispatch = useAppDispatch()
    const {searchedProducts, loaders} = useAppSelector(state => state.product)
    const isSmall = useMediaQuery(`(max-width: ${em(768)})`)

    useEffect(() => {
        if (!category) return
        dispatch(searchProductsAction(category))
    }, [category]);
    return (
        <>
            {loaders.searchingProducts ?
                <Flex h="100vh" w="100%" align="center" justify="center">
                    <Loader/>
                </Flex> :
                searchedProducts?.products?.length <= 1 ?
                    <Flex w="100%" justify="center" align="center" my="xl" py="xl">
                        <Text>No product found in this category</Text>
                    </Flex> :
                    <Flex w="100%" direction="column">
                        <Card w="100%" pos="fixed" style={{zIndex: "100"}}>
                            <Flex w="100%" justify="center">
                                <Flex justify="space-between" align="center" w={isSmall ? "100%" : "70%"}>
                                    <Title order={4}>{searchedProducts?.length} Related Products
                                        found</Title>
                                    {/*<Button radius="xl" color="#62BE12">Create Coupon</Button>*/}
                                </Flex>
                            </Flex>
                        </Card>
                        <Flex justify="center" mt={60}>
                            <Flex direction="column" maw={isSmall ? "100%" : "70%"}>
                                <Flex wrap="wrap" justify={isSmall ? "center" : ""} mx="xs">
                                    {searchedProducts?.products?.map((product, index) => {
                                        return (

                                            <ProductInfoCard key={index} product={product}/>

                                        )
                                    })}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
            }

        </>
    );
};

export default ProductsBySearch;