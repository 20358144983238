import {ICategoriesResponse} from "../../interfaces/buyer/categories/ICategoriesResponse.ts";
import {createSlice} from "@reduxjs/toolkit";
import {getAllCategoriesAction, getAllGuestCategoriesAction, getSubCategoriesAction} from "./categoriesActions.ts";
import {ISubCategoriesResponse} from "../../interfaces/buyer/categories/ISubCategoriesResponse.ts";

interface initialState {
    categories: ICategoriesResponse
    subCategories: ISubCategoriesResponse
    loaders: {
        gettingAllCategories: boolean
        gettingSubcategories: boolean
    }
}

const initialState: initialState = {
    subCategories: {
        success: false,
        subCategories: []
    },
    categories: {
        success: false,
        length: 0,
        categories: []
    },
    loaders: {
        gettingAllCategories: false,
        gettingSubcategories: false
    }
}

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            //     get all categories
            .addCase(getAllCategoriesAction.pending, (state) => {
                state.loaders.gettingAllCategories = true
            })
            .addCase(getAllCategoriesAction.fulfilled, (state, action) => {
                state.loaders.gettingAllCategories = false
                state.categories = action.payload
            })
            .addCase(getAllCategoriesAction.rejected, (state) => {
                state.loaders.gettingAllCategories = false
            })
            //     get all guest categories
            .addCase(getAllGuestCategoriesAction.pending, (state) => {
                state.loaders.gettingAllCategories = true
            })
            .addCase(getAllGuestCategoriesAction.fulfilled, (state, action) => {
                state.loaders.gettingAllCategories = false
                state.categories = action.payload
            })
            .addCase(getAllGuestCategoriesAction.rejected, (state) => {
                state.loaders.gettingAllCategories = false
            })
            //     get all sub categories
            .addCase(getSubCategoriesAction.pending, (state) => {
                state.loaders.gettingSubcategories = true
            })
            .addCase(getSubCategoriesAction.fulfilled, (state, action) => {
                state.loaders.gettingSubcategories = false
                if (!action.payload) return
                state.subCategories = action.payload
            })
            .addCase(getSubCategoriesAction.rejected, (state) => {
                state.loaders.gettingSubcategories = false
            })
    }
})

const categoryReducer = categorySlice.reducer
export default categoryReducer