import {createSlice} from "@reduxjs/toolkit";
import {
    forgotPasswordAction,
    getCurrenciesAction,
    getProfileDataAction,
    resetPasswordAction,
    signInAction,
    signupAction,
    verifyOtpAction
} from "./accountActions.ts";
import {IUserUpdateResponse, IVerifyOtpResponse} from "../../interfaces/account/IAccount.ts";
import {IAuthUserResponse} from "../../interfaces/account/IAuthUserResponse.ts";
import {ICurrencies} from "../../interfaces/account/ICurrencies.ts";

interface initialState {
    authUser: IAuthUserResponse
    address: string
    currencies: ICurrencies
    centre: {
        lat: number,
        lng: number
    }
    verifyOtpResponse?: IVerifyOtpResponse
    loaders: {
        signupLoader: boolean
        verifyingOtp: boolean
        creatingAccount: boolean
        signingIn: boolean
        forgotPasswordLoading: boolean
        passwordResetting: boolean
        gettingUserProfile: boolean
    }
    userProfile?: IUserUpdateResponse
}

const initialState: initialState = {
    address: "",
    centre: {
        lat: 0,
        lng: 0
    },
    currencies: {
        success: false,
        currencies: []
    },
    authUser: {
        success: true,
        user: {
            preferences: {
                currency: {
                    name: "",
                    code: ""
                }
            },
            personalInformation: {
                idCard: {
                    status: ""
                },
                personalAddress: {
                    formattedAddress: "",
                    latitude: 0,
                    longitude: 0,
                    country: "",
                    isoCode: "",
                    state: "",
                    city: "",
                    type: "",
                    coordinates: []
                },
                firstName: "",
                lastName: "",
                phoneNumber: "",
                dob: "",
                gender: "",
                profilePic: ""
            },
            deviceInfo: {
                fcm: ""
            },
            credentialDetails: {
                email: "",
                googleId: ""
            },
            verifyCredentials: {
                verifyStatus: false,
                mobileVerifyStatus: false
            },
            driverAccount: {
                status: "",
                rating: {
                    numRating: 0,
                    totalRating: 0
                }
            },
            wallet: {
                balance: {
                    amount: 0,
                    currency: ""
                }
            },
            _id: "",
            isOnline: false,
            selectedCurrency: "",
            role: "",
            location: {
                type: ""
            },
            accountActive: false,
            loyaltyPoints: 0,
            createdAt: "",
            updatedAt: "",
            __v: 0,
            verificationStatus: false,
            position: {
                type: "",
                coordinates: [],
                longitude: 0,
                latitude: 0,
                heading: 0,
                speed: 0,
                updatedAt: ""
            }
        },
        token: ""
    },
    loaders: {
        signupLoader: false,
        verifyingOtp: false,
        creatingAccount: false,
        signingIn: false,
        forgotPasswordLoading: false,
        passwordResetting: false,
        gettingUserProfile: false
    },
    verifyOtpResponse: {
        success: false,
        message: "",
        token: ""
    },
    userProfile: {
        success: false,
        user: {
            preferences: {
                currency: {
                    name: "",
                    code: ""
                }
            },
            _id: "",
            personalInformation: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                gender: "",
                dob: "",
                profilePic: "",
                personalAddress: {
                    formattedAddress: "",
                    latitude: 0,
                    longitude: 0,
                    country: "",
                    isoCode: "",
                    state: "",
                    city: "",
                    type: "Point",
                    coordinates: [0, 0],
                    phoneNumber: ""
                }
            },
            credentialDetails: {
                email: "",
                password: "",
                googleId: ""
            },
            location: {
                type: "Point",
                coordinates: [0, 0]
            },
            verifyCredentials: {
                OTP: 0,
                OTPExpires: 0,
                verifyStatus: false,
                mobileVerifyStatus: false
            },
            accountActive: false,
            role: "",
            buyerAccount: {
                deliverAddress: {
                    formattedAddress: "",
                    latitude: 0,
                    longitude: 0,
                    country: "",
                    isoCode: "",
                    state: "",
                    city: "",
                    type: "Point",
                    coordinates: [0, 0],
                    phoneNumber: ""
                }
            },
            sellerAccount: {
                idCard: {
                    frontImage: "",
                    backImage: ""
                },
                name: "",
                cnic: "",
                image: "",
                businessType: "",
                registrationNumber: "",
                businessAddress: {
                    formattedAddress: "",
                    latitude: 0,
                    longitude: 0,
                    country: "",
                    isoCode: "",
                    state: "",
                    city: "",
                    type: "Point",
                    coordinates: [0, 0],
                    phoneNumber: ""
                },
                businessPhoneNumber: "",
                businessEmail: "",
                businessLocation: {
                    type: "Point",
                    coordinates: [0, 0]
                },
                businessDescription: "",
                verificationStatus: "pending",
                rating: {
                    totalRating: 0,
                    numRating: 0
                }
            },
            driverAccount: {
                idCard: {
                    front: "",
                    back: ""
                },
                vehicleDetails: {
                    vehicleLiscenceFront: "",
                    vehicleLiscenceBack: ""
                },
                fullName: "",
                nationality: "",
                driverAddress: {
                    formattedAddress: "",
                    latitude: 0,
                    longitude: 0,
                    country: "",
                    isoCode: "",
                    state: "",
                    city: "",
                    type: "Point",
                    coordinates: [0, 0],
                    phoneNumber: ""
                },
                idCardNumber: "",
                dateOfExpiary: "",
                dateOfIssues: "",
                status: "pending",
                country: "",
                registrationNumber: ""
            },
            selectedCurrency: "",
            position: {
                type: "",
                coordinates: [0, 0],
                updatedAt: "",
                latitude: 0,
                longitude: 0,
                heading: 0,
                speed: 0
            },
            deviceInfo: {
                fcm: ""
            },
            isOnline: false
        }
    }
}

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setNavbarAddress: (state, action) => {
            state.address = action.payload
        },
        setCentre: (state, action) => {
            state.centre = action.payload
        }
    },
    extraReducers: builder => {
        builder
            // sign up account
            .addCase(signupAction.pending, (state) => {
                state.loaders.signupLoader = true
            })
            .addCase(signupAction.fulfilled, (state, action) => {
                state.loaders.signupLoader = false
                if (!action.payload) return
                state.authUser = action.payload
            })
            .addCase(signupAction.rejected, (state) => {
                state.loaders.signupLoader = false
            })
            // verify otp
            .addCase(verifyOtpAction.pending, (state) => {
                state.loaders.verifyingOtp = true
            })
            .addCase(verifyOtpAction.fulfilled, (state, action) => {
                state.loaders.verifyingOtp = false
                state.verifyOtpResponse = action.payload
            })
            .addCase(verifyOtpAction.rejected, (state) => {
                state.loaders.verifyingOtp = false
            })
            // sign in
            .addCase(signInAction.pending, (state) => {
                state.loaders.signingIn = true
            })
            .addCase(signInAction.fulfilled, (state, action) => {
                state.loaders.signingIn = false
                if (!action.payload) return
                state.authUser = action.payload
            })
            .addCase(signInAction.rejected, (state) => {
                state.loaders.signingIn = false
            })
            // forgot password
            .addCase(forgotPasswordAction.pending, (state) => {
                state.loaders.forgotPasswordLoading = true
            })
            .addCase(forgotPasswordAction.fulfilled, (state) => {
                state.loaders.forgotPasswordLoading = false
            })
            .addCase(forgotPasswordAction.rejected, (state) => {
                state.loaders.forgotPasswordLoading = false
            })
            // forgot password
            .addCase(resetPasswordAction.pending, (state) => {
                state.loaders.passwordResetting = true
            })
            .addCase(resetPasswordAction.fulfilled, (state) => {
                state.loaders.passwordResetting = false
            })
            .addCase(resetPasswordAction.rejected, (state) => {
                state.loaders.passwordResetting = false
            })
            // forgot password
            .addCase(getProfileDataAction.pending, (state) => {
                state.loaders.gettingUserProfile = true
            })
            .addCase(getProfileDataAction.fulfilled, (state, action) => {
                state.loaders.gettingUserProfile = false
                state.userProfile = action?.payload
            })
            .addCase(getProfileDataAction.rejected, (state) => {
                state.loaders.gettingUserProfile = false
            })
            // get currencies
            .addCase(getCurrenciesAction.pending, (state) => {
                state.loaders.gettingUserProfile = true
            })
            .addCase(getCurrenciesAction.fulfilled, (state, action) => {
                state.loaders.gettingUserProfile = false
                if (!action.payload) return
                state.currencies = action?.payload
            })
            .addCase(getCurrenciesAction.rejected, (state) => {
                state.loaders.gettingUserProfile = false
            })

    }
})

export const accountReducer = accountSlice.reducer
export const {setNavbarAddress, setCentre} = accountSlice.actions