import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {notificationSystem} from "../../utils/notificationSystem.ts";
import {IBuyNowCreateOrder, ICreateOrder,} from "../../interfaces/buyer/cart/ICreateOrder.ts";
import {ProductQueryParams} from "../../interfaces/IQueryParams.ts";
import {IProductByCategoryResponse} from "../../interfaces/buyer/product/IProductByCategoryResponse.ts";
import {IProductBySubCategoryResponse} from "../../interfaces/buyer/product/IProductBySubCategory.ts";
import {IDeleteCartItemResponse} from "../../interfaces/buyer/cart/IDeleteCartItemResponse.ts";
import {toggleCartAction, updateCart} from "./productSlice.ts";
import {IAddProductToWishlist} from "../../interfaces/buyer/product/IAddProductToWishList.ts";
import {ICouponsResponse} from "../../interfaces/buyer/cart/ICouponsResponse.ts";
import {IUpdatedCartResponse} from "../../interfaces/buyer/cart/IUpdatetdCartResponse.ts";
import {ICartUpdateData, Item,} from "../../interfaces/buyer/cart/ICartUpdateData.ts";
import {ISingleProductResponse} from "../../interfaces/buyer/product/ISingleProductResponse.ts";
import {ISuggestedProductsResponse} from "../../interfaces/buyer/product/ISuggestedProductsResponse.ts";
import {IGuestSingleProduct} from "../../interfaces/guest/IGuestSingleProduct.ts";
import {IAddToCartResponse} from "../../interfaces/buyer/cart/IAddToCartResponse.ts";
import {IUpdateCartResponseNew} from "../../interfaces/buyer/cart/IUpdateCartResponseNew.ts";
import {IRecentSearches} from "../../interfaces/buyer/product/IRecentSearches.ts";
import {IUnReviewedProductsResponse} from "../../interfaces/buyer/product/IUnReviewedProductsResponse.ts";
import {IReviewProductSubmit} from "../../interfaces/buyer/product/IReviewProductSubmit.ts";

export const getAllProductsAction = createAsyncThunk(
    "product/getAllProducts",
    async ({lat, lng}: { lat: number; lng: number }) => {
        try {
            const res = await axiosInstance.get(
                `buyer/home-data?lng=${lng}&lat=${lat}`
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", res.data.message, true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const getAllGuestProductsAction = createAsyncThunk(
    "product/getAllGuestProducts",
    async () => {
        try {
            const res = await axiosInstance.get("guest/home-data");
            if (!res.data.success) {
                notificationSystem("Something went wrong", res.data.message, true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const getOneGuestProductAction = createAsyncThunk(
    "product/getOneGuestProducts",
    async (id: string) => {
        try {
            const res = await axiosInstance.get<IGuestSingleProduct>(
                `guest/guest-product-by-id?productId=${id}`
            );

            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const getOneProduct = createAsyncThunk(
    "product/getOneProduct",
    async ({id}: { id: string }) => {
        try {
            const res = await axiosInstance.get<ISingleProductResponse>(
                `buyer/product-by-id?productId=${id}`
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const addToCartAction = createAsyncThunk(
    "product/addToCart",
    async (
        data: {
            productId: string;
        },
        {dispatch}
    ) => {
        try {
            const res = await axiosInstance.post<IAddToCartResponse>(
                "buyer/add-to-cart",
                data
            );

            if (!res.data.success && res.data.message) {
                notificationSystem("Something went wrong", res.data.message, true);
            } else {
                notificationSystem("Item added into cart successfully", "", false);
                dispatch(toggleCartAction(res.data.cart.items.length));
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const updateCartAction = createAsyncThunk(
    "product/updateCart",
    async (data: ICartUpdateData, {dispatch}) => {
        try {
            const res = await axiosInstance.put<IUpdatedCartResponse>(
                "buyer/cart",
                data
            );
            dispatch(updateCart(res.data));
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const updateCartItemAction = createAsyncThunk(
    "product/updateCartItem",
    async (
        data: {
            items: Item[];
        },
        {dispatch}
    ) => {
        try {
            const res = await axiosInstance.put<IUpdateCartResponseNew>(
                "buyer/cart-update",
                data
            );
            dispatch(toggleCartAction(res.data.cart.items.length));
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const deleteCartItemAction = createAsyncThunk(
    "product/deleteCartItem",
    async (data: { productId: string }) => {
        try {
            const res = await axiosInstance.put<IDeleteCartItemResponse>(
                "buyer/delete-specific-cart-item",
                data
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            } else {
                notificationSystem("Item deleted from cart successfully", "", true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const deleteCartAction = createAsyncThunk(
    "product/deleteCart",
    async () => {
        try {
            const res = await axiosInstance.delete("buyer/cart-delete");
            if (!res.data.success) {
                notificationSystem("Something went wrong", res.data.message, true);
            } else {
                notificationSystem("Cart deleted", res.data.message, true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const getAllCartItems = createAsyncThunk(
    "product/getAllCartItems",
    async () => {
        try {
            const res = await axiosInstance.get<IUpdatedCartResponse>("buyer/cart");
            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const getAllProductsActionByCategory = createAsyncThunk(
    "product/getAllProductsByCategory",
    async ({category}: { category: string }) => {
        try {
            const res = await axiosInstance.get<IProductByCategoryResponse>(
                `buyer/sub-categories-by-category-name?categoryName=${encodeURIComponent(
                    category
                )}`
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const getAllProductsActionBySubCategory = createAsyncThunk(
    "product/getAllProductsBySubCategory",
    async ({category}: { category: string }) => {
        try {
            const res = await axiosInstance.get<IProductBySubCategoryResponse>(
                `buyer/products-specific-subcategory?subCategoryName=${encodeURIComponent(
                    category
                )}`
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const getProductsSuggestionAction = createAsyncThunk(
    "product/getProductsSuggestion",
    async (params: ProductQueryParams) => {
        try {
            const res = await axiosInstance.get<ISuggestedProductsResponse>(
                `buyer/products-suggestion?productId=${params.productId}&businessId=${
                    params.businessId
                }&category=${encodeURIComponent(params.category)}&productType=${
                    params.productType
                }`
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);

export const getAllGuestProductsActionByCategory = createAsyncThunk(
    "product/getAllGuestProductsByCategory",
    async ({category}: { category: string }) => {
        try {
            const res = await axiosInstance.get(
                `guest/products-specific-category?categoryName=${category}`
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", res.data.message, true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const getAllFavouriteProductsAction = createAsyncThunk(
    "product/getAllFavouriteProducts",
    async () => {
        try {
            const res = await axiosInstance.get(
                "buyer/wishlist-products-specific-user"
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", res.data.message, true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const searchProductsAction = createAsyncThunk(
    "product/searchProducts",
    async (product: string) => {
        try {
            const res = await axiosInstance.get(
                `buyer/search-product?productName=${product}`
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", res.data.message, true);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);

export const getRecentSearchesAction = createAsyncThunk(
    "product/getRecentSearches",
    async (type: string) => {
        try {
            const res = await axiosInstance.get<IRecentSearches>(
                `buyer/get-search-history?type=${type}`
            );
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
);

export const createOrderAction = createAsyncThunk(
    "product/createOrder",
    async (data: ICreateOrder) => {
        try {
            const res = await axiosInstance.post("buyer/create-order/v2", data);

            if (!res.data.success) {
                notificationSystem("Something went wrong", res.data.message, true);
            } else {
                notificationSystem("Order has been placed", res.data.message, false);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);
export const createBuyNowOrderAction = createAsyncThunk(
    "product/createBuyNoeOrder",
    async (data: IBuyNowCreateOrder) => {
        try {
            const res = await axiosInstance.post("buyer/buy", data);

            if (!res.data.success) {
                notificationSystem("Something went wrong", res.data.message, true);
            } else {
                notificationSystem("Order has been placed", res.data.message, false);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);

export const addToFavouriteAction = createAsyncThunk(
    "product/addToFavourite",
    async (data: { productId: string }) => {
        try {
            const res = await axiosInstance.put<IAddProductToWishlist>(
                "buyer/buyer-add-wishlist",
                data
            );
            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            } else {
                notificationSystem("Product has been added to wishlist", "", false);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);

export const removeFromFavouriteAction = createAsyncThunk(
    "product/removeFromFavourite",
    async (data: { productId: string }) => {
        try {
            const res = await axiosInstance.put<IAddProductToWishlist>(
                "buyer/buyer-remove-wishlist",
                data
            );

            if (!res.data.success) {
                notificationSystem("Something went wrong", "", true);
            } else {
                notificationSystem("Product has been removed from wishlist", "", false);
            }
            return res.data;
        } catch (e) {
            console.log(e);
            notificationSystem("Something went wrong", "", true);
        }
    }
);

export const getAllCoupons = createAsyncThunk(
    "product/getAllCoupons",
    async () => {
        try {
            const res = await axiosInstance.get<ICouponsResponse>(
                "buyer/coupons-with-status"
            );
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
);

export const getAllUnreviewedProductsAction = createAsyncThunk("product/getAllUnreviewedProducts", async () => {
    try {
        const res = await axiosInstance.get<IUnReviewedProductsResponse>("buyer/unreviewed-products")
        return res.data
    } catch (e) {
        notificationSystem("Something went wrong", "", true);
    }
})

export const submitReviewAction = createAsyncThunk("product/submitReview", async (form: IReviewProductSubmit) => {
    try {
        const res = await axiosInstance.post("productReview/single-product-review", form)
        return res.data
    } catch (e) {
        notificationSystem("Something went wrong", "", true);
    }
})
