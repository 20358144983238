import {IWallettopUpResponse} from "../../interfaces/wallet/IWalletTopUpResponse.ts";
import {createSlice} from "@reduxjs/toolkit";
import {getTransactionHistory, topUpAction} from "./walletActions.ts";
import {IWalletWithTransactionHistoryResponse} from "../../interfaces/wallet/IWalletWithTransactionHistoryResponse.ts";

interface initialState {
    walletPaymentResponse: IWallettopUpResponse
    walletWithTransactionHistory: IWalletWithTransactionHistoryResponse
    loaders: {
        makingTopUp: boolean
        gettingWallet: boolean
    }
}

const initialState: initialState = {
    walletWithTransactionHistory: {
        success: false,
        wallet: {
            balance: {
                amount: 0,
                currency: ""
            },
            transactions: []
        }
    },
    walletPaymentResponse: {
        success: false,
        responsePayment: {
            balance: {
                amount: 0,
                currency: ""
            },
            topupAmount: {
                amount: 0,
                currency: ""
            }
        }
    },
    loaders: {
        makingTopUp: false,
        gettingWallet: false
    }
}

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            // top up balance
            .addCase(topUpAction.pending, (state) => {
                state.loaders.makingTopUp = true
            })
            .addCase(topUpAction.fulfilled, (state, action) => {
                state.loaders.makingTopUp = false
                if (!action.payload) return
                state.walletPaymentResponse = action.payload
            })
            .addCase(topUpAction.rejected, (state) => {
                state.loaders.makingTopUp = false
            })
            // get wallet with transaction history
            .addCase(getTransactionHistory.pending, (state) => {
                state.loaders.gettingWallet = true
            })
            .addCase(getTransactionHistory.fulfilled, (state, action) => {
                state.loaders.gettingWallet = false
                if (!action.payload) return
                state.walletWithTransactionHistory = action.payload
            })
            .addCase(getTransactionHistory.rejected, (state) => {
                state.loaders.gettingWallet = false
            })

    }
})

const walletReducer = walletSlice.reducer
export default walletReducer