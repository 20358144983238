import {Box, em, Skeleton} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";

const SliderSkeleton = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    return (
        <Box
            h={isMobile ? 200 : 301}
            w={isMobile ? "100%" : 505}
            p="sm"
            pr="lg"
        >
            <Skeleton
                radius="lg"
                height={isMobile ? 150 : "80%"}
                width="100%"
            />
        </Box>
    );
};

export default SliderSkeleton;