export const discountCalculator = (originalPrice: number, discountPercentage: number) => {
    if (originalPrice < 0 || discountPercentage < 0 || discountPercentage > 100) {
        // Handle invalid input
        throw new Error('Invalid input. Please provide valid original price and discount percentage.');
    }

    const discountAmount = (originalPrice * discountPercentage) / 100;
    const discountedPrice = originalPrice - discountAmount;

    // Round off to one decimal place
    return Number(discountedPrice.toFixed(1));
}

export const discountCalculatorWithCurrency = (
    originalPrice: number,
    discountPercentage: number,
    currency: string
): string => {
    if (originalPrice < 0 || discountPercentage < 0 || discountPercentage > 100) {
        // Handle invalid input
        throw new Error('Invalid input. Please provide a valid original price and discount percentage.');
    }

    const discountAmount = (originalPrice * discountPercentage) / 100;
    const discountedPrice = originalPrice - discountAmount;

    try {
        // Attempt to format the discounted price using toLocaleString with the provided currency
        return discountedPrice.toLocaleString(undefined, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    } catch (error) {
        // Handle invalid currency codes
        console.error(`Invalid currency code: ${currency}. Error: ${(error as Error).message}`);
        return `${discountedPrice.toFixed(2)} (Invalid Currency Code: ${currency})`;
    }
};
export const currencyPrefixer = (
    amount: number,
    currency: string
): string => {
    if (amount < 0) {
        // Handle invalid input
        throw new Error('Invalid input. Please provide a valid original price and discount percentage.');
    }


    try {
        // Attempt to format the discounted price using toLocaleString with the provided currency
        return amount.toLocaleString(undefined, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    } catch (error) {
        // Handle invalid currency codes
        console.error(`Invalid currency code: ${currency}. Error: ${(error as Error).message}`);
        return `${amount.toFixed(2)} (Invalid Currency Code: ${currency})`;
    }
};
