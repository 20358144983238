import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/store.ts";
import {useMediaQuery, useWindowScroll} from "@mantine/hooks";
import {
    Anchor,
    Avatar,
    Badge,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Divider,
    em,
    Flex,
    Group,
    Image,
    Paper,
    Rating,
    rem,
    ScrollArea,
    Skeleton,
    Spoiler,
    Text,
    Title
} from "@mantine/core";
import {useEffect, useState} from "react";
import {getOneGuestProductAction, getProductsSuggestionAction} from "../../store/product/productActions.ts";
import {calculateRatings} from "../../utils/ratingCalculator.ts";
import {discountCalculatorWithCurrency} from "../../utils/discountCalculator.ts";
import {ProductQueryParams} from "../../interfaces/IQueryParams.ts";
import {Carousel} from "@mantine/carousel";
import {IconArrowLeft, IconArrowRight} from "@tabler/icons-react";
import classes from "../buyer/home/carousel.module.css";
import GuestProductInfoCard from "./GuestProductInfoCard.tsx";
import {round} from "../../utils/roundOffFunction.ts";
import fallbackImage from "../../assets/Placholder-01.png";
import ProductInfoSkeleton from "../../components/skeletons/ProductInfoSkeleton.tsx";

const items = [
    {title: 'Home', href: '/'},
    {title: 'Product detail', href: '#'},
].map((item, index) => (
    <Anchor c="black" href={item.href} key={index}>
        {item.title}
    </Anchor>
));
const GuestProductDetail = () => {
    const {id} = useParams()
    const dispatch = useAppDispatch()
    const {guestProduct, suggestedProducts, loaders} = useAppSelector(state => state.product)
    const isMedium = useMediaQuery(`(max-width: ${em(992)})`)
    const isSmall = useMediaQuery(`(max-width: ${em(768)})`)

    const [pic, setPic] = useState(guestProduct?.product?.images[0])
    const [selected, setSelected] = useState(0)
    const navigate = useNavigate()
    const [scroll, scrollTo] = useWindowScroll()

    useEffect(() => {
        scrollTo({y: 0})
        console.log(scroll)
        if (!id) return
        dispatch(getOneGuestProductAction(id))
    }, [id]);

    useEffect(() => {
        setPic(guestProduct?.product?.images[0])
        if (!guestProduct?.product?._id && !guestProduct?.product?.businessId?._id) return
        const params: ProductQueryParams = {
            productId: guestProduct?.product?._id,
            productType: guestProduct?.product?.productType,
            category: guestProduct?.product?.category[0],
            businessId: guestProduct?.product?.businessId?._id
        }
        dispatch(getProductsSuggestionAction(params))
    }, [guestProduct]);
    return (
        <>
            <Flex justify="center">
                <Paper px={isSmall ? "sm" : "xl"} pb={isSmall ? 70 : "sm"} maw={isSmall ? "100%" : "80%"}>
                    <Breadcrumbs separator=">" separatorMargin="md" mt="xs">
                        {items}
                    </Breadcrumbs>
                    <Flex w="100%" direction={isMedium ? "column" : "row"} gap="xl">
                        <Card w={isSmall ? "100%" : isMedium ? "100%" : "30%"} p={0}>
                            <Flex direction="column" h="100%" py={isSmall ? "sm" : "xl"}>
                                {loaders.gettingOneProduct ? <Skeleton height="400px" width="100%"/> :
                                    <Image loading="lazy" src={pic} h="400px" fallbackSrc={fallbackImage}/>}
                                <ScrollArea type={isSmall ? "never" : "auto"}>
                                    <Flex gap={12} my="lg" h="100px">
                                        {loaders.gettingOneProduct ? [1, 2, 3, 4].map((_, index) => (
                                            <Card key={index}
                                                  p={0}
                                                  color="blue">
                                                <Skeleton
                                                    h="100%"
                                                    w="100px" radius="xs"/>
                                            </Card>
                                        )) : guestProduct?.product?.images.map((img, index) => {
                                            return (
                                                <Card key={index}
                                                      style={{
                                                          cursor: "pointer",
                                                          border: selected === index ? "1px solid #62BE12" : ""
                                                      }}
                                                      p={0}
                                                      color="blue">
                                                    <Avatar onClick={() => {
                                                        setPic(img)
                                                        setSelected(index)
                                                    }} style={{border: "1px blue", borderBlock: "blue"}}
                                                            key={index} src={img} h="100%"
                                                            w="100px" radius="xs"/>
                                                </Card>
                                            )
                                        })}
                                    </Flex>
                                </ScrollArea>
                            </Flex>
                        </Card>
                        <Flex direction="column" py={isSmall ? "" : "xl"} h="100%" gap="lg"
                              w={isMedium ? "100%" : "50%"}
                              mx={isSmall ? "" : "xl"}>
                            {loaders.gettingOneProduct ? <Skeleton height="50px"
                                                                   width="100%"/> :
                                <Text size={isSmall ? "20px" : "32px"}
                                      fw={700}>{guestProduct?.product?.productName}</Text>}
                            {loaders.gettingOneProduct ? <Skeleton height="30px" width="90px"/> :
                                <Badge variant="light" color="gray" size="xl"
                                       c="#62BE12">#{guestProduct?.product?.category[0]}</Badge>}
                            {/*<Group>*/}
                            {/*    <span>{calculateRatings(guestProduct?.product?.rating?.totalRating, guestProduct?.product?.rating?.numRating)}</span>*/}
                            {/*    <Rating readOnly*/}
                            {/*            value={calculateRatings(guestProduct?.product?.rating?.totalRating, guestProduct?.product?.rating?.numRating)}/>*/}
                            {/*    <span*/}
                            {/*        style={{color: "#767C8C"}}>({guestProduct?.product?.rating.numRating} ratings)</span>*/}
                            {/*</Group>*/}
                            <Group>
                                {loaders.gettingOneProduct ? <Skeleton height="30px" width="100%"/> :
                                    <>
                                <span
                                    style={{
                                        fontSize: isSmall ? "24px" : "32px",
                                        color: guestProduct?.product?.discount > 0 ? "black" : "#A8AEBF",
                                        fontWeight: guestProduct?.product?.discount > 0 ? 600 : 10
                                    }}>{discountCalculatorWithCurrency(guestProduct?.product?.productPrice?.amount, guestProduct?.product?.discount, guestProduct?.product?.productPrice.currency)}</span>
                                        <Text
                                            fw={guestProduct?.product?.discount > 0 ? 10 : 600}
                                            td={guestProduct?.product?.discount > 0 ? "line-through" : ""}>{round(guestProduct?.product?.productPrice.amount)}{" "}{guestProduct?.product?.productPrice.currency}</Text>
                                        <span style={{color: "#62BE12"}}>{guestProduct?.product?.discount}% off</span>
                                    </>}
                            </Group>
                            {/*<Text>{guestProduct?.product?.color}</Text>*/}

                            <Flex justify="space-between" w="100%">
                                <Flex bg="white" w={isSmall ? "100%" : "100%"} direction="column" gap={12} py="lg">
                                    <Box>
                                        <Text fw={600}>Description</Text>
                                        {loaders.gettingOneProduct ? <Skeleton height="200px" width="100%"/> :
                                            <Spoiler maxHeight={300} showLabel="Show more" hideLabel="Hide">
                                                <Text size="sm" c="#474C59">{guestProduct?.product?.description}</Text>
                                            </Spoiler>}
                                    </Box>
                                    {/*<Box>*/}
                                    {/*    <Text c="#767C8C" fw={400}>What's in the box</Text>*/}
                                    {/*    <Text fw={600}>{product?.product?.whatInsideTheBox}</Text>*/}
                                    {/*</Box>*/}
                                    {/*<Box>*/}
                                    {/*    <Text c="#767C8C" fw={400}>Highlight</Text>*/}
                                    {/*    <List mx="lg">*/}
                                    {/*        {product?.product?.highlights.map((item, index) => {*/}
                                    {/*            return <List.Item key={index}>{item}</List.Item>*/}
                                    {/*        })}*/}
                                    {/*    </List>*/}
                                    {/*</Box>*/}
                                </Flex>
                            </Flex>

                            {!isSmall && <Flex my="lg" w="100%" justify="space-evenly">
                                <Button component="a" fw={100} size="lg" radius="xl" color="#62BE12"
                                        onClick={() => {
                                            navigate("/sign-in")
                                        }} w="40%">Buy Now</Button>
                                <Button component="a" fw={100} size="lg" radius="xl" onClick={() => {
                                    navigate("/sign-in")
                                }} color="#62BE12" w="40%" variant="outline">Add To
                                    Cart</Button>
                            </Flex>
                            }

                        </Flex>
                    </Flex>
                    <Divider/>
                    <Flex w="100%" my="lg" direction="column" gap="sm">
                        <Text fw={700} size={isSmall ? "20px" : "32px"}>Product Ratings & Reviews</Text>
                        <Flex justify="space-between" gap={isSmall ? "lg" : ""}
                              direction={isSmall ? "column" : "row"}>
                            <Flex w="200px" direction="column" gap="lg">
                                <Flex gap="xl">
                                    {/*<Badge variant="light" color="gray"*/}
                                    {/*       size="lg">{calculateRatings(product?.product?.rating.totalRating, product?.product?.rating.numRating)}/5</Badge>*/}
                                    <Flex direction="column" gap="xs">
                                        {/*<span style={{color: "#767C8C"}}>Overall Rating</span>*/}
                                        {/*<Text>{product?.product?.rating?.numRating} Ratings</Text>*/}
                                        <Text>Overall Rating</Text>
                                        <Title
                                            order={isSmall ? 3 : 1}>{calculateRatings(guestProduct?.product?.rating.totalRating, guestProduct?.product?.rating.numRating)}/5</Title>
                                        <Rating readOnly size="lg"
                                                value={calculateRatings(guestProduct?.product?.rating.totalRating, guestProduct?.product?.rating.numRating)}/>
                                    </Flex>
                                </Flex>
                                <Text>0 reviews</Text>
                            </Flex>
                            {/*<Button variant="outline" color="#62BE12" w="20%">Rate</Button>*/}
                        </Flex>
                        <Divider/>
                        <Flex direction="column" gap="lg">
                            <Text fw={600}>Meet your seller</Text>
                            <Flex align="center" gap="lg">
                                <Avatar size="lg"
                                        src={guestProduct?.product?.businessId?.image}/>
                                <Flex direction="column">
                                    <Text fw={600}>{guestProduct?.product?.businessId?.title}</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider/>
                        <Flex justify="space-between">
                            <Text fw={700}>From this shop</Text>
                        </Flex>
                        <Carousel
                            nextControlIcon={<IconArrowRight
                                style={{
                                    width: rem(30),
                                    height: rem(30),
                                    boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                    borderRadius: "100px"
                                }}/>}
                            previousControlIcon={<IconArrowLeft style={{
                                width: rem(30), height: rem(30),
                                boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                borderRadius: "100px"
                            }}/>}
                            slideSize={isSmall ? "160px" : "160px"}
                            slideGap={0}
                            align="start"
                            dragFree
                            classNames={classes}
                            // p="xs"
                        >
                            {loaders.gettingOneProduct ? [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 22, 33].map((_, index) => (
                                <Carousel.Slide key={index}>
                                    <ProductInfoSkeleton/>
                                </Carousel.Slide>
                            )) : suggestedProducts?.productsFromSameShop?.map((product, index) => {
                                return <Carousel.Slide key={index}>
                                    <GuestProductInfoCard product={product}/>
                                </Carousel.Slide>
                            })}
                        </Carousel>
                        <Flex justify="space-between">
                            <Text fw={700}>Similar products</Text>
                        </Flex>
                        <Carousel
                            nextControlIcon={<IconArrowRight
                                style={{
                                    width: rem(30),
                                    height: rem(30),
                                    boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                    borderRadius: "100px"
                                }}/>}
                            previousControlIcon={<IconArrowLeft style={{
                                width: rem(30), height: rem(30),
                                boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                borderRadius: "100px"
                            }}/>}
                            slideSize={isSmall ? "160px" : "160px"}
                            slideGap={0}
                            align="start"
                            dragFree
                            classNames={classes}
                            // bg="yellow"
                            // p="xs"
                        >
                            {loaders.gettingOneProduct ? [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 22, 33].map((_, index) => (
                                <Carousel.Slide key={index}>
                                    <ProductInfoSkeleton/>
                                </Carousel.Slide>
                            )) : suggestedProducts?.similarProducts?.map((product, index) => {
                                return <Carousel.Slide key={index}>
                                    <GuestProductInfoCard product={product}/>
                                </Carousel.Slide>
                            })}
                        </Carousel>
                        <Flex justify="space-between">
                            <Text fw={700}>You might also like</Text>
                        </Flex>
                        <Carousel
                            nextControlIcon={<IconArrowRight
                                style={{
                                    width: rem(30),
                                    height: rem(30),
                                    boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                    borderRadius: "100px"
                                }}/>}
                            previousControlIcon={<IconArrowLeft style={{
                                width: rem(30), height: rem(30),
                                boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                borderRadius: "100px"
                            }}/>}
                            slideSize={isSmall ? "160px" : "160px"}
                            slideGap={0}
                            align="start"
                            dragFree
                            classNames={classes}
                            // p="xs"
                        >
                            {loaders.gettingOneProduct ? [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 22, 33].map((_, index) => (
                                <Carousel.Slide key={index}>
                                    <ProductInfoSkeleton/>
                                </Carousel.Slide>
                            )) : suggestedProducts?.youMightAlsoLike?.map((product, index) => {
                                return <Carousel.Slide key={index}>
                                    <GuestProductInfoCard product={product}/>
                                </Carousel.Slide>
                            })}
                        </Carousel>
                    </Flex>
                </Paper>
            </Flex>
            {isSmall &&
                <Flex pos="fixed" bottom={0} w={isSmall ? "100%" : "50%"} justify="space-evenly" bg="white" py="sm">
                    <Button radius="xl" fw={400} size="md" color="#62BE12" onClick={() => {
                        navigate("/sign-in")
                    }} w="40%">Buy Now</Button>
                    <Button radius="xl" fw={400} size="md" onClick={() => {
                        navigate("/sign-in")
                    }} color="#62BE12" w="40%" variant="outline">Add To
                        Cart</Button>
                </Flex>}
        </>
    );
};

export default GuestProductDetail;