import {
    ActionIcon,
    Anchor,
    Badge,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Divider,
    em,
    Flex,
    Group,
    Image,
    LoadingOverlay,
    rem,
    Stepper,
    Text,
    TextInput,
    Title
} from "@mantine/core";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useMediaQuery} from "@mantine/hooks";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {updatePickupOrderAction} from "../../../store/business/businessesActions.ts";
import QRCode from "react-qr-code";
import {IconPrinter, IconSend2} from "@tabler/icons-react";
import {round} from "../../../utils/roundOffFunction.ts";
import {notificationSystem} from "../../../utils/notificationSystem.ts";
import {modals} from "@mantine/modals";
import fallbackImage from "../../../assets/Placholder-01.png";
import {getSingleBusinessOrderAction, updateOrderStatusAction} from "../../../store/oders/ordersActions.ts";
import {useReactToPrint} from "react-to-print";


const items = [
    {title: 'Orders', href: '/seller/orders'},
    {title: 'Order Details', href: '#'},
]
const SellerOrderDetails = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const {id} = useParams()
    const dispatch = useAppDispatch()
    const {singleBusinessOrder, loaders} = useAppSelector(state => state.orders)
    const [pickupCode, setPickupCode] = useState("")
    const navigate = useNavigate()
    const [productQuantity, setProductQuantity] = useState<number | null>(null)
    const [active, setActive] = useState<number>(0)
    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Invoice',
    });

    useEffect(() => {
        if (!id) return
        dispatch(getSingleBusinessOrderAction(id))
    }, [id]);

    useEffect(() => {
        let qty: number = 0
        singleBusinessOrder?.order?.products?.forEach((value) => {
            qty += value?.quantity
        })
        setProductQuantity(qty)
        const active = singleBusinessOrder?.order?.orderStatus === "confirmed" ? 0 : singleBusinessOrder?.order?.orderStatus === "processed" ? 1 : singleBusinessOrder?.order?.orderStatus === "assigned" ? 2 : singleBusinessOrder?.order?.orderStatus === "delivered" ? 3 : 0
        setActive(active)

    }, [singleBusinessOrder]);


    return (
        <Flex direction="column" gap="sm" p="md">
            <LoadingOverlay visible={loaders.gettingSingleBusinessOrder}/>
            <Breadcrumbs separator=">" separatorMargin="md" mt="xs">
                {items.map((item, index) => (
                    <Anchor c={item.title === "Orders" ? "#62BE12" : "black"} onClick={() => navigate(item.href)}
                            key={index}>
                        {item.title}
                    </Anchor>
                ))}
            </Breadcrumbs>
            <Title order={3}>Order Details</Title>
            {singleBusinessOrder?.order?.orderStatus !== "cancelled" &&
                <Stepper w={isMobile ? "100%" : "55%"} size={isMobile ? "xs" : "sm"} styles={{
                    stepIcon: {
                        borderWidth: rem(4),
                    },
                    step: {

                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        gap: "6px",
                        padding: 0
                    },
                    stepLabel: {
                        marginRight: isMobile ? "10px" : "0px"
                    },
                    separator: {
                        marginLeft: isMobile ? rem(-1) : "",
                        marginRight: isMobile ? rem(-1) : "",
                        marginBottom: isMobile ? rem(16) : "",
                        borderRadius: "12px",
                        height: isMobile ? rem(4) : rem(6),
                    },
                }} active={active + 1} color="#20C375">
                    <Stepper.Step label="Confirmed"/>
                    <Stepper.Step label="Packed"/>
                    <Stepper.Step label="Driver"/>
                    <Stepper.Step label="Delivered"/>
                </Stepper>}
            <Card w={isMobile ? "100%" : "85%"} radius="lg">
                <Flex w="100%" direction={isMobile ? "column" : "row"}>
                    <Flex w={isMobile ? "100%" : "50%"}>
                        <Box h={250} w={isMobile ? "100%" : "90%"} m="sm">
                            <Image h={250} w={isMobile ? "100%" : "90%"}
                                   src={singleBusinessOrder?.order?.products[0]?.item?.image}
                                   fallbackSrc={fallbackImage}/>
                        </Box>
                    </Flex>
                    <Flex w={isMobile ? "100%" : "50%"} gap="sm" direction="column" ref={componentRef}
                          ml={isMobile ? "" : "xl"} m={isMobile ? "sm" : ""}>
                        <Group justify="end" w="100%">
                            {active === 0 && singleBusinessOrder?.order?.deliverType !== "pickup" && (
                                <>
                                    <Button fw={200} radius="xl" variant="outline" onClick={() => {
                                        modals.openConfirmModal({
                                            title: 'Cancel Order',
                                            centered: true,
                                            children: (
                                                <Text size="sm">
                                                    Are you sure you want to cancel order?
                                                </Text>
                                            ),
                                            labels: {confirm: 'Yes', cancel: "No"},
                                            confirmProps: {color: 'red', fw: 200},
                                            onCancel: () => console.log('Cancel'),
                                            onConfirm: () => {
                                                dispatch(updateOrderStatusAction({
                                                    orderId: singleBusinessOrder?.order?._id,
                                                    statusType: "cancelled"
                                                }))
                                            },
                                        });
                                    }}>Cancel</Button>
                                    <Button fw={200} radius="xl" onClick={() => {
                                        modals.openConfirmModal({
                                            title: 'Process Order',
                                            centered: true,
                                            children: (
                                                <Text size="sm">
                                                    Are you sure you want to process this order?
                                                </Text>
                                            ),
                                            labels: {confirm: 'Process', cancel: "Cancel"},
                                            confirmProps: {color: '#62BE12', fw: 200},
                                            onCancel: () => console.log('Cancel'),
                                            onConfirm: () => {
                                                dispatch(updateOrderStatusAction({
                                                    orderId: singleBusinessOrder?.order?._id,
                                                    statusType: "processed"
                                                }))
                                            },
                                        });

                                    }}>Process</Button>
                                </>
                            )}
                            {active === 1 &&
                                <Button fw={200} leftSection={<IconPrinter size={20}/>} onClick={handlePrint}>
                                    Print
                                </Button>
                            }
                        </Group>
                        <Text opacity={0.5}>Order Name</Text>
                        <Text>{singleBusinessOrder?.order?.products[0]?.item?.name}</Text>
                        <Flex justify="space-between">
                            <Flex direction="column">
                                <Text opacity={0.5}>Order ID</Text>
                                {isMobile ?
                                    <Box w={150}>
                                        <Text truncate="end">{singleBusinessOrder?.order?._id}</Text>
                                    </Box>
                                    :
                                    <Text>{singleBusinessOrder?.order?._id}</Text>
                                }
                            </Flex>
                            <Flex direction="column">
                                <Text opacity={0.5}>Product Quantity</Text>
                                <Text>{productQuantity}</Text>
                            </Flex>
                        </Flex>
                        <Flex justify="space-between">
                            <Flex direction="column">
                                <Text opacity={0.5}>Amount</Text>
                                <Text>{round(singleBusinessOrder?.order?.totalOrderAmount?.amount)}{" "}{singleBusinessOrder?.order?.totalOrderAmount?.currency}</Text>
                            </Flex>
                            <Flex direction="column">
                                <Text opacity={0.5}>Status</Text>
                                <Badge variant="light" color="#20C375">{singleBusinessOrder?.order?.orderStatus}</Badge>
                            </Flex>
                        </Flex>
                        <Flex direction="column">
                            <Text opacity={0.5}>Type</Text>
                            <Badge variant="light" color="#F5F6FA"
                                   c="black">{singleBusinessOrder?.order?.deliverType}</Badge>
                        </Flex>
                        <Divider/>
                        <Flex direction="column">
                            <Text opacity={0.5}>Buyer name</Text>
                            <Text>{singleBusinessOrder?.order?.buyerInfo?.name}</Text>
                        </Flex>

                        <Flex direction="column">
                            <Text opacity={0.5}>Buyer phone</Text>
                            <Text>{singleBusinessOrder?.order?.buyerInfo?.phone}</Text>
                        </Flex>
                        <Divider/>
                        {singleBusinessOrder?.order?.totals?.map((value, index) => {
                            return (
                                <Flex justify="space-between" key={index}>
                                    <Text opacity={0.5}>{value?.title}</Text>
                                    <Text
                                        opacity={0.5}>{round(value?.price?.amount)} {" "}{value?.price?.currency}</Text>
                                </Flex>
                            )
                        })}
                        <Flex justify="space-between">
                            <Text fw={600}>Order Total</Text>
                            <Text
                                fw={600}>{round(singleBusinessOrder?.order?.totalOrderAmount?.amount)}{" "}{singleBusinessOrder?.order?.totalOrderAmount?.currency}</Text>
                        </Flex>
                        {singleBusinessOrder?.order?.deliverType === "delivery" ? (
                            active === 1 && (
                                <QRCode
                                    size={isMobile ? 200 : 230}
                                    style={{alignSelf: "center", marginTop: 20}}
                                    value={singleBusinessOrder?.order?._id}
                                    viewBox={`0 0 256 256`}
                                />
                            )
                        ) : (
                            <Flex w="100%" align="center">
                                <TextInput
                                    onChange={(e) => setPickupCode(e.target.value)}
                                    w="100%"
                                    radius="md"
                                    placeholder="Enter Pickup code"
                                />
                                <ActionIcon
                                    onClick={() => {
                                        if (pickupCode.length <= 0) {
                                            notificationSystem("Failed", "Please enter pickup code", true);
                                            return;
                                        }
                                        modals.openConfirmModal({
                                            title: 'Order Picked',
                                            centered: true,
                                            children: (
                                                <Text size="sm">
                                                    Are you sure the order has been picked up by the buyer?
                                                </Text>
                                            ),
                                            labels: {confirm: 'Yes', cancel: "Cancel"},
                                            confirmProps: {color: '#20C375', radius: "xl"},
                                            cancelProps: {radius: "xl", color: "#20C375"},
                                            onCancel: () => console.log('Cancel'),
                                            onConfirm: () => {
                                                dispatch(updatePickupOrderAction({
                                                    id: singleBusinessOrder?.order?._id,
                                                    secret: pickupCode,
                                                }));
                                                setPickupCode("");
                                                navigate(-1);
                                            },
                                        });
                                    }}
                                    variant="transparent"
                                    c="#20C375"
                                >
                                    <IconSend2/>
                                </ActionIcon>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    );
};

export default SellerOrderDetails;