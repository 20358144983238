import {Button, Card, em, Flex, Group, Image, Loader, Rating, rem, Text, Title} from "@mantine/core";
import {IconArrowLeft, IconArrowRight, IconMapPin} from "@tabler/icons-react";
import {calculateRatings} from "../../../utils/ratingCalculator.ts";
import {useMediaQuery} from "@mantine/hooks";
import {useAppDispatch, useAppSelector} from "../../../store/store.ts";
import {useEffect} from "react";
import {getBusinessByIdAction} from "../../../store/business/businessesActions.ts";
import {Carousel} from "@mantine/carousel";
import classes from "../home/carousel.module.css";
import ProductInfoCard from "../product/ProductInfoCard.tsx";
import {useNavigate} from "react-router-dom";

const SingleBusinessDetail = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    // const location = useLocation()
    const navigate = useNavigate()

    // const {id} = useParams()
    const dispatch = useAppDispatch()
    const {singleBusiness, loaders} = useAppSelector(state => state.business)

    useEffect(() => {
        dispatch(getBusinessByIdAction("65e1c5d8c2a67a002ef6ceb3"))
    }, []);
    return (
        <>
            {loaders.gettingSingleBusiness ?
                <Flex h="100vh" w="100vw" justify="center" align="center">
                    <Loader/>
                </Flex> :
                <Flex direction="column" mb="lg" justify="center" align="center">
                    <Card m={isMobile ? "" : "sm"} radius="lg" maw={isMobile ? "100%" : 1240}>
                        <Flex justify="space-between" align={isMobile ? "center" : ""}
                              direction={isMobile ? "column" : "row"}
                              w="100%">
                            <Flex direction={isMobile ? "column" : "row"} w="100%"
                                  align={isMobile ? "center" : ""} gap={isMobile ? "" : "xl"}>
                                <Card h={isMobile ? 200 : 360} w={isMobile ? "100%" : "70%"} p={0}>
                                    <Image radius="lg"
                                           src={singleBusiness?.businessInfo?.image}
                                           h={isMobile ? 250 : "100%"}
                                           w={isMobile ? "100%" : "100%"}/>
                                </Card>
                                <Flex direction="column" mx="lg">
                                    <Title order={3}
                                           my="lg">{singleBusiness?.businessInfo?.title}</Title>
                                    <Flex>
                                        <IconMapPin style={{marginRight: 10, marginTop: 3, color: "#767C8C"}}
                                                    size={20}/>
                                        <Text
                                            style={{color: "#767C8C"}}>{singleBusiness?.businessInfo?.address?.formattedAddress}</Text>
                                    </Flex>
                                    <Flex my="lg" gap="sm" align="center" w="100%">
                                        <Rating
                                            value={calculateRatings(singleBusiness?.businessInfo?.rating?.totalRating, singleBusiness?.businessInfo?.rating?.numRating)}
                                            readOnly/>
                                        <Text c="gray"
                                              size="lg">{calculateRatings(singleBusiness?.businessInfo?.rating?.totalRating, singleBusiness?.businessInfo?.rating?.numRating)}</Text>
                                        {singleBusiness?.businessInfo?.rating?.totalRating &&
                                            <Text
                                                c="#A8AEBF">({singleBusiness?.businessInfo?.rating?.totalRating}+
                                                ratings)</Text>}
                                        <Text>3.10 miles away</Text>
                                    </Flex>
                                    <Flex gap="lg" justify={isMobile ? "space-evenly" : ""}>
                                        <Button variant={isMobile ? "outline" : ""} size={isMobile ? "lg" : "md"}
                                                fw={400} radius="xl" color="#62BE12"
                                                onClick={() => {
                                                    navigate(`/buyer/chat/${singleBusiness?.businessInfo?.userId}?name=${singleBusiness?.businessInfo?.title}&avatar=${singleBusiness?.businessInfo?.image}`)
                                                }}>Message</Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Group>
                            </Group>
                        </Flex>
                    </Card>
                    <Flex direction="column" mb="lg" justify="center" align="center">
                        <Flex maw={isMobile ? "100vw" : 1240} direction="column">
                            <Flex justify="space-between" mx={isMobile ? "sm" : ""}>
                                <Text fw={700}>Latest Products</Text>
                            </Flex>
                            <Carousel
                                nextControlIcon={<IconArrowRight
                                    style={{
                                        width: rem(30),
                                        height: rem(30),
                                        boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                        borderRadius: "100px"
                                    }}/>}
                                previousControlIcon={<IconArrowLeft style={{
                                    width: rem(30), height: rem(30),
                                    boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                    borderRadius: "100px"
                                }}/>}
                                slideSize={isMobile ? "150px" : "150px"}
                                slideGap="xs"
                                align="start"
                                p="xs"
                                dragFree
                                classNames={classes}
                            >
                                {singleBusiness?.latestProducts?.map((product) => {
                                    return (
                                        <Carousel.Slide>
                                            <ProductInfoCard product={product}/>
                                        </Carousel.Slide>
                                    )
                                })}
                            </Carousel>
                        </Flex>
                        <Flex maw={isMobile ? "100vw" : 1240} direction="column">
                            <Flex justify="space-between" mx={isMobile ? "sm" : ""}>
                                <Text fw={700}>Top Rated Products</Text>
                            </Flex>
                            <Carousel
                                nextControlIcon={<IconArrowRight
                                    style={{
                                        width: rem(30),
                                        height: rem(30),
                                        boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                        borderRadius: "100px"
                                    }}/>}
                                previousControlIcon={<IconArrowLeft style={{
                                    width: rem(30), height: rem(30),
                                    boxShadow: "5px 5px 10px 2px rgba(0,0,0,.4)",
                                    borderRadius: "100px"
                                }}/>}
                                slideSize={isMobile ? "150px" : "150px"}
                                slideGap="xs"
                                align="start"
                                p="xs"
                                dragFree
                                classNames={classes}
                            >
                                {singleBusiness?.topRatedProducts?.map((product) => {
                                    return (
                                        <Carousel.Slide>
                                            <ProductInfoCard product={product}/>
                                        </Carousel.Slide>
                                    )
                                })}
                            </Carousel>
                        </Flex>
                    </Flex>
                </Flex>
            }

        </>
    );
};

export default SingleBusinessDetail;