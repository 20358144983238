import {useMediaQuery} from "@mantine/hooks";
import {Box, Card, Center, em, Flex, Title} from "@mantine/core";
import React from "react";

type CenterLayoutProps = {
    children: React.ReactNode
    description?: string
    title?: string
}

const CenterLayout = ({title, description, children}: CenterLayoutProps) => {
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const isLaptop = useMediaQuery(`(max-width: ${em(992)})`);
    return (
        <Center h={isMobile ? "80%" : "90vh"}>
            <Card withBorder p={isLaptop ? "sm" : "lg"} radius="lg"
                  h={isMobile ? "100%" : isLaptop ? "90%" : "90%"}
                  w={isMobile ? "100%" : isLaptop ? "60%" : "40%"}>
                <Flex p={isMobile ? "" : "md"} justify="center" align="center" direction="column" w="100%">
                    <Box w="100%">
                        <Title order={isMobile ? 2 : 1} mt={isMobile ? "lg" : ""} mb="sm">{title}</Title>
                        <span style={{fontWeight: 200, color: "#A8AEBF", fontSize: "15px"}}>{description}</span>
                    </Box>
                    <Box my="lg" w="100%">
                        {children}
                    </Box>
                </Flex>
            </Card>
        </Center>
    );
};

export default CenterLayout;