import {Button, Card, em, Flex, Image, Text} from "@mantine/core";
import {IconCircleMinus, IconCirclePlus} from "@tabler/icons-react";
import {useState} from "react";
import {useAppDispatch} from "../store/store.ts";
import {addToCartAction, deleteCartItemAction} from "../store/product/productActions.ts";
import {useMediaQuery} from "@mantine/hooks";
import {IFood} from "../interfaces/buyer/restaurants/IRestaurantResponse.ts";
import {useNavigate} from "react-router-dom";
import {round} from "../utils/roundOffFunction.ts";
import fallbackImage from "../assets/Placholder-01.png";

type FoodItemCardProp = {
    isPlus?: boolean
    key: number | string
    food: IFood
    user: string
}
const FoodItemCard = ({key, food, user}: FoodItemCardProp) => {
    const [isPlus, setIsPlus] = useState(true)
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery(`(max-width: ${em(768)})`);
    const navigate = useNavigate()

    return (
        <Flex key={key} w={isMobile ? "145px" : "150px"} h={isMobile ? "240px" : "220px"} gap="sm" my="xs">
            <Card shadow="xs" w={isMobile ? "100%" : 400}>
                <Flex align="center" h="100%" direction="column" justify="space-between">
                    <Card.Section>
                        <Image radius="sm" src={food?.productImage} h={isMobile ? 100 : 80} w={isMobile ? 150 : 80}
                               fallbackSrc={fallbackImage}/>
                    </Card.Section>
                    <Flex direction="column" my="xs">
                        <Text lineClamp={2} size="sm">{food?.productName}</Text>
                        <Text size="sm"
                              fw={600}>{round(food?.productPrice?.amount)} {" "}{food?.productPrice?.currency}</Text>
                    </Flex>
                    {
                        isPlus ?
                            <Button component="a" fw={100} w="100%" variant="outline" radius="md" c="#62BE12"
                                    color="#F5F6FA" onClick={() => {
                                if (user === "guest") {
                                    navigate("/sign-in")
                                } else {
                                    setIsPlus(!isPlus)
                                    dispatch(addToCartAction({productId: food._id}))
                                }

                            }} leftSection={<IconCirclePlus size="1.2rem"/>}>Add</Button>
                            :
                            <Button component="a" fw={200} w="100%" color="#62BE12" onClick={() => {
                                setIsPlus(!isPlus)
                                dispatch(deleteCartItemAction({productId: food._id}))
                            }} leftSection={<IconCircleMinus size="1.2rem"/>}>Remove</Button>
                    }

                </Flex>
            </Card>
        </Flex>
    );
};

export default FoodItemCard;