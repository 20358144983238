import {createAsyncThunk} from "@reduxjs/toolkit";
import axiosInstance from "../../config/axios.config.ts";
import {ISendMessage} from "../../interfaces/chat/ISendMessage.ts";
import {IMessageSendResponse} from "../../interfaces/chat/IMessageSendResponse.ts";
import {IChatInbox} from "../../interfaces/chat/IChatInbox.ts";
import {ISingleChat} from "../../interfaces/chat/ISingleChat.ts";
import {updateChat} from "./chatSlice.ts";

export const sendMessageAction = createAsyncThunk("chat/sendMessage", async (message: ISendMessage, {dispatch}) => {
    try {
        const res = await axiosInstance.post<IMessageSendResponse>("chat/send-message", message)
        dispatch(updateChat(res.data.message))
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getChatInboxMessagesAction = createAsyncThunk("chat/getMessages", async () => {
    try {
        const res = await axiosInstance.get<IChatInbox>("chat/getSpecificUserChatHistory")
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const getOneChatAction = createAsyncThunk("chat/getOneChat", async ({receiverId}: { receiverId: string }) => {
    try {
        const res = await axiosInstance.get<ISingleChat>(`chat/getChat?recieverId=${receiverId}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
})

export const updateChatSeenByAction = createAsyncThunk("chat/updateChatSeenBy", async ({chatRoomId}: {
    chatRoomId: string
}) => {
    try {
        const res = await axiosInstance.put("chat/update-seenBy", {chatRoomId})
        return res.data
    } catch (e) {
        console.log(e)
    }
})